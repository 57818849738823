import React, { useEffect, useState } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import SideBar from "../../components/SideBar";
import HeaderAdmin from "../../components/admin/HeaderAdmin";
import Button from "../../components/Button";
import WhiteButton from "../../components/WhiteButton";
import { apiUrl } from "../../constants/apiUrl";
import CustomDataGrid from "../../components/CustomDataGrid";

function AddPromoCodeScreen() {
  const [open, setOpen] = React.useState(false);
  const [modify, setModify] = useState(false);
  const [promoCode, setPromoCode] = useState(null)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [promoCodes, setPromoCodes] = useState([]);

  useEffect(() => {
    const getPromoCodes = async () => {
      const { data } = await axios.get(`${apiUrl}/api/promocodes`);
      setPromoCodes(data);
    };
    getPromoCodes();
  }, [promoCode]);

  function SimpleDialog(props) {
    const { onClose, selectedValue, open, promoCode, modify } = props;

    console.log(promoCode);
    const handleClose = () => {
      onClose(selectedValue);
      setModify(false)
    };

    const [nom, setNom] = useState("");
    const [type, setType] = useState("");
    const [value, setValue] = useState("");

    useEffect(() => {
      setNom(promoCode?.nom);
      setType(promoCode?.type);
      setValue(promoCode?.value);
    }, []);
    const [done, setDone] = useState(false);

    const addPromoCode = async () => {
      const {data} = await axios.post(`${apiUrl}/api/promocodes`, {
        nom,
        value,
        type,
      });
      setPromoCode(data)
      handleClose()
    };

    console.log(promoCode)
    const updatePromoCode = async () => {
      try {
        const {data} = await axios.put(`${apiUrl}/api/promocodes/${promoCode._id}`, {
          nom,
          value,
          type,
        });
        setPromoCode(data)
    
        handleClose()
      } catch(err) {
        console.log(err)
      }
  
     
    }
    return (
      <Dialog
        onClose={handleClose}
        open={open}
        className="add-formation-dialog"
      >
        <div>
          {modify ?           <h3>Modifier votre code promo</h3> : <h3>Ajouter un code promo</h3>
}
          
          <TextField
            id="outlined-basic"
            label="Nom du code"
            variant="outlined"
            value={nom}
            className="title-add-formation w-100"
            onChange={(e) => setNom(e.target.value)}
          />
          <h5>Type de code</h5>
          <div className="d-flex">
            <input
              type="checkbox"
              checked={type === "Pourcentage"}
              onChange={() => setType("Pourcentage")}
              className="mt-1 mx-2"
            />
            <p className="mt-1">Pourcentage</p>
          </div>
          <div className="d-flex">
            <input
              type="checkbox"
              checked={type === "Euros"}
              onChange={() => setType("Euros")}
              className="mt-1 mx-2"
            />
            <p className="mt-1">Euros</p>
          </div>
          <TextField
            id="outlined-basic"
            label="Valeur"
            value={value}
            variant="outlined"
            className="title-add-formation w-100 mt-3"
            onChange={(e) => setValue(e.target.value)}
          />
          <Button text={modify ? "Modifier" : "Ajouter"} onClick={modify ? () => updatePromoCode() : ()=> addPromoCode()} />
          {done && <div>
            {modify ?            <p>Votre code promo a bien été modifié</p> :

            <p>Votre code promo a bien été publié</p>
          }
            </div>}
        </div>
      </Dialog>
    );
  }

  const deletePromoCode = async (id) => {
    await axios.delete(`${apiUrl}/api/promocodes/${id}`);
  };

  const columns = [
    {
      field: "nom",
      headerName: "Nom",
      width: 200,
      headerAlign: "left",
      align: "left",
      flex: 3,
      editable: false,
    },
    {
      field: "type",
      headerName: "Type",
      align: "left",
      headerAlign: "left",
      width: 200,
      editable: false,
    },
    {
      headerName: "Valeur",
      editable: false,
      align: "left",
      headerAlign: "left",
      field: "value",
      width: 200,
    },
    {
      field: "Supprimer",
      headerAlign: "center",
      align: "center",
      editable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            text="Supprimer"
            textClassName="text-danger"
            className="bg-danger text-danger mx-3"
            onClick={async () => {
              await deletePromoCode(params.row._id);
              window.location.reload();
            }}
          />
        );
      },
    },
    {
      field: "Modifier",
      editable: false,
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            <Button text="Modifier" onClick={() => {
              setPromoCode(params.row)
              setModify(true)
              setOpen(true)}}
              />
          </div>
        );
      },
    },
  ];

  return (
    <div className="d-flex">
      <SideBar />
      <div className="add-formation-container">
        <HeaderAdmin text="Code promo" />
        <Button
          text="Ajouter un code promo"
          style={{
            width: "20%",
            paddingTop: "1%",
            paddingBottom: "1%",
            marginTop: "2%",
            marginRight: "2%",
            marginBottom: "2%",
            marginLeft: "auto",
          }}
          onClick={handleClickOpen}
        />

        {promoCodes && promoCodes.length !== 0 ? (
          <CustomDataGrid data={promoCodes} columns={columns} height="80vh" />
        ) : (
          <h5>Aucun code promo n'est en ligne</h5>
        )}

        <SimpleDialog
          open={open}
          promoCode={promoCode}
          onClose={handleClose}
          promoCodes={promoCodes}
          modify={modify}
        />
      </div>
    </div>
  );
}

export default AddPromoCodeScreen;
