import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { Link, Element } from "react-scroll";
import { Grid } from "@mui/material";
import NavBar from "../components/NavBar";
import gestionProjet from "../assets/illu_gestion de projet.svg";
import expertiseIT from "../assets/illu_expertiseIT.svg";
import frame from "../assets/Frame.svg";
import buildings from "../assets/buildings.svg";
import gestionprojet from "../assets/illu_gestion de projet.svg";
import illuProjet from "../assets/illu_suivi.svg";
import avion from "../assets/avion.svg";
import ExpertisesITCard from "../components/ExpertisesITCard";
import cardIT1 from "../assets/cardIT1.png";
import Footer from "../components/Footer";
import nuage from "../assets/nuageService1.svg";
import nuage2 from "../assets/nuageService2.svg";
import nuage3 from "../assets/nuageService3.svg";
import nuage4 from "../assets/nuageService4.svg";
import ondulationServices from "../assets/ondulation_services.png";
import ondulationIT from "../assets/curved_route.svg";
import cybersecurity from "../assets/cybersecurity.svg";
import ingenierieApplicative from "../assets/ingenierie_applicative.svg";
import expertisesIt from "../assets/expertises_it.webp";

function ServicesScreen() {
  const formation = {
    titre: "Infrastructures",
    codeHexaBulletPoint: "#26BC90",
    point1:
      "Maîtrisez votre gestion des infrastructures et de la production avec notre expertise en :",
    point2: "Migration vers le Cloud ou hybridation des infrastructures",
    point3:
      "Définition des architectures cibles et identification des technologies les plus adaptées",
    point4: "Mise en place des pratiques DevOps",
    point5: "Pilotage de la mise en œuvre efficace des infrastructures",
    point6:
      "Configuration des composants pour une capacité adaptable aux évolutions de vos besoins",
    point7: "Gestion des opérations en toute confiance",
    point8: "#Datacenter #Virtualisation #Postedetravail #Reseau #Télécom",
    image: cardIT1,
  };

  const formation2 = {
    titre: "Ingénierie Applicative",
    codeHexaBulletPoint: "#26BC90",
    point1:
      "S’applique à la conception, le développement et la maintenance d’applications et de logiciels",
    point2:
      "Conseil en gestion de projet, incluant la définition des besoins métiers, l'étude de faisabilité, la définition de l'architecture technique et l'audit technologique...",
    point3:
      "Conduite de projets de développement d'applications et de logiciels, avec une focus sur la gestion des délais, des cycles de projet et budgétaires",
    point4:
      "Recettage d'applications ou de logiciels, y compris la stratégie et les plans de tests, le suivi des anomalies et la documentation",
    point5:
      "Maintenance quotidienne, corrective, évolutive et préventive, ainsi que support pour les utilisateurs et l’accompagnement",
    image: ingenierieApplicative,
  };

  const formation3 = {
    titre: "Cybersécurité",
    codeHexaBulletPoint: "#26BC90",
    point1: "Gestion de la sécurité et pilotage projets",
    point2: "Conception et maintenance d’un SI sécurisé",
    point3: "Gestion des incidents et des crises",
    point4: "Audit SSI",
    point5: "Gouvernance, Risques et Conformité (GRC)",
    point6: "Security Operation Center (SOC)",
    point7: "Gestion des identités et des accès (IAM)",
    point8: "IT/OT",
    image: cybersecurity,
  };
  return (
    <div>
      <NavBar active="services" />
      <div className="services-presentation-container services-container pt-5">
        <h1 className="text-center mb-5 services-title">Nos services</h1>
        <div className="services-card-container">
          <div className="services-card">
            <div className="img-services-card-container">
              <img src={gestionProjet} alt="" className="img-services-card" />
            </div>
            <h5 className="services-card-title-mobile">
              Conseil en gestion de projet
            </h5>
            <h5 className="services-card-title service-card-title-desktop">
              Conseil en gestion
            </h5>
            <h5 className="second-services-title service-card-title-desktop">
              de projet
            </h5>
            <div className="d-flex flex-row go-detail expertise-it-detail">
              <Link to="link-to" spy offset={-120}>
                <p className="formation-detail">Voir le détail</p>
              </Link>
              <FontAwesomeIcon
                icon={faArrowRight}
                color="#6633CC"
                className="arrow-icon"
              />
            </div>
          </div>
          <div className="services-card">
            <div className="img-services-card-container">
              <img src={frame} alt="" className="img-services-card" />
            </div>
            <h5 className="services-card-title-mobile">Conseil stratégique</h5>
            <h5 className="services-card-title service-card-title-desktop">
              Conseil{" "}
            </h5>
            <h5 className="second-services-title service-card-title-desktop">
              stratégique
            </h5>
            <div className="d-flex flex-row go-detail expertise-it-detail">
              <Link to="conseil-strategique" spy offset={-120}>
                <p className="formation-detail">Voir le détail</p>
              </Link>
              <FontAwesomeIcon
                icon={faArrowRight}
                color="#6633CC"
                className="arrow-icon"
              />
            </div>
          </div>
          <div className="services-card">
            <div className="img-services-card-container">
              <img src={expertiseIT} alt="" className="img-services-card" />
            </div>
            <h5 className="services-card-title services-card-title-desktop">
              Expertises IT
            </h5>
            <h5 className="services-card-title services-card-title-mobile">
              Expertises IT
            </h5>{" "}
            <div className="d-flex flex-row go-detail expertise-it-detail">
              <Link to="expertise-it" spy offset={-20}>
                <p className="formation-detail">Voir le détail</p>
              </Link>
              <FontAwesomeIcon
                icon={faArrowRight}
                color="#6633CC"
                className="arrow-icon"
              />
            </div>
          </div>
        </div>

        <img src={buildings} alt="" className="building-img" />
        <div id="gestion-projet" />
      </div>
      <Element name="link-to" className="link-to">
        <div className="conseil-gestion-services-container">
          <h2 className="text-center pt-5">Conseil en gestion de projet</h2>
          <div className="conseil-gestion py-5">
            <div className="img-gestion-container">
              <img src={gestionprojet} alt="" className="img-gestion" />
            </div>

            <div className="description-services-container">
              <p>
                Nous réalisons des prestations gravitant autour de la direction
                de projet, en maitrisant la qualité, les coûts, les délais et
                les aspects techniques et fonctionnelles dans les domaines de
                l’infrastructure, l’ingénierie applicative et la cybersécurité.
              </p>
              <br />
              <p>
                Nous fournissons une approche personnalisée et adaptée aux
                méthodologies à appliquer, en adoptant une orientation agile
                pour livrer les solutions dans les délais impartis et poursuivre
                leur amélioration en continu.
              </p>
              <br />
              <p>Nous sommes convaincus par :</p>
              <div className="check-services-container my-3">
                <FontAwesomeIcon
                  icon={faCheck}
                  color="green"
                  className="arrow-icon"
                />
                <p className="mx-2">L’optimisation financière des projets</p>
              </div>
              <div className="check-services-container my-3">
                <FontAwesomeIcon
                  icon={faCheck}
                  color="green"
                  className="arrow-icon"
                />
                <p className="mx-2">
                  La sécurisation des SI et la conformité liée à la RGPD{" "}
                </p>
              </div>
              <div className="check-services-container my-3">
                <FontAwesomeIcon
                  icon={faCheck}
                  color="green"
                  className="arrow-icon"
                />
                <p className="mx-2">
                  L’amélioration de l’expérience client/utilisateur{" "}
                </p>
              </div>
              <div className="check-services-container my-3">
                <FontAwesomeIcon
                  icon={faCheck}
                  color="green"
                  className="arrow-icon"
                />
                <p className="mx-2">
                  L’amélioration de la collaboration et communication
                </p>
              </div>
              <div className="check-services-container my-3">
                <FontAwesomeIcon
                  icon={faCheck}
                  color="green"
                  className="arrow-icon"
                />
                <p className="mx-2">
                  Une meilleure productivité associée à la qualité{" "}
                </p>
              </div>
              <div className="check-services-container my-3">
                <FontAwesomeIcon
                  icon={faCheck}
                  color="green"
                  className="arrow-icon"
                />
                <p className="mx-2">
                  Notre référentiel : ITIL, PMP, Prince2, ISO2700X, CMMI…{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Element>

      <div className="other-conseil-gestion pt-5">
        <div className="conseil-gestion">
          <div className="description-services-container">
            <p className="font-weight-bold">
              Nous maitrisons les 10 domaines de connaissance de la gestion de
              projets avec ses processus associés :
            </p>
            <div className="check-services-container my-3">
              <FontAwesomeIcon
                icon={faCheck}
                color="green"
                className="arrow-icon"
              />
              <p className="mx-2">Management de l’intégration du projet</p>
            </div>
            <div className="check-services-container my-3">
              <FontAwesomeIcon
                icon={faCheck}
                color="green"
                className="arrow-icon"
              />
              <p className="mx-2">Management du contenu du projet</p>
            </div>
            <div className="check-services-container my-3">
              <FontAwesomeIcon
                icon={faCheck}
                color="green"
                className="arrow-icon"
              />
              <p className="mx-2">Management des délais du projet</p>
            </div>
            <div className="check-services-container my-3">
              <FontAwesomeIcon
                icon={faCheck}
                color="green"
                className="arrow-icon"
              />
              <p className="mx-2">Management des coûts du projet</p>
            </div>
            <div className="check-services-container my-3">
              <FontAwesomeIcon
                icon={faCheck}
                color="green"
                className="arrow-icon"
              />
              <p className="mx-2">Management de la qualité du projet</p>
            </div>
            <div className="check-services-container my-3">
              <FontAwesomeIcon
                icon={faCheck}
                color="green"
                className="arrow-icon"
              />
              <p className="mx-2">
                Management des ressources humaines du projet
              </p>
            </div>
            <div className="check-services-container my-3">
              <FontAwesomeIcon
                icon={faCheck}
                color="green"
                className="arrow-icon"
              />
              <p className="mx-2">Management des communications du projet</p>
            </div>
            <div className="check-services-container my-3">
              <FontAwesomeIcon
                icon={faCheck}
                color="green"
                className="arrow-icon"
              />
              <p className="mx-2">Management des risques du projet</p>
            </div>
            <div className="check-services-container my-3">
              <FontAwesomeIcon
                icon={faCheck}
                color="green"
                className="arrow-icon"
              />
              <p className="mx-2">
                Management des approvisionnements du projet
              </p>
            </div>
            <div className="check-services-container my-3">
              <FontAwesomeIcon
                icon={faCheck}
                color="green"
                className="arrow-icon"
              />
              <p className="mx-2">Management des parties prenantes du projet</p>
            </div>
          </div>

          <div className="img-gestion-container">
            <img src={illuProjet} alt="" className="img-gestion" />
          </div>
        </div>
        <div id="conseil-strategique" />
        <img src={ondulationServices} alt="" className="onludation" />
      </div>

      <div className="conseil-strat-container">
        <h2 className="text-center my-5">Conseil stratégique</h2>
        <div className="d-flex">
          <div className="inner-conseil-strat-container my-5">
            <div className="img-ballon-container">
              <img src={avion} alt="" className="img-gestion" />
              <div className="float-area nuage-service2">
                <img src={nuage2} alt="" className="floating-img" />
              </div>

              <div className="float-area nuage-service3">
                <img src={nuage3} alt="" className="floating-img" />
              </div>

              <div className="float-area nuage-service4">
                <img src={nuage4} alt="" className="floating-img" />
              </div>
            </div>
            <div className="float-area nuage-service1">
              <img src={nuage} alt="" className="floating-img" />
            </div>

            <div className="description-ballon-container">
              <p>
                Notre offre de conseil en stratégie de transformation vise à
                aider les entreprises à moderniser leur système d'information et
                leur organisation pour devenir plus agiles et performantes dans
                un contexte de digitalisation de l'économie. Pour cela, nous
                proposons une réorganisation en profondeur de votre structure
                autour de quatre axes : les enjeux, les méthodes, les outils et
                les métiers.
              </p>
              <br />
              <p>
                Nous guidons les entreprises dans la transformation de leur
                organisation en rationalisant les ressources, structurant les
                programmes de transformation et pilotant leur activité pour
                atteindre l'efficience opérationnelle. Nous travaillons
                également sur la transformation du système d'information à
                toutes les étapes du projet et facilitons la transformation
                digitale en activant plusieurs leviers, tels que le traitement
                et la publication de l'information, la simplification des
                interactions avec les clients et la personnalisation des offres.
              </p>
            </div>
          </div>
        </div>

        <div className="description-route-container">
          <div className="check-services-container">
            <FontAwesomeIcon
              icon={faArrowRight}
              color="#1E90FF"
              className="arrow-icon"
            />
            <p className="mx-2">Définir les objectifs</p>
          </div>
          <div className="check-services-container">
            <FontAwesomeIcon
              icon={faArrowRight}
              color="#1E90FF"
              className="arrow-icon"
            />
            <p className="mx-2">Identifier les marchés</p>
          </div>
          <div className="check-services-container">
            <FontAwesomeIcon
              icon={faArrowRight}
              color="#1E90FF"
              className="arrow-icon"
            />
            <p className="mx-2">Identifier la cible</p>
          </div>
          <div className="check-services-container">
            <FontAwesomeIcon
              icon={faArrowRight}
              color="#1E90FF"
              className="arrow-icon"
            />
            <p className="mx-2">
              Etablir sa stratégie de communication et son positionnement
            </p>
          </div>
          <div className="check-services-container">
            <FontAwesomeIcon
              icon={faArrowRight}
              color="#1E90FF"
              className="arrow-icon"
            />
            <p className="mx-2">Mettre en place une politique de prix</p>
          </div>
          <div className="check-services-container">
            <FontAwesomeIcon
              icon={faArrowRight}
              color="#1E90FF"
              className="arrow-icon"
            />
            <p className="mx-2">
              Choisir les outils et tactiques numériques à développer
            </p>
          </div>
          <div className="check-services-container">
            <FontAwesomeIcon
              icon={faArrowRight}
              color="#1E90FF"
              className="arrow-icon"
            />
            <p className="mx-2">Etablir une politique de distribution</p>
          </div>
          <div className="check-services-container">
            <FontAwesomeIcon
              icon={faArrowRight}
              color="#1E90FF"
              className="arrow-icon"
            />
            <p className="mx-2">Budgétiser vos actions</p>
          </div>
          <div className="check-services-container">
            <FontAwesomeIcon
              icon={faArrowRight}
              color="#1E90FF"
              className="arrow-icon"
            />
            <p className="mx-2">Mesurer vos indicateurs de performance (KPI)</p>
          </div>
          <div className="check-services-container">
            <FontAwesomeIcon
              icon={faArrowRight}
              color="#1E90FF"
              className="arrow-icon"
            />
            <p className="mx-2">
              Mettre en place une feuille de route claire et précise
            </p>
          </div>
          <div id="expertise-it" />
        </div>
      </div>

      <img src={ondulationIT} alt="" className="road-image" />
      <img src={expertisesIt} alt="" className="expertisesIT-img" />

      <div className="card-it-supercontainer pb-5">
        <h2 className="text-center mb-5 pb-5">Expertises IT</h2>
        <Grid
          container
          sx={{ mt: 5 }}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12, lg: 12, xl: 12 }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4} sx={{ mt: 5 }}>
            <ExpertisesITCard formation={formation} />{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4} sx={{ mt: 5 }}>
            <ExpertisesITCard formation={formation2} />{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4} sx={{ mt: 5 }}>
            <ExpertisesITCard formation={formation3} lastCard />{" "}
          </Grid>
        </Grid>
      </div>

      <Footer />
    </div>
  );
}

export default ServicesScreen;
