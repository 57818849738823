import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

function CGVScreen() {
  return (
    <div className="">
      <NavBar />

      <div className="bg-light d-flex flex-column min-vh-100 cgu-container">
        <div className="block-1 h-100">
          <h1 className="text-center text-white mt-5">
            CONDITIONS GENERALES DE VENTE
          </h1>
          <p className="text-center mb-5 text-white">
            En vigueur au 03/04/2023
          </p>
        </div>

        <div className="container">
          <div className="my-4">
            <h3>ARTICLE 1 - Champ d'application</h3>
            <p>
              Les présentes Conditions Générales de Vente (dites « CGV »)
              s'appliquent, sans restriction ni réserve à tout achat des
              services suivants :
            </p>
            <br />
            <p className="font-weight-bold">
              Talent1 propose des conseils dans les systèmes et technologies de
              l’information, des formations et du coaching sur mesure.
            </p>{" "}
            <br />
            <p>
              Les caractéristiques principales des Services sont présentées sur
              le site internet http://www.talent1.io. Le Client est tenu d'en
              prendre connaissance avant toute passation de commande. Le choix
              et l'achat d'un Service est de la seule responsabilité du Client.
              <br />
              <br />
              Ces CGV sont accessibles à tout moment sur le site
              http://www.talent1.io et prévaudront sur tout autre document.{" "}
              <br />
              <br />
              Le Client déclare avoir pris connaissance des présentes CGV et les
              avoir acceptées en cochant la case prévue à cet effet avant la
              mise en œuvre de la procédure de commande en ligne du site
              http://www.talent1.io. Sauf preuve contraire, les données
              enregistrées dans le système informatique du Prestataire
              constituent la preuve de l'ensemble des transactions conclues avec
              le Client.
              <br />
              <br />
            </p>
            <p>
              Les coordonnées du Prestataire sont les suivantes :{" "}
              <p className="font-weight-bold mt-3">TALENT1, SASU</p>
              <p>Capital social de 500 euros</p>
              <p>
                {" "}
                Immatriculé au RCS de Pontoise, sous le numéro B 898 635 115
              </p>
              <p>95 BIS RUE JEAN MERMOZ – 95230 SOISY-SOUS-MONTMORENCY</p>
              <p>Mail : contact@talent1.fr </p>
              <p>
                Des droits de douane ou autres taxes locales ou droits
                d'importation ou taxes d'état sont susceptibles d'être
                exigibles. Ils seront à la charge et relèvent de la seule
                responsabilité du Client.
              </p>
            </p>
          </div>

          <div className="my-4">
            <h3>ARTICLE 2 - Prix</h3>
            <p>
              Les Services sont fournis aux tarifs en vigueur figurant sur le
              site http://www.talent1.io, lors de l'enregistrement de la
              commande par le Prestataire. Les prix sont exprimés en Euros, HT
              et TTC. <br />
              <br /> Ces tarifs sont fermes et non révisables pendant leur
              période de validité mais le Prestataire se réserve le droit, hors
              période de validité, d’en modifier les prix à tout moment. <br />{" "}
              <br />
              Le paiement demandé au Client correspond au montant total de
              l'achat, y compris ces frais. <br /> <br /> Une facture est
              établie par le Prestataire et remise au Client de manière
              automatique à sa messagerie électronique.
            </p>
          </div>

          <div className="my-4">
            <h3>ARTICLE 3 – Commandes</h3>
            <p>
              Il appartient au Client de sélectionner sur le site
              http://www.talent1.io les Services qu'il désire commander, selon
              les modalités suivantes : <br /> <br />
              <p>
                {" "}
                Le client choisi une formation ou une séance de coaching qu'il
                met dans son panier. Le client a la possibilité de supprimer et
                modifier avant de valider sa commande. Lors de son inscription,
                le client a accepté les présentes conditions générales de vente.
                Il rentrera ensuite ses coordonnées ou se connectera à son
                espace client. Après validation des informations et paiement, la
                commande sera considérée comme définitive.
                <br /> <br />
              </p>
              <p>
                {" "}
                La vente ne sera considérée comme valide qu’après paiement
                intégral du prix. Il appartient au Client de vérifier
                l'exactitude de la commande et de signaler immédiatement toute
                erreur.
                <br /> <br />
              </p>
              <p>
                {" "}
                Toute commande passée sur le site http://www.talent1.io
                constitue la formation d'un contrat conclu à distance entre le
                Client et le Prestataire.
                <br /> <br />
              </p>
              <p>
                {" "}
                Le Prestataire se réserve le droit d'annuler ou de refuser toute
                commande d'un Client avec lequel il existerait un litige relatif
                au paiement d'une commande antérieure.
                <br /> <br />
              </p>
              <p>
                {" "}
                Le Client pourra suivre l'évolution de sa commande sur le site.
              </p>
            </p>
          </div>

          <div className="my-4">
            <h3>ARTICLE 4 - Conditions de paiement</h3>
            <p>
              Le prix est payé par voie de paiement sécurisé, selon les
              modalités suivantes : <br /> <br />• Paiement par carte bancaire{" "}
              <br /> <br />
              Le prix est payable comptant par le Client, en totalité au jour de
              la pasation de la commande. <br /> <br /> Les données de paiement
              sont échangées en mode crypté grâce au protocole défini par le
              prestataire de paiement agréé intervenant pour les transactions
              bancaires réalisées sur le site http://www.talent1.io. <br />{" "}
              <br />
              Les paiements effectués par le Client ne seront considérés comme
              définitifs qu'après encaissement effectif des sommes dues, par le
              Prestataire. <br /> <br />
              Le Prestataire ne sera pas tenu de procéder à la fourniture des
              Services commandés par le Client si celui-ci ne lui en paye pas le
              prix en totalité dans les conditions ci-dessus indiquées.
            </p>
          </div>

          <div className="my-4">
            <h3>ARTICLE 5 - Fourniture des Prestations</h3>
            <p>
              Les Services commandés par le Client seront fournis selon les
              modalités suivantes : Les séances de formations / Coaching sont
              dispensées de deux manières :
              <p className="mt-3 font-weight-bold">1. En visioconférence :</p>
              Talent1 n'est pas responsable, en cas d'incident technique coté
              Client. <br /> <br />
              <p>
                Le client s'engage à vérifier le bon fonctionnement de ses
                équipements :
              </p>
              <ul>
                <li>
                  <p>Débit internet avec à minima 20M</p>
                </li>
                <li>
                  <p>Microphone</p>
                </li>
                <li>
                  <p>Caméra</p>
                </li>
                <li>
                  <p>Écrans externes</p>
                </li>
              </ul>
              <p>
                En cas de défaillance de Talent1, la séance sera reportée ou
                remboursée dans son intégralité au Client.
              </p>
              <p className="mt-3 font-weight-bold">2. Sur site :</p>
              <p>
                Talent1 s'engage à communiquer toutes les informations sur la
                date, heure, lieux et condition d'accès pour suivre la formation
                à minima 3 jours avant la date de formation / coaching. <br />{" "}
                <br />
                <p />
                <p>
                  {" "}
                  Le client devra apporter son ordinateur personnel pour être
                  dans les meilleurs conditions lors des éventuelles
                  manipulations. Talent1 ne fournira pas un poste de travail le
                  jour de la formation. Dans les deux cas, Talent1 s'engage à
                  communiquer toutes les informations 3 jours ouvrés avant la
                  formation / coaching. <br /> <br />
                </p>
                <p>
                  {" "}
                  Lesdits Services seront fournis dans un délai maximum de
                  Talent1 s'engage à communiquer toutes les informations 3 jours
                  ouvrés avant la formation / coaching. <br /> <br />
                </p>
                <p>
                  {" "}
                  En cas de défaillance de Talent1, s'engage soit à reporter la
                  formation / coaching ou rembourser sans frais le Client dans
                  les conditions prévues aux présentes CGV à l'adresse indiquée
                  par le Client lors de sa commande sur le site
                  http://www.talent1.io.
                  <br /> <br />
                </p>
                <p>
                  {" "}
                  Le Prestataire s'engage à faire ses meilleurs efforts pour
                  fournir les Services commandés par le Client, dans le cadre
                  d'une obligation de moyen et dans les délais ci-dessus
                  précisés.
                  <br /> <br />
                </p>
                <p> Conditions de remboursement du Client :</p> <br />
                Le Client doit envoyer un mail à contact@talent1.fr avec pour
                object : REMBOURSEMENT
              </p>
              <ul>
                <li>
                  <p>
                    4 jours avant la date de formation, le client sera remboursé
                    à 75%
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    3 jours avant la date de formation, le client sera remboursé
                    à 50%
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    2 jours avant la date de formation, le client sera remboursé
                    à 25%
                  </p>
                </li>
                <li>
                  <p> 1 jour ou jour J, le client ne sera pas remboursé.</p>
                </li>
              </ul>
              <p>
                Toutes absences (justifiées ou non justifiée), non présence, les
                frais engagés seront acquis à Talent1. <br /> <br />
                Talent1 se réserve le droit de repositionner le Client à une
                autre session en fonction du planning et disponibilité. <br />{" "}
                <br /> Aucune réclamation n’est possible dès lors la formation /
                coaching est effectuée.
              </p>
            </p>
            <br />
          </div>

          <div className="my-4">
            <h3>ARTICLE 6 - Droit de rétractation</h3>
            <p>
              Le droit de rétractation ne s’applique pas à l’achat de formations
              en ligne, coaching.
            </p>
          </div>

          <div className="my-4">
            <h3>ARTICLE 7 - Données personnelles</h3>
            <p>
              Le Client est informé que la collecte de ses données à caractère
              personnel est nécessaire à la vente des Services et leur
              réalisation et délivrance, confiées au Prestataire. Ces données à
              caractère personnel sont récoltées uniquement pour l’exécution du
              contrat de prestations de services. <br /> <br />
            </p>
            <p className="font-weight-bold">
              7.1 Collecte des données à caractère personnel
            </p>
            <p>
              Les données à caractère personnel qui sont collectées sur le site
              http://www.talent1.io sont les suivantes : <br /> <br />
            </p>
            <p className="font-weight-bold">Ouverture de compte</p>
            <p>
              {" "}
              Lors de la création du compte Client / utilisateur : Noms,
              prénoms, adresse postale, numéro de téléphone et adresse e-mail.
            </p>
            <br /> <br />
            <p className="font-weight-bold">Paiement</p>
            <p>
              {" "}
              Dans le cadre du paiement des Prestations proposés sur le site
              http://www.talent1.io, celui-ci enregistre des données financières
              relatives au compte bancaire ou à la carte de crédit du Client /
              utilisateur.
            </p>
            <br /> <br />
            <p className="font-weight-bold">
              7.2 Destinataires des données à caractère personnel
            </p>
            <p>
              {" "}
              Les données à caractère personnel sont réservées à l’usage unique
              du Prestataire. Le responsable de traitement des données est le
              Prestataire, au sens de la loi Informatique et libertés et à
              compter du 25 mai 2018 du Règlement 2016/679 sur la protection des
              données à caractère personnel.
            </p>
            <br /> <br />
            <p className="font-weight-bold">7.3 Limitation du traitement</p>
            <p>
              {" "}
              Sauf si le Client exprime son accord exprès, ses données à
              caractère personnelles ne sont pas utilisées à des fins
              publicitaires ou marketing.
            </p>
            <br /> <br />
            <p className="font-weight-bold">
              7.4 Durée de conservation des données
            </p>
            <p>
              {" "}
              Le Prestataire conservera les données ainsi recueillies pendant un
              délai de 5 ans, couvrant le temps de la prescription de la
              responsabilité civile contractuelle applicable.
            </p>
            <br /> <br />
            <p className="font-weight-bold">7.5 Sécurité et confidentialité</p>
            <p>
              {" "}
              Le Prestataire met en œuvre des mesures organisationnelles,
              techniques, logicielles et physiques en matière de sécurité du
              numérique pour protéger les données personnelles contre les
              altérations, destructions et accès non autorisés. Toutefois il est
              à signaler qu’Internet n’est pas un environnement complètement
              sécurisé et le Prestataire ne peut garantir la sécurité de la
              transmission ou du stockage des informations sur Internet.
            </p>
            <br /> <br />
            <p className="font-weight-bold">
              7.6 Mise en œuvre des droits des Clients et utilisateurs
            </p>
            <p>
              {" "}
              En application de la règlementation applicable aux données à
              caractère personnel, les Clients et utilisateurs du site
              http://www.talent1.io disposent des droits suivants :
            </p>
            <ul>
              <li>
                <p>
                  {" "}
                  Ils peuvent mettre à jour ou supprimer les données qui les
                  concernent de la manière suivante : En se connectant à son
                  compte, rubrique "Supprimer mon compte" ou en contactant
                  l'administrateur avec le formulaire de contact.
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  Ils peuvent supprimer leur compte en écrivant à l’adresse
                  électronique indiqué à l’article 9.3 « Responsable de
                  traitement »
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  Ils peuvent exercer leur droit d’accès pour connaître les
                  données personnelles les concernant en écrivant à l’adresse
                  indiqué à l’article 9.3 « Responsable de traitement »{" "}
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  Si les données à caractère personnel détenues par le
                  Prestataire sont inexactes, ils peuvent demander la mise à
                  jour des informations des informations en écrivant à l’adresse
                  indiqué à l’article 9.3 « Responsable de traitement »{" "}
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  Ils peuvent demander la suppression de leurs données à
                  caractère personnel, conformément aux lois applicables en
                  matière de protection des données en écrivant à l’adresse
                  indiqué à l’article 9.3 « Responsable de traitement »
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  Enfin, ils peuvent s’opposer au traitement de leurs données
                  par le Prestataire
                </p>
              </li>
            </ul>
            <p>
              {" "}
              Ces droits, dès lors qu’ils ne s’opposent pas à la finalité du
              traitement, peuvent être exercé en adressant une demande par
              courrier ou par E-mail au Responsable de traitement dont les
              coordonnées sont indiquées ci-dessus. <br /> <br />
            </p>
            <p>
              {" "}
              Le responsable de traitement doit apporter une réponse dans un
              délai maximum d’un mois. En cas de refus de faire droit à la
              demande du Client, celui-ci doit être motivé. Le Client est
              informé qu’en cas de refus, il peut introduire une réclamation
              auprès de la CNIL (3 place de Fontenoy, 75007 PARIS) ou saisir une
              autorité judiciaire.
              <br /> <br />
            </p>
            <p>
              {" "}
              Le Client peut être invité à cocher une case au titre de laquelle
              il accepte de recevoir des mails à caractère informatifs et
              publicitaires de la part du Prestataire. Il aura toujours la
              possibilité de retirer son accord à tout moment en contactant le
              Prestataire (coordonnées ci-dessus) ou en suivant le lien de
              désabonnement.
            </p>
          </div>

          <div className="my-4">
            <h3>8 - Droit applicable - Langue</h3>
            <p>
              Les présentes CGV et les opérations qui en découlent sont régies
              et soumises au droit français. Les présentes CGV sont rédigées en
              langue française. Dans le cas où elles seraient traduites en une
              ou plusieurs langues étrangères, seul le texte français ferait foi
              en cas de litige.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default CGVScreen;
