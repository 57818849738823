import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_CLEAR_ERROR,
} from "../constants/cartConstants";
import { apiUrl } from "../constants/apiUrl";

export const addToCart = (id, qty) => async (dispatch, getState) => {
  const { data } = await axios.get(`${apiUrl}/api/formations/${id}`);

  console.log("data", data);
  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data._id,
      encadre: data.encadre,
      thematique: data.thematique,
      codeHexaEncadre: data.codeHexaEncadre,
      codeHexaThematique: data.codeHexaThematique,
      titre: data.titre,
      description: data.description,
      prixHT: data.prixHT,
      prixTTC: data.prixTTC,
      publique: data.publique,
      startDate: data.startDate,
      endDate: data.endDate,
      duration: data.duration,
      format: data.format,
      codeHexaBulletPoint: data.codeHexaBulletPoint,
      point1: data.point1,
      point2: data.point2,
      point3: data.point3,
      point4: data.point4,
      point5: data.point5,
      point6: data.point6,
      point7: data.point7,
      point8: data.point8,
      point9: data.point9,
      point10: data.point10,
      title1: data.title1,
      title2: data.title2,
      title3: data.title3,
      title4: data.title4,
      description1: data.description1,
      description2: data.description2,
      description3: data.description3,
      description4: data.description4,
      rank: data.rank,
      qty,
      boughtAt: new Date(),
    },
  });

  localStorage.setItem(
    "cartItemsTalent1",
    JSON.stringify(getState().cart.cartItems),
  );
};

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem(
    "cartItemsTalent1",
    JSON.stringify(getState().cart.cartItems),
  );
};

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });
  localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem("paymentMethod", JSON.stringify(data));
};

export const clearError = () => (dispatch) => {
  console.log("hqsdfsjdsjd");
  dispatch({
    type: CART_CLEAR_ERROR,
  });
};
