import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import WhiteButton from "../WhiteButton";
import Button from "../Button";
import CustomDataGrid from "../CustomDataGrid";

function MesFormations({ orders }) {
  const columns = [
    {
      field: "formationTitle",
      headerName: "Formations",
      editable: false,
      minWidth: 90,
      headerAlign: "center",
      align: "left",
      flex: 1,
    },
    {
      field: "Date",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      editable: false,
      renderCell: (params) => {
        console.log(params);
        return (
          <p>
            Du {params.row.startDate} au {params.row.endDate}
          </p>
        );
      },
    },
    {
      field: "Quizz",
      editable: false,
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: (params) => {
        if (params.row.quizz) {
          return (
            <a href={params.row.quizz} target="_blank" rel="noreferrer">
              <Button
                text="Voir le quizz"
                className="p-2"
                onClick={() => console.log(params)}
              />
            </a>
          );
        }
        return <p>Le quizz n'est pas encore disponible</p>;
      },
    },
    {
      field: "Certificats",
      editable: false,
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: (params) => {
        if (params.row.certificat) {
          return (
            <a
              href={params.row.certificat}
              download
              target="_blank"
              rel="noreferrer"
            >
              <Button
                text="Télécharger"
                className="p-2"
                onClick={() => console.log(params)}
              />
            </a>
          );
        }
        return (
          <p className="py-3">Le certificat n'est pas encore disponible</p>
        );
      },
    },
  ];
  return <CustomDataGrid data={orders} columns={columns} height={500} />;
}

export default MesFormations;
