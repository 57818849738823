import React, { useEffect, useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import WhiteButton from "../WhiteButton";
import AdresseCard from "./AdresseCard";
import AuthContext from "../../context/currentUserContext";
import { apiUrl } from "../../constants/apiUrl";
import CustomUpload from "../CustomUpload";
import CustomInput from "../CustomInput";

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const { currentUser, setIsLoggedIn, setCurrentUser } =
    useContext(AuthContext);

  const navigate = useNavigate();
  const handleDelete = async () => {
    try {
      await axios.delete(`${apiUrl}/api/users/${currentUser._id}`);
      localStorage.removeItem("userInfoTalent1");
      setIsLoggedIn(false);
      setCurrentUser(null);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };
  const styles = {
    container: {
      maxWidth: "400px",
    },
    alignCenter: {
      textAlign: "center",
    },
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={styles.container}>
        <h4 style={styles.alignCenter}>Suppression de votre compte</h4>
        <p style={styles.alignCenter}>
          Etes-vous sur de vouloir supprimer votre compte ?
        </p>
        <div className="d-flex float-right">
          <WhiteButton
            text="Annuler"
            className="mx-3"
            style={{ marginTop: "5%", borderRadius: "5px" }}
            onClick={handleClose}
          />
          <Button
            text="Supprimer"
            style={{ marginTop: "5%", borderRadius: "5px" }}
            onClick={handleDelete}
          />
        </div>
      </div>
    </Dialog>
  );
}

function MesInformations({ currentUser, setCurrentUser }) {
  const [error, setError] = useState("");
  const [modifyPassword, setModifyPassword] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [localisation, setLocalisation] = useState("");
  const [poste, setPoste] = useState("");
  const [phone, setPhone] = useState("");
  const [cvUrl, setCvUrl] = useState("");
  const [modifyDone, setModifyDone] = useState(false);

  useEffect(() => {
    setFirstName(currentUser.firstName);
    setLastName(currentUser.lastName);
    setLocalisation(currentUser.localisation);
    setPoste(currentUser.poste);
    setPhone(currentUser.phone);
  }, [currentUser]);
  const handleClose = () => {
    setOpen(false);
  };
  const modifyUser = async () => {
    const { data } = await axios.put(`${apiUrl}/api/users/profile`, {
      id: currentUser._id,
      firstName,
      lastName,
      poste,
      localisation,
      phone,
      CV: cvUrl,
    });
    setCurrentUser(data);
    setModifyDone(true);
    setTimeout(() => {
      setModifyDone(false);
    }, 2000);
  };

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string(),
    newPassword: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/,
        "Votre mot de passe doit comporter au minimum dix caractères incluant obligatoirement une lettre majuscule, un chiffre et un caractère spécial (@$!%*?&)(#_+=/§).",
      )
      .required("Ce champ est requis"),
    confirmNewPassword: Yup.string()
      .required("Ce champ est requis")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Les mots de passe ne sont pas identiques",
      ),
  });

  const handleForm = async (values) => {
    setError("");
    try {
      const { status } = await axios.post(`${apiUrl}/api/users/login`, {
        email: currentUser.email,
        password: values.currentPassword,
      });
      if (status === 400) {
        setError("Votre mot de passe actuel est erorné");
      } else {
        const { data } = await axios.post(`${apiUrl}/api/users/password`, {
          email: currentUser.email,
          password: values.newPassword,
        });
        setCurrentUser(data);
        setModifyPassword(true);
        setTimeout(() => {
          setModifyPassword(false);
        }, 3000);
      }
    } catch (e) {
      setError("Votre mot de passe actuel est erroné");
    }
  };

  return (
    <div>
      {currentUser && (
        <div>
          <div className="informations">
            <h5>Mes informations</h5>
            <div className="profile-infos-container">
              <CustomInput
                id="outlined-basic"
                label="Prénom"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="text-field-add-formation adresse-input"
              />
              <CustomInput
                id="outlined-basic"
                label="Nom"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="text-field-add-formation adresse-input"
              />
            </div>
            <div className="profile-infos-container">
              <CustomInput
                id="outlined-basic"
                label="Localisation"
                variant="outlined"
                value={localisation}
                onChange={(e) => setLocalisation(e.target.value)}
                className="text-field-add-formation adresse-input"
              />
              <CustomInput
                id="outlined-basic"
                label="Type de poste"
                variant="outlined"
                value={poste}
                onChange={(e) => setPoste(e.target.value)}
                className="text-field-add-formation adresse-input"
              />
            </div>

            <div className="profile-infos-container">
              <CustomInput
                required
                label="Numéro de téléphone"
                className="text-field-add-formation adresse-input"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />

              <div className="upload-profile">
                <CustomUpload
                  text={`${currentUser.firstName}_${currentUser.lastName}.pdf`}
                  file={cvUrl}
                  setFile={setCvUrl}
                />
                <a href={currentUser.CV} download>
                  <p>Télécharger mon CV</p>
                </a>
              </div>
            </div>
            <Button
              text="Sauvegarder"
              className="modifier-container"
              onClick={modifyUser}
            />
            {modifyDone && (
              <div
                className="alert alert-success modification-done"
                role="alert"
              >
                Vos modifications ont été prises en compte !
              </div>
            )}
          </div>

          <div className="informations">
            <AdresseCard
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              title="Votre adresse de facturation"
              textButton="Sauvegarder"
              profile
              style={{
                padding: "0px!important",
              }}
              className="p-0"
            />
          </div>

          <div className="informations">
            <h5>Changement de mot de passe</h5>
            <Formik
              initialValues={initialValues}
              validationSchema={changePasswordSchema}
              onSubmit={(values) => {
                handleForm(values);
              }}
            >
              {({ values, errors, handleChange, handleSubmit }) => (
                <div className="d-flex flex-column">
                  <div className="profile-infos-container">
                    <CustomInput
                      id="outlined-basic"
                      label="Mot de passe actuel"
                      type="password"
                      value={values.currentPassword}
                      onChange={handleChange("currentPassword")}
                      variant="outlined"
                      className="text-field-add-formation adresse-input"
                    />
                  </div>

                  <div className="profile-infos-container d-flex justify-content-between">
                    <div className="d-flex flex-column confirmPasswordInput adresse-input">
                      <CustomInput
                        id="outlined-basic"
                        label="Nouveau mot de passe"
                        variant="outlined"
                        type="password"
                        value={values.newPassword}
                        onChange={handleChange("newPassword")}
                        className="text-field-add-formation w-100"
                      />
                      {errors.newPassword && (
                        <div className="text-danger">{errors.newPassword}</div>
                      )}
                    </div>

                    <div className="d-flex flex-column confirmPasswordInput adresse-input">
                      <CustomInput
                        id="outlined-basic"
                        label="Confirmer le nouveau mot de passe"
                        variant="outlined"
                        type="password"
                        value={values.confirmNewPassword}
                        onChange={handleChange("confirmNewPassword")}
                        className="text-field-add-formation w-100"
                      />
                      {errors.confirmNewPassword && (
                        <div className="text-danger">
                          {errors.confirmNewPassword}
                        </div>
                      )}
                    </div>
                  </div>

                  <Button
                    text="Sauvegarder"
                    className="modifier-container"
                    onClick={handleSubmit}
                  />
                  {error && <div className="text-danger">{error}</div>}
                </div>
              )}
            </Formik>
            {modifyPassword && (
              <div
                className="alert alert-success modification-done"
                role="alert"
              >
                Votre mot de passe a bien été modifié !
              </div>
            )}
          </div>

          <div className="informations">
            <h5>Supprimer le profil</h5>
            <p>
              Vous pouvez supprimer votre profil à tout moment. Votre compte et
              vos données seront supprimés définitivement.{" "}
            </p>
            <Button
              text="Supprimer"
              className="modifier-container mt-5"
              onClick={() => setOpen(true)}
            />
            <SimpleDialog open={open} onClose={handleClose} />
          </div>
        </div>
      )}
    </div>
  );
}

export default MesInformations;
