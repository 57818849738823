import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import SideBar from "../../components/SideBar";
import HeaderAdmin from "../../components/admin/HeaderAdmin";
import { apiUrl } from "../../constants/apiUrl";
import Button from "../../components/Button";
import CustomDataGrid from "../../components/CustomDataGrid";
import deleteImage from "../../assets/Style.svg";
import WhiteButton from "../../components/WhiteButton";
import { sendMail } from "../../utils/sendEmail";

function Users() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function SimpleDialog(props) {
    const { onClose, selectedValue, open, currentUser } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };

    const navigate = useNavigate();

    console.log("currentUser", currentUser);
    const handleDelete = async () => {
      try {
        const response = await sendMail(
          currentUser.firstName,
          currentUser.email,
          currentUser._id,
          7,
        );
        await axios.delete(`${apiUrl}/api/users/${currentUser._id}`);
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    };

    const styles = {
      container: {
        maxWidth: "400px",
      },
      alignCenter: {
        textAlign: "center",
      },
    };
    return (
      <Dialog onClose={handleClose} open={open}>
        <div style={styles.container}>
          <h4 style={styles.alignCenter}>Suppression du compte</h4>
          <p style={styles.alignCenter}>
            Etes-vous sur de vouloir supprimer ce compte ?
          </p>
          <div className="d-flex float-right">
            <WhiteButton
              text="Annuler"
              className="mx-3"
              style={{ marginTop: "5%", borderRadius: "5px" }}
              onClick={handleClose}
            />
            <Button
              text="Supprimer"
              style={{ marginTop: "5%", borderRadius: "5px" }}
              onClick={handleDelete}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  const navigate = useNavigate();
  const columns = [
    {
      field: "firstName",
      headerName: "Prénom",
      width: 200,
      headerAlign: "left",
      align: "left",
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Nom",
      headerAlign: "left",
      align: "left",
      width: 200,
      editable: false,
    },
    {
      headerName: "Profil",
      editable: false,
      field: "poste",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      field: "email",
      width: 250,
      headerName: "Email",
      headerAlign: "left",
      align: "left",
      editable: false,
    },
    {
      field: "formations",
      headerName: "Formations",
      editable: false,
      headerAlign: "left",
      align: "left",
      width: 600,

      renderCell: (params) => {
        console.log(params);
        return (
          <ul className="flex">
            {params.value.map((role, index) => (
              <li key={index}>
                {role.formationTitle} - {role.date}
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      field: "CV",
      headerName: "CV",
      editable: false,
      headerAlign: "center",
      align: "center",
      width: 150,

      renderCell: (params) => (
        <div>
          {params.row.CV && (
            <a href={params.row.CV} target="_blank" rel="noreferrer">
              <Button text="Télécharger" />
            </a>
          )}
        </div>
      ),
    },
    {
      width: 150,
      editable: false,
      headerName: "Profil",
      field: "Profil",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Button
            text="Voir profil"
            onClick={() => {
              console.log("params", params);
              navigate("/admin/view-users", {
                state: {
                  userId: params.row._id,
                },
              });
            }}
          />
        );
      },
    },
    {
      headerName: "",
      editable: true,
      width: 50,
      renderCell: (params) => (
        <div>
          <img
            src={deleteImage}
            className="delete-img"
            alt=""
            onClick={() => {
              setOpen(true);
            }}
          />
          <SimpleDialog
            open={open}
            onClose={handleClose}
            currentUser={params.row}
          />
        </div>
      ),
    },
  ];
  const [users, setUsers] = useState();

  useEffect(() => {
    const getUsers = async () => {
      const { data } = await axios.get(`${apiUrl}/api/users`);
      setUsers(data);
    };
    getUsers();
  }, []);

  return (
    <div className="d-flex">
      <SideBar />
      <div className="users-container">
        <HeaderAdmin text="Les utilisateurs" />
        {users ? (
          <div className="mt-5">
            {" "}
            <CustomDataGrid data={users} columns={columns} height="80vh" />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Users;
