import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

function CGUScreen() {
  return (
    <div className="">
      <NavBar />

      <div className="bg-light d-flex flex-column min-vh-100 text-justify cgu-container">
        <div className="block-1 h-100">
          <h1 className="text-center text-white mt-5">
            CONDITIONS GENERALES D'UTILISATION
          </h1>
          <p className="text-center mb-5 text-white">
            En vigueur au 03/04/2023
          </p>
        </div>

        <div className="container">
          <div className="my-4">
            <p>
              Les présentes conditions générales d'utilisation (dites « CGU »)
              ont pour objet l'encadrement juridique des modalités de mise à
              disposition du site et des services par talent1 et de définir les
              conditions d’accès et d’utilisation des services par «
              l'Utilisateur ».
              <br /> <br />
            </p>
            <p>
              Les présentes CGU sont accessibles sur le site à la rubrique
              «CGU».
              <br /> <br />
            </p>
            <p>
              Toute inscription ou utilisation du site implique l'acceptation
              sans aucune réserve ni restriction des présentes CGU par
              l’utilisateur. Lors de l'inscription sur le site via le Formulaire
              d’inscription, chaque utilisateur accepte expressément les
              présentes CGU en cochant la case précédant le texte suivant : « Je
              reconnais avoir lu et compris les CGU et je les accepte ».
            </p>
            <br />
            <p>
              En cas de non-acceptation des CGU stipulées dans le présent
              contrat, l'Utilisateur se doit de renoncer à l'accès des services
              proposés par le site.
            </p>
            <p>
              Talent1 se réserve le droit de modifier unilatéralement et à tout
              moment le contenu des présentes CGU.
            </p>
          </div>

          <div className="my-4">
            <h3>Article 1 : Les mentions légales</h3>
            <p>
              L’édition et la direction de la publication du site www.talent1.io
              est assurée par Erwan BAGDATLI, domicilié : <br /> <br />
              95 bis rue Jean Mermoz <br />
              95230 Soisy-sous-Montmorency <br /> Numéro de téléphone est
              0635511210 <br /> Adresse e-mail : contact@talent1.fr. <br />{" "}
              <br />
              Talent1 est assujetie au RCS avec le numéro d'inscription Pontoise
              B 898 635 115 et son numéro de TVA intracommunautaire est le
              FR71898635115. L'hébergeur du site talent1.io est la société
              HEROKU, dont le siège social est situé au The Landmark at One
              Market, Suite 300, San Francisco, CA 94105, United States.
            </p>
          </div>

          <div className="my-4">
            <h3>ARTICLE 2 : Accès au site</h3>
            <p>
              Le site talent1.io permet à l'Utilisateur un accès gratuit et
              propose les services suivants :
            </p>
            <ul>
              <li>
                <p> Conseils en système et technologie de l'information.</p>
              </li>
              <li>
                {" "}
                <p>Formations et coaching</p>
              </li>
            </ul>
            <p>
              Le site est accessible gratuitement en tout lieu à tout
              Utilisateur ayant un accès à Internet. Tous les frais supportés
              par l'Utilisateur pour accéder au service (matériel informatique,
              logiciels, connexion Internet, etc.) sont à sa charge. <br />{" "}
              <br />
            </p>
            <p>
              L’Utilisateur non membre n'a pas accès aux services réservés. Pour
              cela, il doit s’inscrire en remplissant le formulaire. En
              acceptant de s’inscrire aux services réservés, l’Utilisateur
              membre s’engage à fournir des informations sincères et exactes
              concernant son état civil et ses coordonnées, notamment son
              adresse email. <br /> <br />
            </p>
            <p>
              Pour accéder aux services, l’Utilisateur doit ensuite s'identifier
              à l'aide de son identifiant et de son mot de passe qui lui seront
              communiqués après son inscription. <br /> <br />
            </p>
            <p>
              Tout Utilisateur membre régulièrement inscrit pourra également
              solliciter sa désinscription en se rendant à la page dédiée sur
              son espace personnel. Celle-ci sera effective dans un délai
              raisonnable. Tout événement dû à un cas de force majeure ayant
              pour conséquence un dysfonctionnement du site ou serveur et sous
              réserve de toute interruption ou modification en cas de
              maintenance, n'engage pas la responsabilité de talent1. Dans ces
              cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur
              de toute interruption ou suspension de service, même sans préavis.{" "}
              <br /> <br />
            </p>
            <p>
              L'Utilisateur a la possibilité de contacter le site par messagerie
              électronique à l’adresse email de l’éditeur communiqué à l’ARTICLE
              1.
            </p>
          </div>

          <div className="my-4">
            <h3>ARTICLE 3 : Collecte des données</h3>
            <p>
              Le site assure à l'Utilisateur une collecte et un traitement
              d'informations personnelles dans le respect de la vie privée
              conformément à la loi n°78-17 du 6 janvier 1978 relative à
              l'informatique, aux fichiers et aux libertés. <br /> <br />
            </p>
            <p>
              En vertu de la loi Informatique et Libertés, en date du 6 janvier
              1978, l'Utilisateur dispose d'un droit d'accès, de rectification,
              de suppression et d'opposition de ses données personnelles.
              L'Utilisateur exerce ce droit :
            </p>

            <ul>
              <li>
                <p>par mail à l'adresse email contact@talent1.fr</p>
              </li>
              <li>
                <p>via un formulaire de contact ;</p>
              </li>
              <li>
                <p>via son espace personnel ;</p>
              </li>
            </ul>
          </div>

          <div className="my-4">
            <h3>ARTICLE 4 : Propriété intellectuelle</h3>
            <p>
              Les marques, logos, signes ainsi que tous les contenus du site
              (textes, images, son…) font l'objet d'une protection par le Code
              de la propriété intellectuelle et plus particulièrement par le
              droit d'auteur. <br /> <br />
            </p>
            <p>
              La marque talent1 est une marque déposée par talent1 SASU.Toute
              représentation et/ou reproduction et/ou exploitation partielle ou
              totale de cette marque, de quelque nature que ce soit, est
              totalement prohibée. <br /> <br />
            </p>

            <p>
              L'Utilisateur doit solliciter l'autorisation préalable du site
              pour toute reproduction, publication, copie des différents
              contenus. Il s'engage à une utilisation des contenus du site dans
              un cadre strictement privé, toute utilisation à des fins
              commerciales et publicitaires est strictement interdite. Toute
              représentation totale ou partielle de ce site par quelque procédé
              que ce soit, sans l’autorisation expresse de l’exploitant du site
              Internet constituerait une contrefaçon sanctionnée par l’article L
              335-2 et suivants du Code de la propriété intellectuelle. <br />{" "}
              <br />
            </p>

            <p>
              Il est rappelé conformément à l’article L122-5 du Code de
              propriété intellectuelle que l’Utilisateur qui reproduit, copie ou
              publie le contenu protégé doit citer l’auteur et sa source. <br />{" "}
              <br />
            </p>
          </div>

          <div className="my-4">
            <h3>ARTICLE 5 : Responsabilité</h3>
            <p>
              Les sources des informations diffusées sur le site talent1.io sont
              réputées fiables mais le site ne garantit pas qu’il soit exempt de
              défauts, d’erreurs ou d’omissions. <br /> <br />
            </p>
            <p>
              Les informations communiquées sont présentées à titre indicatif et
              général sans valeur contractuelle. Malgré des mises à jour
              régulières, le site talent1.io ne peut être tenu responsable de la
              modification des dispositions administratives et juridiques
              survenant après la publication. De même, le site ne peut être
              tenue responsable de l’utilisation et de l’interprétation de
              l’information contenue dans ce site. <br /> <br />
            </p>

            <p>
              L'Utilisateur s'assure de garder son mot de passe secret. Toute
              divulgation du mot de passe, quelle que soit sa forme, est
              interdite. Il assume les risques liés à l'utilisation de son
              identifiant et mot de passe. Le site décline toute responsabilité.{" "}
              <br /> <br />
            </p>

            <p>
              Le site talent1.io ne peut être tenu pour responsable d’éventuels
              virus qui pourraient infecter l’ordinateur ou tout matériel
              informatique de l’Internaute, suite à une utilisation, à l’accès,
              ou au téléchargement provenant de ce site. <br /> <br />
            </p>

            <p>
              La responsabilité du site ne peut être engagée en cas de force
              majeure ou du fait imprévisible et insurmontable d'un tiers.
            </p>
          </div>

          <div className="my-4">
            <h3>ARTICLE 6 : Liens hypertextes</h3>
            <p>
              Des liens hypertextes peuvent être présents sur le site.
              L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira
              du site talent1.io. Ce dernier n’a pas de contrôle sur les pages
              web sur lesquelles aboutissent ces liens et ne saurait, en aucun
              cas, être responsable de leur contenu.
            </p>
          </div>

          <div className="my-4">
            <h3>ARTICLE 7 : Cookies</h3>
            <p>
              L’Utilisateur est informé que lors de ses visites sur le site, un
              cookie peut s’installer automatiquement sur son logiciel de
              navigation. <br /> <br />
            </p>
            <p>
              Les cookies sont de petits fichiers stockés temporairement sur le
              disque dur de l’ordinateur de l’Utilisateur par votre navigateur
              et qui sont nécessaires à l’utilisation du site talent1.io Les
              cookies ne contiennent pas d’information personnelle et ne peuvent
              pas être utilisés pour identifier quelqu’un. Un cookie contient un
              identifiant unique, généré aléatoirement et donc anonyme. Certains
              cookies expirent à la fin de la visite de l’Utilisateur, d’autres
              restent. <br /> <br />
            </p>
            <p>
              L’information contenue dans les cookies est utilisée pour
              améliorer le site talent1.io. <br /> <br />
            </p>
            <p>
              En naviguant sur le site, L’Utilisateur les accepte. <br /> <br />
            </p>

            <p>
              L’Utilisateur doit toutefois donner son consentement quant à
              l’utilisation de certains cookies. A défaut d’acceptation,
              l’Utilisateur est informé que certaines fonctionnalités ou pages
              risquent de lui être refusées. <br /> <br />
            </p>
            <p>
              L’Utilisateur pourra désactiver ces cookies par l’intermédiaire
              des paramètres figurant au sein de son logiciel de navigation.
            </p>
          </div>

          <div className="my-4">
            <h3>ARTICLE 8 : Droit applicable et juridiction compétente</h3>
            <p>
              La législation française s'applique au présent contrat. En cas
              d'absence de résolution amiable d'un litige né entre les parties,
              les tribunaux français seront seuls compétents pour en connaître.{" "}
              <br /> <br />
            </p>
            <p>
              Pour toute question relative à l’application des présentes CGU,
              vous pouvez joindre l’éditeur aux coordonnées inscrites à
              l’ARTICLE 1.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default CGUScreen;
