import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export async function uploadFileAsync(file, path) {
  // We're done with the blob, close and release it
  const uploadFile = (uploadedFile, uploadedPath) => {
    return new Promise((resolve, reject) => {
      if (!file) reject();

      const fileRef = ref(getStorage(), uploadedPath);
      const uploadTask = uploadBytesResumable(fileRef, uploadedFile);

      uploadTask.on(
        "state_changed",
        () => {},
        (err) => reject(err),
        () => resolve(getDownloadURL(uploadTask.snapshot.ref)),
      );
    });
  };

  const allPromises = [];

  allPromises.push(uploadFile(file, path));

  const uploadedUrls = await Promise.all(allPromises);
  console.log(uploadedUrls);
  return uploadedUrls[0];
}
