import React from "react";
import Box from '@mui/material/Box';
import { DataGrid, frFR } from "@mui/x-data-grid";

function CustomDataGrid({ data, columns, height }) {
  return (
    <Box style={{ height: height, width: "100%",  "& .super-app-theme": {
      bgcolor: () => "green",
    }, }} className="bg-white"  sx={{
      "& .super-app-theme": {
        bgcolor: () => "green",
      },
    }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            pagination
            rows={data}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            pageSize={50}
            getRowHeight={() => "auto"}
            getRowId={(row) => (row._id ? row._id : row.product)}
            rowsPerPageOptions={[50]}
            disableSelectionOnClick
            getRowClassName={(params) => {
              if (params.row?.email === "erwan.bagdatli@talent1.fr") {
                return "super-app-theme";
              }
            }}
            experimentalFeatures={{ newEditingApi: true }}
            components={{
              NoResultsOverlay: () => <h3>Aucune entrée</h3>,
            }}
          />
        </div>
      </div>
    </Box>
  );
}

export default CustomDataGrid;
