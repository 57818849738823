import React, { useState } from "react";
import iconUpload from "../assets/upload_icon.svg";
import { uploadFileAsync } from "../utils/upload";
import CustomLoader from "./CustomLoader";

function CustomUpload({ file, setFile, text, className, folder }) {
  const hiddenFileInput = React.useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = async (event) => {
    setIsLoading(true);
    const fileUploaded = event.target.files[0];
    const downloadUrl = await uploadFileAsync(
      fileUploaded,
      `${folder}/${fileUploaded.name}`,
    );
    setSelectedFileName(fileUploaded.name);
    console.log(downloadUrl);
    setFile(downloadUrl);
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div>
          <div
            className={`${className} d-flex rounded p-3`}
            onClick={handleClick}
            style={{ border: "1px solid lightgrey", cursor: "pointer" }}
          >
            <img src={iconUpload} alt="" />
            <p className="mx-3">{selectedFileName || text}</p>
          </div>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          />
        </div>
      )}
      {file && (
        <div className="d-flex">
          <a href={file}>Voir le fichier</a>
          <p onClick={() => setFile("")} className="mx-3 cursor-pointer">
            Supprimer
          </p>
        </div>
      )}
    </div>
  );
}

export default CustomUpload;
