import React, { useState } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import logoConnexion from "../../assets/logo_connexion.svg";
import imgConnexion from "../../assets/connexionImg.svg";
import Button from "../../components/Button";
import { apiUrl } from "../../constants/apiUrl";
import CustomInput from "../../components/CustomInput";

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;
  const navigate = useNavigate();
  const handleClose = () => {
    onClose(selectedValue);
  };

  const styles = {
    container: {
      maxWidth: "400px",
    },
    alignCenter: {
      textAlign: "center",
    },
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={styles.container}>
        <h4 style={styles.alignCenter}>Mot de passe mis à jour</h4>
        <p style={styles.alignCenter}>
          Votre mot de passe a bien été mis à jour, vous pouvez désormais vous
          connecter avec !
        </p>
        <Button
          text="Retour à la page de connexion"
          style={{ marginTop: "5%", borderRadius: "5px" }}
          onClick={() => navigate("/login")}
        />
      </div>
    </Dialog>
  );
}

function ResetPasswordScreen() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const initialValues = {
    newPassword: "",
    confirmNewPassword: "",
  };

  const changePasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&)(#_+=/§])[A-Za-z\d@$!%*?&-)(#_+=/§]{10,}$/,
        "Votre mot de passe doit comporter au minimum dix caractères incluant obligatoirement une lettre majuscule, un chiffre et un caractère spécial (@$!%*?&)(#_+=/§).",
      )
      .required("Ce champ est requis"),
    confirmNewPassword: Yup.string()
      .required("Ce champ est requis")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Les mots de passe ne sont pas identiques",
      ),
  });

  const handleForm = async (values) => {
    try {
      await axios.post(`${apiUrl}/api/users/password`, {
        email: params.email,
        password: values.newPassword,
      });
    } catch (err) {
      console.log(err);
    } finally {
      handleClickOpen();
    }
  };

  return (
    <div className="connexion-container">
      <div className="left-container">
        <div className="form-connexion-container">
          <img src={logoConnexion} alt="" className="connexion-logo" />
          <h1>Réinitialiser le mot de passe</h1>
          <p>
            Pour modifier votre mot de passe, saisissez l’adresse email avec
            laquelle vous avez créé votre compte Talent1.{" "}
          </p>
          <div className="flex-row d-flex signup-container" />

          <Formik
            initialValues={initialValues}
            validationSchema={changePasswordSchema}
            onSubmit={(values) => {
              handleForm(values);
            }}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
              <div>
                <div className="text-field-container">
                  <CustomInput
                    id="outlined-helperText"
                    label="Nouveau mot de passe"
                    color="secondary"
                    focused
                    type="password"
                    secureTextEntry
                    value={values.newPassword}
                    className="text-field"
                    onChange={handleChange("newPassword")}
                  />
                </div>
                {errors.newPassword && (
                  <div className="text-danger mb-3">{errors.newPassword}</div>
                )}
                <div className="text-field-container">
                  <CustomInput
                    id="outlined-helperText"
                    label="Confirmer le mot de passe"
                    color="secondary"
                    focused
                    type="password"
                    value={values.confirmNewPassword}
                    secureTextEntry
                    className="text-field"
                    onChange={handleChange("confirmNewPassword")}
                  />
                </div>

                {errors.confirmNewPassword && (
                  <div className="text-danger mb-3">
                    {errors.confirmNewPassword}
                  </div>
                )}
                {error && <p className="error">{error}</p>}
                <Button
                  text="Créer votre nouveau mot de passe"
                  style={{ marginTop: "15%" }}
                  onClick={handleSubmit}
                />
              </div>
            )}
          </Formik>
        </div>
      </div>
      <SimpleDialog open={open} onClose={handleClose} />

      <div className="img-connexion-container">
        <div className="img-connexion-inner-container">
          <img src={imgConnexion} alt="" />
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordScreen;
