import React from "react";

import TextField from "@mui/material/TextField";
import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#6200EE",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#6200EE",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "grey",
      },
      "&:hover fieldset": {
        borderColor: "grey",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6200EE",
      },
    },
  },
})(TextField);

// eslint-disable-next-line no-unused-vars
function CustomInput({
  defaultValue,
  label,
  value,
  onChange,
  className,
  type,
  multiline,
  rows,
  required,
  disabled,
  ...rest
}) {
  return (
    <CssTextField
      className={className}
      label={label}
      multiline={multiline}
      rows={rows}
      type={type}
      required={required}
      variant="outlined"
      id="custom-css-outlined-input"
      defaultValue={defaultValue}
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
  );
}

export default CustomInput;
