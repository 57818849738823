import React from "react";
import styles from "./Button.module.css";
import shoppingCart from "../assets/Line.png";

// eslint-disable-next-line no-unused-vars
function Button({ text, style, onClick, className, cart, type, ...rest }) {
  return (
    <div
      className={`${styles.buttonContainer} ${className} rounded p-3 cursor-pointer`}
      style={style}
      type={type}
      onClick={onClick}
      onKeyDown={onClick}
      role="none"
    >
      {cart && <img src={shoppingCart} alt="shoppping-cart" />}
      <p className={styles.buttonText}>{text}</p>
    </div>
  );
}

export default Button;
