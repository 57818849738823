import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { Link } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import checkIcon from "../assets/check_icon.svg";

const ExpertisesItCard = ({ formation, lastCard }) => {
  console.log(formation)
  return (
    <div className="card-expertise-container">
        <img src={formation.image} alt="" className="card-it-img" />
    
      <h3 className="mb-3 text-center">{formation.titre}</h3>

      <div className="check-container">
        {lastCard ?   <div className="d-flex mt-3 mb-2">
          <img
            src={checkIcon}
            className="forma-icon"
           
          />
          <p>{formation.point1}</p>
        </div> :   <div className="d-flex my-2">
          <img
            src={checkIcon}
            className="forma-icon"
           
          />
          <p>{formation.point1}</p>
        </div>}
        <div className="d-flex my-2">
          <img
            src={checkIcon}
            className="forma-icon"
           
          />
          <p>{formation.point2}</p>
        </div>
        <div className="d-flex my-2">
        <img
            src={checkIcon}
            className="forma-icon"
           
          />
          <p>{formation.point3}</p>
        </div>
        <div className="d-flex my-2">
        <img
            src={checkIcon}
            className="forma-icon"
           
          />
          <p>{formation.point4}</p>
        </div>
        <div className="d-flex my-2">
        <img
            src={checkIcon}
            className="forma-icon"
           
          />
          <p>{formation.point5}</p>
        </div>
        {formation.point6 &&       <div className="d-flex my-2">
        <img
            src={checkIcon}
            className="forma-icon"
           
          />
          <p>{formation?.point6}</p>
        </div>}
  
        {formation.point7 &&    <div className="d-flex my-2">
        <img
            src={checkIcon}
            className="forma-icon"
           
          />
          <p>{formation?.point7}</p>
        </div>}
        {formation.point8 &&    <div className="d-flex my-2">
        <img
            src={checkIcon}
            className="forma-icon"
           
          />
          <p>{formation?.point8}</p>
        </div>}
        {formation.point9 &&    <div className="d-flex my-2">
        <img
            src={checkIcon}
            className="forma-icon"
           
          />
          <p>{formation?.point9}</p>
        </div>}
      
      </div>

      <div className="d-flex flex-row go-detail go-detail-services-card">
        <a href="/contact">
          <p className="formation-detail">Un projet ? Echangeons</p>
        </a>
        <FontAwesomeIcon
          icon={faArrowRight}
          color="#6633CC"
          className="arrow-icon"
        />
      </div>
    </div>
  );
};

export default ExpertisesItCard;
