import React, { useEffect, useState, useContext } from "react";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import MesFormations from "../../components/profil/MesFormations";
import MesFactures from "../../components/profil/MesFactures";
import AuthContext from "../../context/currentUserContext";
import { apiUrl } from "../../constants/apiUrl";
import MesInformations from "../../components/profil/MesInformations";
import Button from "../../components/Button";

function ProfileScreen() {
  const [formation, setFormation] = useState(true);
  const [factures, setFactures] = useState(false);
  const [detail, setDetail] = useState(false);
  const [orders, setOrders] = useState([]);

  const { currentUser, setCurrentUser } = useContext(AuthContext);

  useEffect(() => {
    const getUserOrders = async () => {
      const { data } = await axios.post(`${apiUrl}/api/users/profile`, {
        _id: currentUser._id,
      });
      console.log(data);
      setOrders(data.formations);
    };
    getUserOrders();
  }, [currentUser]);

  console.log(currentUser);
  return (
    <div>
      <NavBar />
      <div className="profile-main">
        <h1 className="profile-title">Mon profil</h1>
        <div className="header-profile mb-5">
          <p
            className={
              formation
                ? "header-profile-purple card-mobile-text"
                : "header-profile-title card-mobile-text"
            }
            onClick={() => {
              setFormation(true);
              setFactures(false);
              setDetail(false);
            }}
          >
            Mes formations
          </p>
          <p
            className={
              factures
                ? "header-profile-purple card-mobile-text"
                : "header-profile-title card-mobile-text"
            }
            onClick={() => {
              setFormation(false);
              setFactures(true);
              setDetail(false);
            }}
          >
            Factures
          </p>
          <p
            className={
              detail
                ? "header-profile-purple card-mobile-text"
                : "header-profile-title card-mobile-text"
            }
            onClick={() => {
              setFormation(false);
              setFactures(false);
              setDetail(true);
            }}
          >
            Détails du compte
          </p>
        </div>

        {formation && orders && (
          <div>
            <div className="cart-desktop">
              <MesFormations orders={orders} />
            </div>

            <div className="cart-mobile">
              {orders.length ? (
                <>
                  {orders.map((order, index) => {
                    return (
                      <div
                        className="cart-mobile-card bg-white rounded p-3 "
                        key={index}
                      >
                        <div className="d-flex justify-content-between">
                          <p className="font-weight-bold card-mobile-text">
                            Formation
                          </p>
                          <p className="font-weight-bold card-mobile-text">
                            {order.formationTitle}
                          </p>
                        </div>
                        <hr className="w-100" />
                        <div className="d-flex justify-content-between">
                          <p className="font-weight-bold card-mobile-text">
                            Date
                          </p>
                          <p className="card-mobile-text ">
                            Du {order.startDate} au {order.endDate}
                          </p>
                        </div>
                        <hr className="w-100" />
                        <div className="d-flex justify-content-between">
                          <p className="font-weight-bold card-mobile-text">
                            Quizz
                          </p>
                          {order.quizz ? (
                            <a
                              href={order.quizz}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button text="Voir le quizz" className="p-2" />
                            </a>
                          ) : (
                            <p className="card-mobile-text">
                              Le quizz n'est pas encore disponible
                            </p>
                          )}
                        </div>
                        <hr className="w-100" />
                        <div className="d-flex justify-content-between">
                          <p className="font-weight-bold card-mobile-text">
                            Certificat
                          </p>
                          {order.certificat ? (
                            <a
                              href={order.certificat}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button
                                text="Voir le certificat"
                                className="p-2"
                              />
                            </a>
                          ) : (
                            <p className="card-mobile-text">
                              Le certificat n'est pas encore disponible
                            </p>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p>Vous n'avez acheté encore aucune formation</p>
              )}
            </div>
          </div>
        )}
        {factures && (
          <div>
            <div className="cart-desktop">
              <MesFactures factures={orders} />
            </div>

            <div className="cart-mobile">
              {orders.length !== 0 ? (
                <>
                  {orders.map((order, index) => {
                    return (
                      <div
                        className="cart-mobile-card bg-white rounded p-3 "
                        key={index}
                      >
                        <div className="d-flex justify-content-between">
                          <p className="font-weight-bold card-mobile-text">
                            Formation
                          </p>
                          <p className="font-weight-bold card-mobile-text">
                            {order.formationTitle}
                          </p>
                        </div>
                        <hr className="w-100" />
                        <div className="d-flex justify-content-between">
                          <p className="font-weight-bold card-mobile-text">
                            Date d'achat
                          </p>
                          <p className="card-mobile-text ">
                            {order.boughtDate}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="font-weight-bold card-mobile-text">
                            Prix HT
                          </p>
                          <p className="card-mobile-text ">{order.prixHT}</p>
                        </div>
                        <hr className="w-100" />
                        <div className="d-flex justify-content-between">
                          <p className="font-weight-bold card-mobile-text">
                            Facture
                          </p>
                          {order.factureUrl ? (
                            <a
                              href={order.factureUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Button text="Voir la facture" className="p-2" />
                            </a>
                          ) : (
                            <p className="card-mobile-text">
                              La facture n'est pas disponible
                            </p>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p>Vous n'avez aucune facture</p>
              )}
            </div>
          </div>
        )}
        {detail && currentUser && (
          <MesInformations
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
          />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default ProfileScreen;
