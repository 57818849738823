import React, { useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { DataGrid } from "@mui/x-data-grid";
import DialogTitle from '@mui/material/DialogTitle';
import SideBar from "../../components/SideBar";
import FormationListCard from "../../components/admin/FormationListCard";
import Button from "../../components/Button";
import HeaderAdmin from "../../components/admin/HeaderAdmin";
import WhiteButton from "../../components/WhiteButton";
import { apiUrl } from "../../constants/apiUrl";
import CustomUpload from "../../components/CustomUpload";
import CustomImageUpload from "../../components/CustomImageUpload";
import CustomInput from "../../components/CustomInput";
import CustomLoader from "../../components/CustomLoader";
import CustomDataGrid from "../../components/CustomDataGrid";
import AuthContext from "../../context/currentUserContext";
import { useNavigate } from "react-router-dom";

function AddFormationScreen() {
  const [open, setOpen] = React.useState(false);
  const [openModify, setOpenModify] = useState(false);
  const [formation, setFormation] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const navigate = useNavigate()
  useEffect(() => {
    const fetchCurrentUser = async () => {
   
      if (localStorage.getItem("userInfoTalent1") === null) {
        navigate('/')
      }
      userId = localStorage.getItem("userInfoTalent1");
        const { data } = await axios.post(`${apiUrl}/api/users/profile`, {
          _id: userId,
        });
        console.log("data", data)
        if (data == null|| data.email != "erwan.bagdatli@talent1.fr" || data.email != "hadrien.jaubert99@gmail.com")
        {
               navigate('/')
        }
      }
    fetchCurrentUser();
  }, []);
  const {currentUser} = useContext(AuthContext)
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModify = () => {
    setOpenModify(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const [iconUrl, setIconUrl] = useState("");
    const [programmeUrl, setProgrammeUrl] = useState("");
    const [imageUrl, setImageUrl] = useState("");

    const initialValues = {
      encadre: "",
      thematique: "",
      codeHexaEncadre: "",
      codeHexaThematique: "",
      titre: "",
      description: "",
      prixHT: "",
      prixTTC: "",
      certification: "",
      publique: "",
      startDate: "",
      endDate: "",
      duration: "",
      format: "",
      point1: "",
      point2: "",
      point3: "",
      point4: "",
      point5: "",
      point6: "",
      point7: "",
      point8: "",
      point9: "",
      point10: "",
      title1: "",
      title2: "",
      title3: "",
      title4: "",
      description1: "",
      description2: "",
      description3: "",
      description4: "",
      rank: 0,
    };

    const addFormationSchema = Yup.object().shape({
      codeHexaEncadre: Yup.string().matches(/#/, "Rajouter un # devant"),
      codeHexaThematique: Yup.string().matches(/#/, "Rajouter un # devant"),
      encadre: Yup.string(),
      thematique: Yup.string(),
    });

    const [formationSubmitted, setFormationSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleForm = async (values) => {
      setIsLoading(false);
      await axios.post(`${apiUrl}/api/formations`, {
        encadre: values.encadre,
        thematique: values.thematique,
        codeHexaEncadre: values.codeHexaEncadre,
        codeHexaThematique: values.codeHexaThematique,
        titre: values.titre,
        description: values.description,
        prixHT: values.prixHT,
        prixTTC: (Number(values.prixHT) * 1.2).toFixed(0).toString(),
        certification: values.certification,
        publique: values.publique,
        startDate: values.startDate,
        endDate: values.endDate,
        duration: values.duration,
        format: values.format,
        codeHexaBulletPoint: values.codeHexaBulletPoint,
        point1: values.point1,
        point2: values.point2,
        point3: values.point3,
        point4: values.point4,
        point5: values.point5,
        point6: values.point6,
        point7: values.point7,
        point8: values.point8,
        point9: values.point9,
        point10: values.point10,
        title1: values.title1,
        title2: values.title2,
        title3: values.title3,
        title4: values.title4,
        description1: values.description1,
        description2: values.description2,
        description3: values.description3,
        description4: values.description4,
        rank: values.rank,
        image: imageUrl,
        programme: programmeUrl,
        icon: iconUrl,
      });
      setIsLoading(false);
      setFormationSubmitted(true);
      setTimeout(async () => {
        setOpen(false);
        window.location.reload();
      }, 1500);
    };


    return (
      <Dialog
        onClose={handleClose}
        open={open}
        className="add-formation-dialog"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={addFormationSchema}
          onSubmit={(values) => {
            handleForm(values);
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <div>
              <div className="d-flex">
                <div className="d-flex justify-content-between">
                  <CustomImageUpload file={imageUrl} setFile={setImageUrl} />
                </div>
                <div className="add-formation-icon-container">
                  <CustomUpload
                    text="Ajoutez une icône (synthèse)"
                    file={iconUrl}
                    folder="icon"
                    className="w-50"
                    setFile={setIconUrl}
                  />
                  <div className="d-flex justify-content-between mt-5">
                    <div className="d-flex flex-column w-100">
                      <CustomInput
                        id="outlined-basic"
                        label="Encadré de la formation"
                        variant="outlined"
                        value={values.encadre}
                        className="text-field-add-formation code-hexa"
                        onChange={handleChange("encadre")}
                      />

                      {errors.encadre && (
                        <div className="text-danger">
                          {errors.codeHexaEncadre}
                        </div>
                      )}
                    </div>

                    <div className="d-flex flex-column w-100">
                      <CustomInput
                        id="outlined-basic"
                        label="Thématique"
                        variant="outlined"
                        required
                        value={values.thematique}
                        className="text-field-add-formation code-hexa"
                        onChange={handleChange("thematique")}
                      />
                      {errors.thematique && (
                        <div className="text-danger">
                          {errors.codeHexaEncadre}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mt-4">
                    <div className="d-flex flex-column w-100">
                      <CustomInput
                        id="outlined-basic"
                        label="Code hexa de l’encadré (ex : #FFFFFF)"
                        variant="outlined"
                        value={values.codeHexaEncadre}
                        className="text-field-add-formation code-hexa"
                        onChange={handleChange("codeHexaEncadre")}
                      />

                      {errors.codeHexaEncadre && (
                        <div className="text-danger">
                          {errors.codeHexaEncadre}
                        </div>
                      )}
                    </div>

                    <div className="d-flex flex-column w-100">
                      <CustomInput
                        id="outlined-basic"
                        label="Code hexa de la thématique (ex : #FFFFFF)"
                        variant="outlined"
                        required
                        value={values.codeHexaThematique}
                        className="text-field-add-formation code-hexa"
                        onChange={handleChange("codeHexaThematique")}
                      />
                      {errors.codeHexaThematique &&
                        touched.codeHexaThematique && (
                          <div className="text-danger">
                            {errors.codeHexaThematique}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Titre"
                  variant="outlined"
                  value={values.titre}
                  className="title-add-formation w-100"
                  onChange={handleChange("titre")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  value={values.description}
                  className="title-add-formation w-100"
                  onChange={handleChange("description")}
                />
              </div>

              <h3>Prix</h3>
              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Prix HT (€)"
                  variant="outlined"
                  value={values.prixHT}
                  className="text-field-add-formation"
                  onChange={handleChange("prixHT")}
                />

                <CustomInput
                  id="outlined-basic"
                  label="Prix TTC (€)"
                  variant="outlined"
                  disabled={true}
                  value={(Number(values.prixHT) * 1.2).toFixed(0).toString()}
                  className="text-field-add-formation"
                />
              </div>

              <h3>Caractéristiques</h3>
              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Public"
                  variant="outlined"
                  value={values.publique}
                  className="text-field-add-formation"
                  onChange={handleChange("publique")}
                />

                <CustomInput
                  id="outlined-basic"
                  label="Certification"
                  variant="outlined"
                  value={values.certification}
                  className="text-field-add-formation"
                  onChange={handleChange("certification")}
                />
              </div>

              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Date de début "
                  variant="outlined"
                  value={values.startDate}
                  className="text-field-add-duree"
                  onChange={handleChange("startDate")}
                />

                <CustomInput
                  id="outlined-basic"
                  label="Date de fin"
                  variant="outlined"
                  value={values.endDate}
                  className="text-field-add-duree"
                  onChange={handleChange("endDate")}
                />

                <CustomInput
                  id="outlined-basic"
                  label="Durée"
                  variant="outlined"
                  value={values.duration}
                  className="text-field-add-duree"
                  onChange={handleChange("duration")}
                />
              </div>

              <CustomInput
                id="outlined-basic"
                label="Format pédagogique"
                variant="outlined"
                value={values.format}
                className="text-field-add-formation"
                onChange={handleChange("format")}
              />

              <h3>Que contient la formation ?</h3>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 1"
                  variant="outlined"
                  value={values.point1}
                  className="title-add-formation w-100"
                  onChange={handleChange("point1")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 2"
                  variant="outlined"
                  value={values.point2}
                  className="title-add-formation w-100"
                  onChange={handleChange("point2")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 3"
                  variant="outlined"
                  value={values.point3}
                  className="title-add-formation w-100"
                  onChange={handleChange("point3")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 4"
                  variant="outlined"
                  value={values.point4}
                  className="title-add-formation w-100"
                  onChange={handleChange("point4")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 5"
                  value={values.point5}
                  variant="outlined"
                  className="title-add-formation w-100"
                  onChange={handleChange("point5")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 6"
                  value={values.point6}
                  variant="outlined"
                  className="title-add-formation w-100"
                  onChange={handleChange("point6")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 7"
                  variant="outlined"
                  value={values.point7}
                  className="title-add-formation w-100"
                  onChange={handleChange("point7")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 8"
                  value={values.point8}
                  variant="outlined"
                  className="title-add-formation w-100"
                  onChange={handleChange("point8")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 9"
                  value={values.point9}
                  variant="outlined"
                  className="title-add-formation w-100"
                  onChange={handleChange("point9")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 10"
                  value={values.point10}
                  variant="outlined"
                  className="title-add-formation w-100"
                  onChange={handleChange("point10")}
                />
              </div>

              <h3>Compétences visées</h3>
              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Titre 1"
                  variant="outlined"
                  value={values.title1}
                  className="text-field-add-formation"
                  onChange={handleChange("title1")}
                />

                <CustomInput
                  id="outlined-basic"
                  label="Titre 2"
                  value={values.title2}
                  variant="outlined"
                  className="text-field-add-formation"
                  onChange={handleChange("title2")}
                />
              </div>
              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Description 1"
                  variant="outlined"
                  value={values.description1}
                  className="text-field-add-formation"
                  onChange={handleChange("description1")}
                />
                <CustomInput
                  id="outlined-basic"
                  label="Description 2"
                  variant="outlined"
                  value={values.description2}
                  className="text-field-add-formation"
                  onChange={handleChange("description2")}
                />
              </div>
              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Titre 3"
                  value={values.title3}
                  variant="outlined"
                  className="text-field-add-formation"
                  onChange={handleChange("title3")}
                />

                <CustomInput
                  id="outlined-basic"
                  label="Titre 4"
                  value={values.title4}
                  variant="outlined"
                  className="text-field-add-formation"
                  onChange={handleChange("title4")}
                />
              </div>
              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Description 3"
                  value={values.description3}
                  variant="outlined"
                  className="text-field-add-formation"
                  onChange={handleChange("description3")}
                />
                <CustomInput
                  id="outlined-basic"
                  label="Description 4"
                  value={values.description4}
                  variant="outlined"
                  className="text-field-add-formation"
                  onChange={handleChange("description4")}
                />
              </div>

              <h3>Autre</h3>
              <div className="w-50 d-flex">
                <CustomUpload
                  text="Ajouter le programme"
                  file={programmeUrl}
                  folder="programmes"
                  className="mb-5"
                  setFile={setProgrammeUrl}
                />
              </div>

              <CustomInput
                id="outlined-basic"
                label="Rang"
                value={values.rank}
                variant="outlined"
                className="text-field-add-formation"
                onChange={handleChange("rank")}
              />

              <div className="d-flex justify-content-end">
                <div className="d-flex align-self-end">
                  <WhiteButton
                    text="Annuler"
                    className="mx-3"
                    onClick={handleClose}
                    style={{ paddingLeft: "4%", paddingRight: "4%" }}
                  />
                  {isLoading ? (
                    <CustomLoader />
                  ) : (
                    <Button
                      type="submit"
                      text="Ajouter la formation"
                      onClick={handleSubmit}
                    />
                  )}
                </div>
              </div>

              {formationSubmitted && (
                <div className="alert alert-success mt-3" role="alert">
                  Votre formation a bien été soumise !
                </div>
              )}
            </div>
          )}
        </Formik>
      </Dialog>
    );
  }

  function SimpleDialogModify(props) {
    const { onClose, selectedValue, open, formation } = props;
    const [iconUrl, setIconUrl] = useState(formation?.icon);
    const [programmeUrl, setProgrammeUrl] = useState(formation?.programme);
    const [imageUrl, setImageUrl] = useState(formation?.image);

    const initialValues = {
      encadre: formation?.encadre,
      thematique: formation?.thematique,
      codeHexaEncadre: formation?.codeHexaEncadre,
      codeHexaThematique: formation?.codeHexaThematique,
      titre: formation?.titre,
      description: formation?.description,
      prixHT: formation?.prixHT,
      prixTTC: formation?.prixTTC,
      certification: formation?.certification,
      publique: formation?.publique,
      startDate: formation?.startDate,
      endDate: formation?.endDate,
      duration: formation?.duration,
      format: formation?.format,
      point1: formation?.point1,
      point2: formation?.point2,
      point3: formation?.point3,
      point4: formation?.point4,
      point5: formation?.point5,
      point6: formation?.point6,
      point7: formation?.point7,
      point8: formation?.point8,
      point9: formation?.point9,
      point10: formation?.point10,
      title1: formation?.title1,
      title2: formation?.title2,
      title3: formation?.title3,
      title4: formation?.title4,
      description1: formation?.description1,
      description2: formation?.description2,
      description3: formation?.description3,
      description4: formation?.description4,
      rank: formation?.rank,
    };

    const addFormationSchema = Yup.object().shape({
      codeHexaEncadre: Yup.string().matches(/#/, "Rajouter un # devant"),
      codeHexaThematique: Yup.string().matches(/#/, "Rajouter un # devant"),
    });

    const [formationSubmitted, setFormationSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleForm = async (values) => {
      setIsLoading(false);
      await axios.put(`${apiUrl}/api/formations`, {
        _id: formation._id,
        encadre: values.encadre,
        thematique: values.thematique,
        codeHexaEncadre: values.codeHexaEncadre,
        codeHexaThematique: values.codeHexaThematique,
        titre: values.titre,
        description: values.description,
        prixHT: values.prixHT,
        prixTTC: (Number(values.prixHT) * 1.2).toFixed(0).toString(),
        certification: values.certification,
        publique: values.publique,
        startDate: values.startDate,
        endDate: values.endDate,
        duration: values.duration,
        format: values.format,
        codeHexaBulletPoint: values.codeHexaBulletPoint,
        point1: values.point1,
        point2: values.point2,
        point3: values.point3,
        point4: values.point4,
        point5: values.point5,
        point6: values.point6,
        point7: values.point7,
        point8: values.point8,
        point9: values.point9,
        point10: values.point10,
        title1: values.title1,
        title2: values.title2,
        title3: values.title3,
        title4: values.title4,
        description1: values.description1,
        description2: values.description2,
        description3: values.description3,
        description4: values.description4,
        rank: values.rank,
        image: imageUrl,
        programme: programmeUrl,
        icon: iconUrl,
      });
      await axios.put(`${apiUrl}/api/users/formations`, {
        startDate: values.startDate,
        endDate: values.endDate,
        formationTitle: values.titre,
      });
      setIsLoading(false);
      setFormationSubmitted(true);
      setTimeout(async () => {
        setOpen(false);
        window.location.reload();
      }, 1500);
    };

    return (
      <Dialog
        onClose={handleCloseModify}
        open={open}
        className="add-formation-dialog"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={addFormationSchema}
          onSubmit={(values) => {
            handleForm(values);
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <div>
              <div className="d-flex">
                <div className="d-flex justify-content-between">
                  <CustomImageUpload file={imageUrl} setFile={setImageUrl} formation={formation}/>
                </div>
                <div className="add-formation-icon-container">
                  <CustomUpload
                    text="Ajoutez une icône (synthèse)"
                    file={iconUrl}
                    folder="icon"
                    className="w-50"
                    setFile={setIconUrl}
                  />
                  <div className="d-flex justify-content-between mt-5">
                    <CustomInput
                      id="outlined-basic"
                      label="Encadré de la formation"
                      variant="outlined"
                      value={values.encadre}
                      className="text-field-add-formation"
                      onChange={handleChange("encadre")}
                    />

                    <CustomInput
                      id="outlined-basic"
                      label="Thématique"
                      variant="outlined"
                      required
                      value={values.thematique}
                      className="text-field-add-formation"
                      onChange={handleChange("thematique")}
                    />
                  </div>
                  <div className="d-flex justify-content-between mt-4">
                    <div className="d-flex flex-column w-100">
                      <CustomInput
                        id="outlined-basic"
                        label="Code hexa de l’encadré (ex : #FFFFFF)"
                        variant="outlined"
                        value={values.codeHexaEncadre}
                        className="text-field-add-formation code-hexa"
                        onChange={handleChange("codeHexaEncadre")}
                      />

                      {errors.codeHexaEncadre && (
                        <div className="text-danger">
                          {errors.codeHexaEncadre}
                        </div>
                      )}
                    </div>

                    <div className="d-flex flex-column w-100">
                      <CustomInput
                        id="outlined-basic"
                        label="Code hexa de la thématique (ex : #FFFFFF)"
                        variant="outlined"
                        required
                        value={values.codeHexaThematique}
                        className="text-field-add-formation code-hexa"
                        onChange={handleChange("codeHexaThematique")}
                      />
                      {errors.codeHexaThematique &&
                        touched.codeHexaThematique && (
                          <div className="text-danger">
                            {errors.codeHexaThematique}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Titre"
                  variant="outlined"
                  value={values.titre}
                  className="title-add-formation w-100"
                  onChange={handleChange("titre")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  value={values.description}
                  className="title-add-formation w-100"
                  onChange={handleChange("description")}
                />
              </div>

              <h3>Prix</h3>
              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Prix HT (€)"
                  variant="outlined"
                  value={values.prixHT}
                  className="text-field-add-formation"
                  onChange={handleChange("prixHT")}
                />

                <CustomInput
                  id="outlined-basic"
                  label="Prix TTC (€)"
                  variant="outlined"
                  disabled={true}
                  value={(Number(values.prixHT) * 1.2).toFixed(0).toString()}
                  className="text-field-add-formation"
                  onChange={handleChange("prixTTC")}
                />
              </div>

              <h3>Caractéristiques</h3>
              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Public"
                  variant="outlined"
                  value={values.publique}
                  className="text-field-add-formation"
                  onChange={handleChange("publique")}
                />

                <CustomInput
                  id="outlined-basic"
                  label="Certification"
                  variant="outlined"
                  value={values.certification}
                  className="text-field-add-formation"
                  onChange={handleChange("certification")}
                />
              </div>

              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Date de début "
                  variant="outlined"
                  value={values.startDate}
                  className="text-field-add-duree"
                  onChange={handleChange("startDate")}
                />

                <CustomInput
                  id="outlined-basic"
                  label="Date de fin"
                  variant="outlined"
                  value={values.endDate}
                  className="text-field-add-duree"
                  onChange={handleChange("endDate")}
                />

                <CustomInput
                  id="outlined-basic"
                  label="Durée"
                  variant="outlined"
                  value={values.duration}
                  className="text-field-add-duree"
                  onChange={handleChange("duration")}
                />
              </div>

              <CustomInput
                id="outlined-basic"
                label="Format pédagogique"
                variant="outlined"
                value={values.format}
                className="text-field-add-formation"
                onChange={handleChange("format")}
              />

              <h3>Que contient la formation ?</h3>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 1"
                  variant="outlined"
                  value={values.point1}
                  className="title-add-formation w-100"
                  onChange={handleChange("point1")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 2"
                  variant="outlined"
                  value={values.point2}
                  className="title-add-formation w-100"
                  onChange={handleChange("point2")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 3"
                  variant="outlined"
                  value={values.point3}
                  className="title-add-formation w-100"
                  onChange={handleChange("point3")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 4"
                  variant="outlined"
                  value={values.point4}
                  className="title-add-formation w-100"
                  onChange={handleChange("point4")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 5"
                  value={values.point5}
                  variant="outlined"
                  className="title-add-formation w-100"
                  onChange={handleChange("point5")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 6"
                  value={values.point6}
                  variant="outlined"
                  className="title-add-formation w-100"
                  onChange={handleChange("point6")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 7"
                  variant="outlined"
                  value={values.point7}
                  className="title-add-formation w-100"
                  onChange={handleChange("point7")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 8"
                  value={values.point8}
                  variant="outlined"
                  className="title-add-formation w-100"
                  onChange={handleChange("point8")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 9"
                  value={values.point9}
                  variant="outlined"
                  className="title-add-formation w-100"
                  onChange={handleChange("point9")}
                />
              </div>

              <div className="w-100">
                <CustomInput
                  id="outlined-basic"
                  label="Point 10"
                  value={values.point10}
                  variant="outlined"
                  className="title-add-formation w-100"
                  onChange={handleChange("point10")}
                />
              </div>

              <h3>Compétences visées</h3>
              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Titre 1"
                  variant="outlined"
                  value={values.title1}
                  className="text-field-add-formation"
                  onChange={handleChange("title1")}
                />

                <CustomInput
                  id="outlined-basic"
                  label="Titre 2"
                  value={values.title2}
                  variant="outlined"
                  className="text-field-add-formation"
                  onChange={handleChange("title2")}
                />
              </div>
              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Description 1"
                  variant="outlined"
                  value={values.description1}
                  className="text-field-add-formation"
                  onChange={handleChange("description1")}
                />
                <CustomInput
                  id="outlined-basic"
                  label="Description 2"
                  variant="outlined"
                  value={values.description2}
                  className="text-field-add-formation"
                  onChange={handleChange("description2")}
                />
              </div>
              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Titre 3"
                  value={values.title3}
                  variant="outlined"
                  className="text-field-add-formation"
                  onChange={handleChange("title3")}
                />

                <CustomInput
                  id="outlined-basic"
                  label="Titre 4"
                  value={values.title4}
                  variant="outlined"
                  className="text-field-add-formation"
                  onChange={handleChange("title4")}
                />
              </div>
              <div className="d-flex w-100 justify-content-between">
                <CustomInput
                  id="outlined-basic"
                  label="Description 3"
                  value={values.description3}
                  variant="outlined"
                  className="text-field-add-formation"
                  onChange={handleChange("description3")}
                />
                <CustomInput
                  id="outlined-basic"
                  label="Description 4"
                  value={values.description4}
                  variant="outlined"
                  className="text-field-add-formation"
                  onChange={handleChange("description4")}
                />
              </div>

              <h3>Autre</h3>
              <div className="w-50 d-flex">
                <CustomUpload
                  text="Ajouter le programme"
                  file={programmeUrl}
                  folder="programmes"
                  className=""
                  setFile={setProgrammeUrl}
                />
              </div>

              <CustomInput
                id="outlined-basic"
                label="Rang"
                value={values.rank}
                variant="outlined"
                className="text-field-add-formation mt-5"
                onChange={handleChange("rank")}
              />

              <div className="d-flex justify-content-end">
                <div className="d-flex align-self-end">
                  <WhiteButton
                    text="Annuler"
                    className="mx-3"
                    onClick={handleCloseModify}
                    style={{ paddingLeft: "4%", paddingRight: "4%" }}
                  />
                  {isLoading ? (
                    <CustomLoader />
                  ) : (
                    <Button
                      type="submit"
                      text="Modifier la formation"
                      onClick={handleSubmit}
                    />
                  )}
                </div>
              </div>

              {formationSubmitted && (
                <div className="alert alert-success mt-3" role="alert">
                  Votre formation a bien été modifiée !
                </div>
              )}
            </div>
          )}
        </Formik>
      </Dialog>
    );
  }
  const config = {
    headers: {
      accept: "application/json",
    },
  };

  function SimpleDialogDelete(props) {
    const { onClose, selectedValue, open, formation } = props;

    console.log("formation", formation)
    const deleteFormation = async () => {
      await axios.delete(`${apiUrl}/api/formations/${formation._id}`);
      window.location.reload()
    };

    
    const styles = {
      container: {
        maxWidth: "400px",
      },
      alignCenter: {
        textAlign: "center",
      },
    };
    return (
      <Dialog onClose={handleClose} open={open}>
        <div style={styles.container}>
          <h4 style={styles.alignCenter}>Suppression de votre formation</h4>
          <p style={styles.alignCenter}>
            Etes-vous sur de vouloir supprimer votre formation ?
          </p>
          <div className="d-flex float-right">
            <WhiteButton
              text="Annuler"
              className="mx-3"
              style={{ marginTop: "5%", borderRadius: "5px" }}
              onClick={handleCloseDelete}
            />
            <Button
              text="Supprimer"
              style={{ marginTop: "5%", borderRadius: "5px" }}
              onClick={deleteFormation}
            />
          </div>
        </div>
      </Dialog>
    );
  }
  const [formationList, setFormationList] = useState([]);

  useEffect(() => {
    const getFormations = async () => {
      const { data } = await axios.get(
        `${apiUrl}/api/formations/listformation`,
        config,
      );
      setFormationList(data);
    };
    getFormations();
  }, [formation]);



  const columns = [
    {
      field: "titre",
      headerName: "Titre",
      width: 200,
      flex: 3,
      align: "left",
      editable: false,
    },
    {
      field: "Supprimer",
      editable: false,
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Button
            text="Supprimer"
            textClassName="text-white"
            className="bg-danger "
            onClick={() => {
              setFormation(params.row);
                setOpenDelete(true)
            }}
          />
        );
      },
    },
    {
      field: "Modifier",
      editable: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <Button
              text="Modifier"
              onClick={() => {
          
                setFormation(params.row);
                setOpenModify(true);
              }}
            />
          </div>
        );
      },
    },
  ];


  return (
    <div className="d-flex">
      <SideBar />
      <div className="add-formation-container">
        <HeaderAdmin text="Les formations" />
        <Button
          text="Ajouter une formation"
          style={{
            width: "20%",
            paddingTop: "1%",
            paddingBottom: "1%",
            marginTop: "2%",
            marginBottom: "2%",
            marginLeft: "79%",
          }}
          onClick={handleClickOpen}
        />

        {formationList && formationList.length !== 0 ? (
            <CustomDataGrid data={formationList} columns={columns} height="80vh"/>
        ) : (
          <h5>Aucune formation n'est en ligne</h5>
        )}

        <SimpleDialog open={open} onClose={handleClose} />
        <SimpleDialogModify open={openModify} onClose={handleCloseModify} formation={formation}/>
        <SimpleDialogDelete open={openDelete} onClose={handleCloseDelete} formation={formation}/>
      </div>
    </div>
  );
}

export default AddFormationScreen;
