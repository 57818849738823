import React from "react";

function HeaderAdmin({ text }) {
  return (
    <div className="bg-white py-3">
      <p className="header-admin-text mx-2">{text}</p>
    </div>
  );
}

export default HeaderAdmin;
