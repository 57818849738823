export const formatDateForFacture = () => {
  const hours = new Date().getHours();
  const minutes = new Date().getMinutes();
  let date = `-${hours}${minutes}-`;
  date += new Date().toLocaleDateString().replaceAll("/", "-");
  const arr = date.split("-");
  arr.reverse();
  arr.pop();
  date = arr.join("-");
  return date;
};
