import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import React from "react";
import { faThLarge } from "@fortawesome/free-solid-svg-icons/faThLarge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons/faGraduationCap";
import { faMedal } from "@fortawesome/free-solid-svg-icons/faMedal";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faGift } from "@fortawesome/free-solid-svg-icons/faGift";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo_talent1_header.svg";

function SideBar() {
  function DashboardIcon() {
    return <FontAwesomeIcon icon={faThLarge} />;
  }
  function FormationIcon() {
    return <FontAwesomeIcon icon={faGraduationCap} />;
  }
  function CandidateIcon() {
    return <FontAwesomeIcon icon={faMedal} />;
  }
  function BanniereIcon() {
    return <FontAwesomeIcon icon={faCalendarAlt} />;
  }
  function PromoIcon() {
    return <FontAwesomeIcon icon={faGift} />;
  }

  const navigate = useNavigate();
  return (
    <ProSidebar>
      <div className="img-sidebar-container">
        <img
          src={logo}
          alt=""
          className="img-sidebar"
          onClick={() => navigate("/")}
        />
      </div>
      <Menu iconShape="square">
        <MenuItem icon={<DashboardIcon />}>
          Dashboard <Link to="/admin/dashboard" />
        </MenuItem>
        <MenuItem icon={<FormationIcon />}>
          Formations
          <Link to="/admin/formations" />
        </MenuItem>
        <MenuItem icon={<CandidateIcon />}>
          Les utilisateurs
          <Link to="/admin/users" />
        </MenuItem>
        <MenuItem icon={<BanniereIcon />}>
          Bannière <Link to="/admin/banniere" />
        </MenuItem>
        <MenuItem icon={<PromoIcon />}>
          Code promo
          <Link to="/admin/promocodes" />
        </MenuItem>
      </Menu>
    </ProSidebar>
  );
}

export default SideBar;
