import React, { useEffect } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { useLocation, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import detailFormation from "../assets/detailFormation.svg";
import Button from "../components/Button";
import publicImg from "../assets/ic_public.svg";
import certif from "../assets/ic_certification.svg";
import date from "../assets/ic_duree.svg";
import format from "../assets/ic_format.svg";
import contenuFormation from "../assets/contenuFormation.svg";
import DetailFormation from "../components/DetailFormation";
import Footer from "../components/Footer";
import { addToCart, clearError } from "../actions/cartActions";
import styles from "../components/Button.module.css";
import wave from "../assets/wave_formation.svg";
import { hexToRgbA } from "../utils/functions";
import checkIcon from "../assets/check_icon.svg";

function FormationDetailScreen() {
  const location = useLocation();
  const formation = location.state?.formation;
  console.log(formation);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!formation) {
      console.log('lkdjkqldj')
      navigate("/404");
    }
    dispatch(clearError());
  }, [dispatch]);
  const cart = useSelector((state) => state.cart);
  const { error } = cart;
  const addToCartHandler = async () => {
    try {
      dispatch(addToCart(formation._id, 1));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <NavBar />
      {formation && <div>
        <div className="head">
      
      <div className="header-left">
        <div
          className="thematique mb-3 gestion-projet"
          style={{
            backgroundColor: formation.thematique
              ? `${hexToRgbA(formation.codeHexaThematique)}`
              : "",
          }}
        >
          <p style={{ color: formation.codeHexaThematique }}>
            {formation.thematique}
          </p>
        </div>
        <h2>{formation.titre}</h2>
        <p className="mb-5">{formation.description}</p>
        <div className="d-flex">
          <h5 className="price-ht">{Number(formation.prixHT).toFixed(2)}€ HT</h5>
          <p className="price-ttc mt-2">{(Number(formation.prixHT) * 1.2).toFixed(2)}€ TTC</p>
        </div>
        <div className="button-formation-container">
          <Button
            text="Ajouter la formation"
            onClick={() => addToCartHandler()}
          />
          {error && <p className="text-danger">{error}</p>}
        </div>
      </div>
      <div className="header-right">
        <div className="header-right-inner-container">
          <img src={formation.image} alt="" />
        </div>
      </div>
    </div>

    <div className="details-formation">
      <DetailFormation
        title="Public"
        undertitle={formation.publique}
        img={publicImg}
      />
      <DetailFormation
        title="Certification"
        undertitle={formation.certification}
        img={certif}
      />
      <DetailFormation
        title="Date et durée"
        undertitle={`Du ${formation.startDate} au ${formation.endDate} (${formation.duration})`}
        img={date}
      />
      <DetailFormation
        title="Format pédagogique"
        undertitle={formation.format}
        img={format}
      />
    </div>

    <div className="contenu-formation">
      <div className="contenu-formation-img-container">
        <img src={contenuFormation} alt="" />
      </div>

      <div className="contenu-formation-text">
        <h2 className="mb-3">Que contient la formation ?</h2>
        {formation.point1 && (
          <div className="d-flex my-2">
            <img src={checkIcon} className="forma-icon" />
            <p>{formation.point1}</p>
          </div>
        )}
        {formation.point2 && (
          <div className="d-flex my-2">
            <img src={checkIcon} className="forma-icon" />
            <p>{formation.point2}</p>
          </div>
        )}
        {formation.point3 && (
          <div className="d-flex my-2">
            <img src={checkIcon} className="forma-icon" />
            <p>{formation.point3}</p>
          </div>
        )}
        {formation.point4 && (
          <div className="d-flex my-2">
            <img src={checkIcon} className="forma-icon" />
            <p>{formation.point4}</p>
          </div>
        )}
        {formation.point5 && (
          <div className="d-flex my-2">
            <img src={checkIcon} className="forma-icon" />
            <p>{formation.point5}</p>
          </div>
        )}
        {formation.point6 && (
          <div className="d-flex my-2">
            <img src={checkIcon} className="forma-icon" />
            <p>{formation.point6}</p>
          </div>
        )}
        {formation.point7 && (
          <div className="d-flex my-2">
            <img src={checkIcon} className="forma-icon" />
            <p>{formation.point7}</p>
          </div>
        )}
        {formation.point8 && (
          <div className="d-flex my-2">
            <img src={checkIcon} className="forma-icon" />
            <p>{formation.point8}</p>
          </div>
        )}
        {formation.point9 && (
          <div className="d-flex my-2">
            <img src={checkIcon} className="forma-icon" />
            <p>{formation.point9}</p>
          </div>
        )}
        {formation.point10 && (
          <div className="d-flex my-2">
            <img src={checkIcon} className="forma-icon" />
            <p>{formation.point10}</p>
          </div>
        )}
        <div
          className={`${styles.buttonContainer} mb-5 mt-3 download-button`}
        >
          {formation.programme ?     <a
            className="download-text"
            href={formation.programme}
            download
            target="_blank"
            rel="noreferrer"
          ><p className="download-text">Télécharger le programme</p>
          </a> : <p className="download-text">Télécharger le programme</p>}
      
            
        </div>
      </div>
    </div>

    <img src={wave} alt="" className="w-100 mt-5" />

    <div className="competences-visees">
      <h2 className="competence-title">Compétences visées</h2>
      <p className="competence-undertitle">
        Qu’allez-vous maîtriser avec cette formation ?
      </p>
      <div className="lorem-flex">
        <div className="lorem-big-container">
          <FontAwesomeIcon
            icon={faCheck}
            color="#2EE0AC"
            className="checkbox"
          />
          <div className="lorem-container">
            <h5>{formation.title1}</h5>
            <p>{formation.description1}</p>
          </div>
        </div>
        <div className="lorem-big-container">
          <FontAwesomeIcon
            icon={faCheck}
            color="#2EE0AC"
            className="checkbox"
          />
          <div className="lorem-container">
            <h5>{formation.title2}</h5>
            <p>{formation.description2}</p>
          </div>
        </div>
      </div>

      <div className="lorem-flex">
        <div className="lorem-big-container">
          <FontAwesomeIcon
            icon={faCheck}
            color="#2EE0AC"
            className="checkbox"
          />
          <div className="lorem-container">
            <div className="lorem-container">
              <h5>{formation.title3}</h5>
              <p>{formation.description3}</p>
            </div>
          </div>
        </div>
        <div className="lorem-big-container">
          <FontAwesomeIcon
            icon={faCheck}
            color="#2EE0AC"
            className="checkbox"
          />
          <div className="lorem-container">
            <h5>{formation.title4}</h5>
            <p>{formation.description4}</p>
          </div>
        </div>
      </div>
    </div>
        </div>}
  

      <Footer />
    </div>
  );
}

export default FormationDetailScreen;
