import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../constants/apiUrl";
import { sendMail } from "../../utils/sendEmail";

function ActivateAccount() {
  const [queryParameters] = useSearchParams();

  const navigate = useNavigate();
  useEffect(() => {
    const getPendingUser = async () => {
      try {
        const { data } = await axios.get(
          `${apiUrl}/api/pendinguser/${queryParameters.get("id")}`,
        );

        return data;
      } catch (e) {
        console.log(e);
      }
    };

    const createUser = async () => {
      const data = await getPendingUser();
      const {
        email,
        password,
        particulier,
        firstName,
        lastName,
        phone,
        localisation,
        poste,
        CV,
        _id,
      } = data;
      console.log(data);
      try {
        const response = await axios.post(`${apiUrl}/api/users`, {
          email,
          password,
          particulier,
          firstName,
          lastName,
          phone,
          localisation,
          poste,
          CV,
        });
        await axios.delete(`${apiUrl}/api/pendinguser/${_id}`);
        const responseMail = await sendMail(firstName, email, _id, 4);
        localStorage.setItem("userInfoTalent1", response.data.user._id);
        navigate("/");
        window.location.reload();
      } catch (e) {
        console.log(e);
      }
    };
    createUser();
  }, []);
  return <div />;
}

export default ActivateAccount;
