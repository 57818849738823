import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import axios from "axios";
import Typewriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import CardFormation from "../components/CardFormation";
import Footer from "../components/Footer";
import { apiUrl } from "../constants/apiUrl";
import wave from "../assets/wave_violet.svg";
import Button from "../components/Button";

function ListFormationsScreen() {
  const config = {
    headers: {
      accept: "application/json",
    },
  };

  const navigate = useNavigate();
  const [formationList, setFormationList] = useState([]);
  const [height, setHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const getFormations = async () => {
      const { data } = await axios.get(
        `${apiUrl}/api/formations/listformation`,
        config,
      );
      if (windowSize.current[0] > 1530) {
        if (data.length === 1 || data.length === 2) {
          setHeight(600);
        } else {
          setHeight(Math.ceil(data.length / 3) * 600);
        }
      } else if (windowSize.current[0] > 950 && windowSize.current[0] <= 1530) {
        if (data.length === 1 || data.length === 2) {
          setHeight(600);
        } else {
          setHeight(Math.ceil(data.length / 3) * 700);
        }
      } else if (windowSize.current[0] <= 950) {
        setHeight(Math.ceil(data.length) * 800);
      }
      data.sort(function (a, b) {
        return parseFloat(a.rank) - parseFloat(b.rank);
      });
      setFormationList(data);
      setIsLoading(false);
    };
    getFormations();
  }, []);

  if (isLoading) {
    return (
      <div className="super-container">
        <NavBar active="formations" />
        <div className="inner-no-cart-container container">
          <div className="spinner-border" role="status" />
        </div>
        <Footer />
      </div>
    );
  }
  return (
    <div>
      {formationList.length === 0 ? (
        <div className="super-container">
          <NavBar active="formations" />
          <div className="inner-no-cart-container container">
            <h3 className="no-cart-title my-3">
              Oups ! Aucune formation n'est disponible
            </h3>
            <Button
              className="no-cart-button"
              text="Retour à l'accueil"
              onClick={() => navigate("/")}
            />
          </div>
          <Footer />
        </div>
      ) : (
        <div className="super-container-formations">
          <NavBar active="formations" />
          <div className="block-1 block-1-contact justify-content-center">
            <h1 className="head-title">Explorez nos formations</h1>
            <h5 className="text-center text-white">
              {" "}
              <Typewriter
                options={{
                  strings: [
                    "Transformez votre potentiel en résultats concrets avec nos formations et devenez le leader de demain...",
                  ],
                  autoStart: true,
                  loop: true,
                  cursor: "_",
                  pauseFor: 15000,
                }}
              />
            </h5>
          </div>
          <img
            src={wave}
            alt=""
            className="w-100 wave"
            style={{ backgroundColor: "#F3F7F9" }}
          />

          {formationList ? (
            <div className="list-formations-container" style={{ height }}>
              <Grid
                className="grid-formation"
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 2, sm: 8, md: 12, lg: 12, xl: 12 }}
              >
                {formationList.map((formation) => {
                  const { id } = formation;
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={id}>
                      <CardFormation formation={formation} />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          ) : (
            ""
          )}

          <Footer />
        </div>
      )}
    </div>
  );
}

export default ListFormationsScreen;
