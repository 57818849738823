import axios from "axios";
import { pdf } from "@react-pdf/renderer";
import React from "react";
import { storage } from "../firebase";
import Facture from "../components/PDFViewer";
import { apiUrl } from "../constants/apiUrl";
import { uploadFileAsync } from "./upload";
import { formatDateForFacture } from "./date";

const handleSendCheckoutEmail = async (
  cartItems,
  total,
  sousTotal,
  userDetails,
  factureDate,
  date,
  setCurrentUser,
  TVA,
  codePromo,
) => {
  const blob = await pdf(
    <Facture
      cartItems={cartItems}
      total={total}
      sousTotal={sousTotal}
      userData={userDetails}
      TVA={TVA}
      codePromo={codePromo}
    />,
  ).toBlob();
  const number = Math.random() * 100000001;
  const file = new File(
    [blob],
    `${userDetails.lastName}-facture-${number}.pdf`,
    {
      lastModified: new Date().getTime(),
    },
  );
  const downloadUrl = await uploadFileAsync(file, `factures/${file.name}`);
  try {
    await axios.post(`${apiUrl}/api/email/sendinblue/facture`, {
      email: userDetails.email,
      lastName: userDetails.lastName,
      firstName: userDetails.firstName,
      items: cartItems,
      facturationAddress: userDetails.facturationAddress,
      facturationPostalCode: userDetails.facturationPostalCode,
      facturationCity: userDetails.facturationCity,
      enterpriseName: userDetails.enterpriseName,
      facturationCountry: userDetails.facturationCountry,
      factureDate,
      TVA,
      date,
      total,
      codePromo,
      sousTotal,
      fileUrl: downloadUrl,
    });
  } catch (err) {
    console.log(err);
  }
  let totalHT = 0;
  for (const item of cartItems) {
    totalHT += Number(item.prixHT);
    userDetails.formations.push({
      formationTitle: item.titre,
      date: item.startDate,
      factureUrl: downloadUrl,
      prixHT: item.prixHT,
      qty: item.qty,
      boughtDate: factureDate,
      startDate: item.startDate,
      endDate: item.endDate,
      enterpriseName: userDetails.enterpriseName,
      lastName: userDetails.lastName,
      firstName: userDetails.firstName,
      factureNumber: formatDateForFacture(),
      total,
      TVA,
    });
  }
  const userData = await axios.put(`${apiUrl}/api/users/profile`, {
    formations: userDetails.formations,
    id: userDetails._id,
  });
  const facture = {
    formationTitle: cartItems[0].titre,
    date: cartItems[0].startDate,
    factureUrl: downloadUrl,
    prixHT: totalHT,
    boughtDate: factureDate,
    enterpriseName: userDetails.enterpriseName,
    lastName: userDetails.lastName,
    firstName: userDetails.firstName,
    factureNumber: formatDateForFacture(),
    total,
    TVA,
  };
  await axios.post(`${apiUrl}/api/factureadmin`, {
    facture,
  });
  setCurrentUser(userData.data);
};

export { handleSendCheckoutEmail };
