import { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "../constants/apiUrl";

const useBaniere = () => {
  const [baniere, setBaniere] = useState(null);

  const fetchBaniere = async () => {
    const { data } = await axios.get(`${apiUrl}/api/baniere`);
    setBaniere(data[0]);
  };
  useEffect(() => {
    fetchBaniere();
  }, []);
  return { baniere };
};

export default useBaniere;
