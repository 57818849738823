import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApercuCommande from "../../components/cart/ApercuCommande";
import Footer from "../../components/Footer";
import NavBarFacturation from "../../components/NavBarFacturation";
import AuthContext from "../../context/currentUserContext";
import AdresseCard from "../../components/profil/AdresseCard";

function FacturationScreen() {
  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const [total, setTotal] = useState(0);
  const [sousTotalProps, setSousTotalProps] = useState(0);

  let sousTotal = 0;
  cartItems.forEach((item) => {
    sousTotal += Number(item.prixHT) * item.qty;
  });
  let TVA = 0;
  TVA = Number(sousTotal * 0.2).toFixed(2);

  useEffect(() => {
    setSousTotalProps(Number(sousTotal).toFixed(2));
    setTotal(Number(sousTotal * 1.2).toFixed(2));
  }, []);
  return (
    <div>
      <NavBarFacturation />
      {currentUser && (
        <div className="profile-main">
          <h1 className="mb-4">Facturation</h1>
          <div className="facturation-container">
            <div className="facturation-inner-container">
              <AdresseCard
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                title="Votre adresse de facturation"
                textButton="Passer au paiement"
                className="p-5"
              />
            </div>
            <ApercuCommande
              cartItems={cartItems}
              TVA={TVA}
              sousTotalProps={sousTotalProps}
              sousTotal={sousTotal}
              total={total}
              setTotal={setTotal}
            />
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default FacturationScreen;
