import React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import WhiteButton from "../WhiteButton";
import CustomDataGrid from "../CustomDataGrid";

function MesFactures({ factures }) {
  const columns = [
    {
      field: "formationTitle",
      headerName: "Titre de la formation",
      minWidth: 90,
      headerAlign: "center",
      align: "left",
      flex: 1,
      editable: false,
    },
    {
      field: "boughtDate",
      headerName: "Date d'achat",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      editable: false,
    },
    {
      field: "prixHT",
      flex: 0.5,
      headerName: "Tarif (€HT)",
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      flex: 0.5,
      field: "Facture",
      headerAlign: "center",
      align: "center",
      editable: false,
      renderCell: (params) => {
        return (
          <a href={params.row.factureUrl} download>
            <WhiteButton
              text="Télécharger"
              className="p-2"
              onClick={() => console.log(params)}
            />
          </a>
        );
      },
    },
  ];
  return <CustomDataGrid data={factures} columns={columns} height={500} />;
}

export default MesFactures;
