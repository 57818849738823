import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import deleteImage from "../../assets/Style.svg";
import { removeFromCart } from "../../actions/cartActions";
import QtySelector from "./QtySelector";
import CustomDataGrid from "../CustomDataGrid";

function DisplayCart({ cartItems }) {
  const dispatch = useDispatch();

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const columns = [
    {
      field: "titre",
      headerName: "TITRE",
      width: 400,
      flex: 3,
      editable: false,
    },
    {
      field: "qty",
      headerName: "QUANTITE",
      flex: 1,
      editable: false,
      renderCell: (params) => <QtySelector item={params.row} smooth />,
    },
    {
      field: "prixHT",
      flex: 1,
      headerName: "TARIF (€HT)",
      renderCell: (params) => (
        <p
          className="font-weight-bold"
          style={{ fontSize: "0.9rem", fontFamily: "Rubik-SemiBold" }}
        >
          {Number(params.row.prixHT).toFixed(2)} €
        </p>
      ),
      editable: false,
    },
    {
      headerName: "",
      editable: true,
      width: 50,
      renderCell: (params) => (
        <img
          src={deleteImage}
          className="delete-img"
          alt=""
          onClick={() => {
            console.log("params", params);
            removeFromCartHandler(params.row.product);
          }}
        />
      ),
    },
  ];

  function MyCustomNoRowsOverlay() {
    return (
      <h5 className="text-center mt-5">
        Vous n'avez aucune formation dans votre panier
      </h5>
    );
  }

  return <CustomDataGrid data={cartItems} columns={columns} height={500} />;
}

export default DisplayCart;
