import React from "react";

function DetailFormation({ img, title, undertitle }) {
  return (
    <div className="details">
      <div className="details-img-container">
        <img src={img} alt="" />
      </div>
      <h5>{title}</h5>
      <p>{undertitle}</p>
    </div>
  );
}

export default DetailFormation;
