import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { cartReducer } from "./reducers/cartReducer";

const reducer = combineReducers({
  cart: cartReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItemsTalent1")
  ? JSON.parse(localStorage.getItem("cartItemsTalent1"))
  : [];

const initialState = {
  cart: { cartItems: cartItemsFromStorage },
};

const store = createStore(reducer, initialState, applyMiddleware(thunk));

export default store;
