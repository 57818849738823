import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import axios from "axios";
import WhiteButton from "../WhiteButton";
import Button from "../Button";
import CustomInput from "../CustomInput";
import { apiUrl } from "../../constants/apiUrl";
import CustomUpload from "../CustomUpload";
import { Formik } from "formik";
function SimpleDialogQuizz(props) {
  const { onClose, selectedValue, open, formation, certificat, user } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const styles = {
    container: {
      maxWidth: "400px",
    },
    alignCenter: {
      textAlign: "center",
    },
  };
  const [url, setUrl] = useState("");
  const formations = user.formations;
  console.log(formations)
  const handleChangeCertificat = async () => {
    const index = formations.findIndex(x => x.formationTitle ===formation.formationTitle);
    formations[index].certificat = url
    await axios.put(`${apiUrl}/api/users/profile`, {
      id: user._id,
      formations,
    });
    handleClose();
  };
  const handleChangeQuizz = async () => {
    const index = formations.findIndex(x => x.formationTitle ===formation.formationTitle);
    console.log(index)
    formations[index].quizz = url
    await axios.put(`${apiUrl}/api/users/profile`, {
      id: user._id,
      formations,
    });
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={styles.container} className="d-flex flex-column justify-content-center">
        <h4 style={styles.alignCenter}>{certificat ? "Ajouter un certificat": "Ajouter un quizz"}</h4>
        <p style={styles.alignCenter}>{formation?.formationTitle}</p>
        {formation?.certificat && <a href={formation.certificat} target="_blank" className="text-center">Voir l'ancien certificat</a>}
        {certificat ? <CustomUpload className="w-100 my-4" text="Télécharger le certificat" file={url} setFile={setUrl} folder="certificats"/> : <CustomInput  value={url}
          onChange={(e) => setUrl(e.target.value)}
          label="Insérer une URL"
          className="w-100 my-4"/>}
    
        <div className="d-flex justify-content-around">
          <WhiteButton
            text="Annuler"
            className="mx-3"
            style={{ marginTop: "5%", borderRadius: "5px" }}
            onClick={handleClose}
          />
          <Button
            text={certificat ? "Ajouter le certificat" : "Ajouter le quizz"}
            style={{ marginTop: "5%", borderRadius: "5px" }}
            onClick={certificat ? () => handleChangeCertificat() : () => handleChangeQuizz()}
          />
        </div>
      </div>
    </Dialog>
  );
}

function SimpleDialogModifyQuizz(props) {
  const { onClose, selectedValue, open, formation, certificat, user, initialCertificatUrl, initialQuizzUrl } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  console.log("formation", formation)
  const styles = {
    container: {
      maxWidth: "400px",
    },
    alignCenter: {
      textAlign: "center",
    },
  };

  const initialValues = {
    quizz: initialQuizzUrl
  }

  const [certificatUrl, setCertificatUrl] = useState(formation?.certificat)
 
  const formations = user.formations;
  
  const handleChangeCertificat = async () => {
    const index = formations.findIndex(x => x.formationTitle ===formation.formationTitle);
    formations[index].certificat = certificatUrl
    await axios.put(`${apiUrl}/api/users/profile`, {
      id: user._id,
      formations,
    });
    handleClose();
    window.location.reload()
  };
  const handleChangeQuizz = async (values) => {
    const index = formations.findIndex(x => x.formationTitle ===formation.formationTitle);
    formations[index].quizz = values.quizz
    await axios.put(`${apiUrl}/api/users/profile`, {
      id: user._id,
      formations,
    });
    handleClose();
    window.location.reload()
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={styles.container} className="d-flex flex-column justify-content-center">
        <h4 style={styles.alignCenter}>{certificat ? "Modifier le certificat": "Modifier le quizz"}</h4>
        <p style={styles.alignCenter}>{formation?.formationTitle}</p>
      
        {certificat ? <div>
          <CustomUpload className="w-100 my-4" text="Uploader le certificat" file={initialCertificatUrl} setFile={setCertificatUrl} folder="certificats"/>   
          <div className="d-flex justify-content-around">
            <WhiteButton
              text="Annuler"
              className="mx-3"
              style={{ marginTop: "5%", borderRadius: "5px" }}
              onClick={handleClose}
            />
            <Button
              text={certificat ? "Modifier le certificat" : "Modifier le quizz"}
              style={{ marginTop: "5%", borderRadius: "5px" }}
              onClick={handleChangeCertificat}
            />
          </div>
        </div> : 
        
        <Formik initialValues={initialValues} onSubmit={(values) => handleChangeQuizz(values)}>
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <div>
              <CustomInput  
                value={values.quizz}
                onChange={handleChange("quizz")}
                label="Insérer une URL"
                className="w-100 my-4"
                />
                  <div className="d-flex justify-content-around">
                      <WhiteButton
                        text="Annuler"
                        className="mx-3"
                        style={{ marginTop: "5%", borderRadius: "5px" }}
                        onClick={handleClose}
                      />
                      <Button
                        text={certificat ? "Modifier le certificat" : "Modifier le quizz"}
                        style={{ marginTop: "5%", borderRadius: "5px" }}
                        onClick={handleSubmit}
                      />
                  </div>
            </div>

          )}
       
        </Formik>
     
          
          }
    
      </div>
    </Dialog>
  );
}

function FormationsUser({ orders, user }) {
  const [openQuizz, setOpenQuizz] = React.useState(false);
  const [openModifyQuizz, setOpenModifyQuizz] = React.useState(false);

  const [formation, setFormation] = useState(null);
  const [certificat, setCertificat] = useState(false)
  const columns = [
    {
      field: "formationTitle",
      headerName: "Formations",
      editable: false,
      headerAlign: "center",
      align: "left",
      minWidth: 90,
      flex: 1,
    },
    {
      field: "Date",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return (
          <p>
            Du {params.row.startDate} au {params.row.endDate}
          </p>
        );
      },
    },
    {
      field: "Quizz",
      editable: false,
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Button
            text={params.row.quizz ? "Editez le quizz" : "Ajouter un quizz"}
            className={params.row.quizz ? "bg-success p-2": "p-2"}
            onClick={() => {
              setCertificat(false)
              setFormation(params.row);
              params.row.quizz ? setOpenModifyQuizz(true) : setOpenQuizz(true)
            }}
          />
        );
      },
    },
    {
      field: "Certificats",
      editable: false,
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Button
          text={params.row.certificat ? "Editez le certificat" : "Ajouter un certificat"}
          className={params.row.certificat ? "bg-success p-2": "p-2"}
            onClick={() => {
              setFormation(params.row);
              setCertificat(true)
              params.row.certificat ? setOpenModifyQuizz(true) : setOpenQuizz(true)
            }}
          />
        );
      },
    },
  ];

  const handleCloseQuizz = () => {
    setOpenQuizz(false);
  };

  const handleCloseModifyQuizz = () => {
    setOpenModifyQuizz(false);
  };

  return (
    <div style={{ height: 500, width: "100%" }} className="bg-white mt-5">
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            rows={orders}
            columns={columns}
            pageSize={5}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
          <SimpleDialogQuizz
            open={openQuizz}
            user={user}
            onClose={handleCloseQuizz}
            formation={formation}
            certificat={certificat}
          />
          <SimpleDialogModifyQuizz
            open={openModifyQuizz}
            user={user}
            onClose={handleCloseModifyQuizz}
            formation={formation}
            initialCertificatUrl={formation?.certificat}
            initialQuizzUrl={formation?.quizz}
            certificat={certificat}
          />
        </div>
      </div>
    </div>
  );
}

export default FormationsUser;
