import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import axios from "axios";
import ApercuCommandeItem from "./ApercuCommandeItem";
import WhiteButton from "../WhiteButton";
import { apiUrl } from "../../constants/apiUrl";

function ApercuCommande({
  cartItems,
  displayPromoCode,
  handleSubmit,
  TVA,
  total,
  setTVA,
  setTotal,
  sousTotal,
  sousTotalProps,
  setSousTotalProps,
  setCodePromo,
}) {
  const [promoCode, setPromoCode] = useState("");
  const [err, setErr] = useState("");
  const [goodPromoCode, setGoodPromoCode] = useState(false);
  const [reduction, setReduction] = useState(0);

  const [promoCodeApply, setPromoCodeApply] = useState(false);
  const [error, setError] = useState("");
  const handlePromoCode = async () => {
    try {
      if (promoCodeApply) {
        setError("Vous avez déjà utilisé un code promo");
      } else {
        const { data } = await axios.get(
          `${apiUrl}/api/promocodes/${promoCode}`,
        );
        setGoodPromoCode(true);
        if (data.type === "Pourcentage") {
          setSousTotalProps((prevState) =>
            (prevState * (1 - data.value / 100)).toFixed(2),
          );
          setTotal(() =>
            (sousTotalProps * (1 - data.value / 100) * 1.2).toFixed(2),
          );
          setTVA(sousTotal * (1 - data.value / 100).toFixed(2) * 0.2);
          if (data.value === 100) {
            handleSubmit();
          }
          setReduction(Number((sousTotal * data.value) / 100).toFixed(2));
          setCodePromo(Number((sousTotal * data.value) / 100).toFixed(2));
          setPromoCodeApply(true);
        } else {
          setSousTotalProps((prevState) => prevState - data.value);
          setTotal((prevState) => ((prevState - data.value) * 1.2).toFixed(2));
          setCodePromo(data.value);
          setReduction(data.value);
          setPromoCodeApply(true);
        }
      }
    } catch (error) {
      setErr("Ce code promo n'est pas valide");
    }
  };

  console.log(reduction);
  return (
    <div className="adresse-facturation-container adresse-facturation-two">
      <h5 className="apercu">Aperçu de la commande</h5>
      <div className="commande-inner-container">
        {cartItems.map((item) => {
          const { id } = item;
          return <ApercuCommandeItem item={item} key={id} />;
        })}
        {displayPromoCode && (
          <div className="d-flex justify-content-between mt-3">
            <TextField
              id="outlined-basic"
              label="Code Promo"
              variant="outlined"
              className="w-50"
              onChange={(e) => setPromoCode(e.target.value)}
            />
            <WhiteButton
              text="Appliquer"
              onClick={handlePromoCode}
              className="appliquer-button"
            />
          </div>
        )}

        {err && <p className="text-danger">{err}</p>}
        {error && <p className="text-danger">{error}</p>}
        <h5 className="total-panier-text my-3">Total Panier</h5>
        {goodPromoCode && (
          <div className="price-container">
            <p className="price-text">Code Promo</p>
            <p>- {reduction}€</p>
          </div>
        )}
        <div className="price-container">
          <p className="price-text">Total (HT)</p>
          {sousTotalProps && <p>{sousTotalProps}€</p>}
        </div>

        <div className="price-container">
          <p className="price-text">TVA</p>
          <p>{TVA}€</p>
        </div>

        <div className="price-container">
          <p className="price-text">Total (TTC)</p>
          <p>{total}€</p>
        </div>
      </div>
    </div>
  );
}

export default ApercuCommande;
