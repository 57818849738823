import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router";
import NavBar from "../../components/NavBar";
import Button from "../../components/Button";
import CardFormation from "../../components/CardFormation";
import { apiUrl } from "../../constants/apiUrl";
import deleteImage from "../../assets/Style.svg";
import { removeFromCart } from "../../actions/cartActions";
import DisplayCart from "../../components/cart/DisplayCart";
import QtySelector from "../../components/cart/QtySelector";

function CartScreen() {
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const userInfo = localStorage.getItem("userInfoTalent1");

  const config = {
    headers: {
      accept: "application/json",
    },
  };
  const dispatch = useDispatch();

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };
  const [suggestedFormations, setSuggestedFormations] = useState([]);

  useEffect(() => {
    const getFormations = async () => {
      const { data } = await axios.get(
        `${apiUrl}/api/formations/listformation`,
        config,
      );
      console.log("data", data);
      const formationIds = [];
      for (const article of cartItems) {
        formationIds.push(article.product);
      }
      const suggestedFormationsArray = data.filter(
        (item) => formationIds.indexOf(item._id) === -1,
      );
      console.log(formationIds);
      console.log(suggestedFormations);
      setSuggestedFormations(suggestedFormationsArray);
    };
    getFormations();
  }, [cartItems]);

  let sousTotal = 0;

  for (const article of cartItems) {
    sousTotal += Number(article.prixHT) * article.qty;
  }
  let TVA = 0;
  TVA = Number(sousTotal * 0.2).toFixed(2);

  let total = 0;
  total = Number(sousTotal * 1.2).toFixed(2);

  return (
    <div>
      <NavBar />
      <div className="profile-main">
        <h2 className="mb-5">
          {cartItems.length}{" "}
          {cartItems.length === 1 ? "formation" : "formations"} dans votre
          panier
        </h2>

        <div className="d-flex justify-content-between cart-super-container">
          <div className="cart-container">
            <div className="cart-desktop">
              <DisplayCart cartItems={cartItems} />
            </div>

            <div className="cart-mobile">
              {cartItems.map((cartItem, index) => {
                return (
                  <div className="cart-mobile-card bg-white rounded p-3">
                    <div className="d-flex justify-content-between">
                      <p className="font-weight-bold">{cartItem.titre}</p>
                      <img
                        src={deleteImage}
                        className="delete-img"
                        alt=""
                        onClick={() => {
                          console.log("params", params);
                          removeFromCartHandler(params.row.product);
                        }}
                      />
                    </div>
                    <hr className="w-100" />
                    <div className="d-flex justify-content-between">
                      <p className="font-weight-bold qty-mobile">QUANTITE</p>
                      <QtySelector smooth item={cartItem} />
                    </div>
                    <hr className="w-100" />
                    <div className="d-flex justify-content-between">
                      <p className="font-weight-bold">PRIX</p>
                      <p>{cartItem.prixHT} € HT</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="paiement-container">
            <h5 className="mode-paiement-text">Aperçu de la commande</h5>

            <h5 className="total-panier-text my-3">Total Panier</h5>
            <div className="price-container">
              <p className="price-text">Sous-total (HT)</p>
              <p>{sousTotal.toFixed(2)}€</p>
            </div>
            <div className="price-container">
              <p className="price-text">TVA</p>
              <p>{TVA}€</p>
            </div>
            <div className="price-container">
              <p className="price-text">Total (TTC)</p>
              <p>{total}€</p>
            </div>
            <Button
              text="Valider la commande"
              className="valider-commande"
              onClick={() => {
                if (!userInfo) {
                  navigate("/login", {
                    state: {
                      route: "cart",
                    },
                  });
                } else {
                  navigate("/facturation");
                }
              }}
            />
          </div>
        </div>

        <div className="">
          {suggestedFormations[0] ? (
            <div className="">
              <h5 className="aimer-aussi">Vous aimerez peut être aussi</h5>

              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 2, sm: 8, md: 12 }}
              >
                {suggestedFormations.map((formation, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <CardFormation formation={formation} addCart />
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default CartScreen;
