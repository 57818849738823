import React from "react";
import { Link, useNavigate } from "react-router-dom";
import imgFooter from "../assets/logo_footer.svg";
import logoLinkedIn from "../assets/logo_lk.svg";

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer-container footer">
      <div className="flex-column">
        <img src={imgFooter} alt="" onClick={() => navigate("/")} />
        <p className="copyright">Tous droits réservés</p>
      </div>

      <div className="inner-footer-container">
        <Link to="/CGU">
          <p className="footer-paragraph">CGU</p>
        </Link>
        <Link to="/CGV">
          <p className="footer-paragraph">CGV</p>
        </Link>
      </div>

      <div className="linkedin-container">
        <a
          href="https://www.linkedin.com/company/talent-1/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={logoLinkedIn} alt="" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
