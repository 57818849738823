import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "../Button";
import { apiUrl } from "../../constants/apiUrl";
import CustomInput from "../CustomInput";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

function AdresseCard({ currentUser, className, setCurrentUser, textButton, title, profile, style }) {
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [facturationAddress, setFacturationAddress] = useState("");
  const [facturationPostalCode, setFacturationPostalCode] = useState("");
  const [facturationCity, setFacturationCity] = useState("");
  const [enterpriseName, setEnterpriseName] = useState("");
  const [facturationCountry, setFacturationCountry] = useState("")
  const navigate = useNavigate()
  useEffect(() => {
    setFirstName(currentUser.firstName);
    setLastName(currentUser.lastName);
    setFacturationAddress(currentUser?.facturationAddress)
    setFacturationPostalCode(currentUser?.facturationPostalCode)
    setFacturationCity(currentUser?.facturationCity)
    setEnterpriseName(currentUser?.enterpriseName)
    setFacturationCountry(currentUser?.facturationCountry)
  }, []);
  const [done, setDone] = useState(false);
  const handleForm = async (values) => {
    try {
      const { data } = await axios.put(`${apiUrl}/api/users/profile`, {
        id: currentUser._id,
        firstName: values.firstName,
        lastName: values.lastName,
        facturationAddress: values.facturationAddress,
        enterpriseName: values.enterpriseName,
        facturationPostalCode: values.facturationPostalCode,
        facturationCity: values.facturationCity,
        facturationCountry: values.facturationCountry
      });
      setCurrentUser(data);
      if (profile) {
        setDone(true)
        setTimeout(() => setDone(false), 2000);
      } else {
        navigate('/payment')
      }
      
    } catch (err) {
      console.log(err);
    }
  };

  const initialValues = {
    enterpriseName: currentUser.enterpriseName,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    facturationAddress: currentUser.facturationAddress,
    facturationPostalCode: currentUser.facturationPostalCode,
    facturationCity: currentUser.facturationCity,
    facturationCountry: currentUser.facturationCountry,
  }


  const facturationSchema = Yup.object().shape({
    firstName: Yup.string().required("Ce champ est requis"),
    lastName: Yup.string().required("Ce champ est requis"),
    facturationAddress: Yup.string().required("Ce champ est requis"),
    facturationPostalCode: Yup.string().required("Ce champ est requis"),
    facturationCity: Yup.string().required("Ce champ est requis"),
    facturationCountry: Yup.string().required("Ce champ est requis"),
  });

  return (
    <div className="facturation-container w-100">
      <div style={style} className={`adresse-facturation-container adresse-facturation-one w-100 ${className}`}>
        <h5 className="adresse-facturation-text">{title}</h5>

        <Formik 
      
        onSubmit={(values) => handleForm(values)}
          initialValues={initialValues}
          validationSchema={facturationSchema}
          >
            {({values, errors, handleChange, handleSubmit}) => {
              return (
                <div className="d-flex flex-column">
                    <CustomInput
          id="outlined-basic"
          label="Nom de votre société"
          variant="outlined"
          className="adresse-input-full mb-5"
          value={values.enterpriseName}
          onChange={handleChange("enterpriseName")}
        />
          {errors.enterpriseName && (
                        <div className="text-danger">
                          {errors.enterpriseName}
                        </div>
                      )}
        <div className="adresse-inner-container my-2">
          <div className="d-flex flex-column adresse-input">
          <CustomInput
        required={true}
            id="outlined-basic"
            label="Prénom"
            variant="outlined"
            className="w-100"
            value={values.firstName}
            onChange={handleChange("firstName")}
          />
              {errors.firstName && (
                        <div className="text-danger">
                          {errors.firstName}
                        </div>
                      )}
          </div>
      
      <div className="d-flex flex-column mb-5 adresse-input">
      <CustomInput
              required={true}
            id="outlined-basic"
            label="Nom"
            variant="outlined"
            className="w-100"
            value={values.lastName}
            onChange={handleChange("lastName")}
          />
   {errors.lastName && (
                        <div className="text-danger">
                          {errors.lastName}
                        </div>
                      )}
      </div>
     
        </div>
        <div className="my-2 mb-5">
          <CustomInput
            id="outlined-basic"
            label="Adresse"
            required={true}
            variant="outlined"
            className="adresse-input-full"
            value={values.facturationAddress}
            onChange={handleChange("facturationAddress")}
          />
           {errors.facturationAddress && (
                        <div className="text-danger">
                          {errors.facturationAddress}
                        </div>
                      )}
        </div>

        <div className="adresse-inner-container my-2">
          <div className="d-flex flex-column w-30 input-mobile">
          <CustomInput
              required={true}
            id="outlined-basic"
            label="Code Postal"
            variant="outlined"
            className="w-100"
            value={values.facturationPostalCode}
            onChange={handleChange("facturationPostalCode")}
          />
               {errors.facturationPostalCode && (
                        <div className="text-danger">
                          {errors.facturationPostalCode}
                        </div>
                      )}
          </div>
    
    <div className="d-flex flex-column w-30 input-mobile">
    <CustomInput
              required={true}
            id="outlined-basic"
            label="Ville"
            variant="outlined"
            className="w-100"
            value={values.facturationCity}
            onChange={handleChange("facturationCity")}
          />
               {errors.facturationCity && (
                        <div className="text-danger">
                          {errors.facturationCity}
                        </div>
                      )}
    </div>
     
     <div className="d-flex w-30 flex-column mb-4 input-mobile">
     <CustomInput
            id="outlined-basic"    
            required={true}
            label="Pays"
            type="text"
            variant="outlined"
            className="w-100"
            value={values.facturationCountry}
            onChange={handleChange("facturationCountry")}
          />
          {errors.facturationCountry && (
                        <div className="text-danger">
                          {errors.facturationCountry}
                        </div>
                      )}
     </div>
               
        </div>

          <Button
            text={textButton}
            onClick={handleSubmit}
            className="cancel-dialog-button mt-2 mb-3 modifier-container"
          />

        {done && (
        <div className="alert alert-success modification-done" role="alert">
          Vos modifications ont été prises en compte !
        </div>
      )}
                </div>
              )
            }}
        </Formik>
      
      </div>
     
    </div>
  );
}

export default AdresseCard;
