import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "../Button";
import { apiUrl } from "../../constants/apiUrl";
import CustomInput from "../CustomInput";
import { useNavigate } from "react-router-dom";

function AdresseCardUser({ currentUser, setCurrentUser, textButton, title, profile }) {
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [facturationAddress, setFacturationAddress] = useState("");
  const [facturationPostalCode, setFacturationPostalCode] = useState("");
  const [facturationCity, setFacturationCity] = useState("");
  const [enterpriseName, setEnterpriseName] = useState("");
  const [facturationCountry, setFacturationCountry] = useState("")
  const navigate = useNavigate()
  useEffect(() => {
    setFirstName(currentUser.firstName);
    setLastName(currentUser.lastName);
    setFacturationAddress(currentUser?.facturationAddress)
    setFacturationPostalCode(currentUser?.facturationPostalCode)
    setFacturationCity(currentUser?.facturationCity)
    setEnterpriseName(currentUser?.enterpriseName)
    setFacturationCountry(currentUser?.facturationCountry)
  }, []);
  const [done, setDone] = useState(false);
  const handleSubmit = async () => {
    try {
      const { data } = await axios.put(`${apiUrl}/api/users/profile`, {
        id: currentUser._id,
        firstName,
        lastName,
        facturationAddress,
        enterpriseName,
        facturationPostalCode,
        facturationCity,
        facturationCountry
      });
      setCurrentUser(data);
      if (profile) {
        setDone(true)
        setTimeout(() => setDone(false), 2000);
      } else {
        navigate('/payment')
      }
      
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="facturation-container w-100">
      <div className="adresse-facturation-container adresse-facturation-one w-100">
        <h5 className="adresse-facturation-text">{title}</h5>

        <CustomInput
          id="outlined-basic"
          label="Nom de votre société"
          variant="outlined"
          className="adresse-input-full mb-5"
          value={enterpriseName}
          onChange={(e) => setEnterpriseName(e.target.value)}
        />
        <div className="adresse-inner-container my-2">
        <CustomInput
            id="outlined-basic"
            label="Prénom"
            variant="outlined"
            className="adresse-input mb-5"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <CustomInput
            id="outlined-basic"
            label="Nom"
            variant="outlined"
            className="adresse-input mb-5"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
      
        </div>
        <div className="my-2">
          <CustomInput
            id="outlined-basic"
            label="Adresse"
            variant="outlined"
            className="adresse-input-full mb-5"
            value={facturationAddress}
            onChange={(e) => setFacturationAddress(e.target.value)}
          />
        </div>

        <div className="adresse-inner-container my-2">
          <CustomInput
            id="outlined-basic"
            label="Code Postal"
            variant="outlined"
            className="w-30 mb-5"
            value={facturationPostalCode}
            onChange={(e) => setFacturationPostalCode(e.target.value)}
          />
          <CustomInput
            id="outlined-basic"
            label="Ville"
            variant="outlined"
            className="w-30"
            value={facturationCity}
            onChange={(e) => setFacturationCity(e.target.value)}
          />
                <CustomInput
            id="outlined-basic"
            label="Pays"
            variant="outlined"
            className="w-30 mb-5"
            value={facturationCountry}
            onChange={(e) => setFacturationCountry(e.target.value)}
          />
        </div>

          <Button
            text={textButton}
            onClick={handleSubmit}
            className="cancel-dialog-button mt-2 mb-3 modifier-container"
          />

        {done && (
        <div className="alert alert-success modification-done" role="alert">
          Vos modifications ont été prises en compte !
        </div>
      )}
      </div>
     
    </div>
  );
}

export default AdresseCardUser;
