import React, { useEffect, useState } from "react";
import axios from "axios";
import SideBar from "../../components/SideBar";
import HeaderAdmin from "../../components/admin/HeaderAdmin";
import Button from "../../components/Button";
import CustomInput from "../../components/CustomInput";
import { apiUrl } from "../../constants/apiUrl";

function AddBanniereScreen() {
  const [text, setText] = useState("");
  const [link, setLink] = useState("");
  const [name, setName] = useState("");
  const [done, setDone] = useState(false);

  const addBanniere = async () => {
    await axios.post(`${apiUrl}/api/baniere`, {
      text,
      link,
      name,
    });
    setDone(true);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    const fetchBaniere = async () => {
      const { data } = await axios.get(`${apiUrl}/api/baniere`);
      setText(data[0].text);
      setLink(data[0].link);
      setName(data[0].name);
    };
    fetchBaniere();
  }, []);

  return (
    <div className="d-flex justify-center">
      <SideBar />
      <div className="add-formation-container">
        <HeaderAdmin text="Bannière" />

        <div className="add-baniere my-5 p-5 w-75 d-flex justify-center flex-column mx-5">
          <CustomInput
            label="Texte"
            className="my-5"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <div className="d-flex justify-content-between w-100">
            <CustomInput
              label="Lien"
              className="w-40"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            <CustomInput
              label="Nom du bouton"
              className="w-40"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <Button text="Sauvegarder" className="mt-5" onClick={addBanniere} />
          {done && (
            <div className="alert alert-success my-3" role="alert">
              La bannière a bien été changée
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddBanniereScreen;
