import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { useDispatch } from "react-redux";
import QtySelector from "./QtySelector";
import { removeFromCart } from "../../actions/cartActions";

function ApercuCommandeItem({ item }) {
  const dispatch = useDispatch();

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  return (
    <>
      <div className="d-flex justify-content-between my-2">
        <p className="facturation-title">{item.titre}</p>
        <FontAwesomeIcon
          icon={faTimes}
          className="cross-icon"
          style={{ cursor: "pointer" }}
          onClick={() => removeFromCartHandler(item.product)}
        />
      </div>

      <div className="d-flex justify-content-between facturation-card">
        <QtySelector item={item} />
        <p className="formation-price">
          {Number(item.prixHT * item.qty).toFixed(2)} €
        </p>
      </div>
    </>
  );
}

export default ApercuCommandeItem;
