import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Typewriter from "typewriter-effect";
import axios from "axios";
import { useSelector } from "react-redux";
import NavBar from "../components/NavBar";
import cross from "../assets/Close.svg";
import logo from "../assets/logo_talent1_header.svg";
import block1Img from "../assets/illu_Talent1.svg";
import nuage1 from "../assets/nuage1.svg";
import nuage2 from "../assets/nuage2.svg";
import nuage3 from "../assets/nuage3.svg";
import nuage4 from "../assets/nuage4.svg";
import nuage5 from "../assets/nuage5.svg";
import nuage6 from "../assets/nuage6.svg";
import nuage7 from "../assets/nuage7.svg";
import illuMobile1 from "../assets/illu_mobile1.svg";
import illuMobile2 from "../assets/illu_mobile2.svg";
import illuMobile3 from "../assets/illu_mobile3.svg";
import illuMobile4 from "../assets/illu_mobile4.svg";
import reconversionImage from "../assets/illu_formations_one page.svg";
import fusee from "../assets/fusee.svg";
import nosValeurs from "../assets/serpentin2.svg";
import green from "../assets/green.svg";
import satisfaction from "../assets/satisfaction.svg";
import expertise from "../assets/expertises.svg";
import performance from "../assets/performance.svg";
import transparence from "../assets/transparence.svg";
import Footer from "../components/Footer";
import Button from "../components/Button";
import useBaniere from "../hooks/useBaniere";
import wave from "../assets/wave_violet.svg";
import fuseeButtonClicked from "../assets/fusee_button_clicked.svg";
import { apiUrl } from "../constants/apiUrl";

function HomeScreen() {
  const navigate = useNavigate();
  const { baniere } = useBaniere();

  const [barOpen, setBarOpen] = useState(true);

  const [fuseeImage, setFuseeImage] = useState(fusee);
  const config = {
    headers: {
      accept: "application/json",
    },
  };
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  useEffect(() => {
    const getFormations = async () => {
      const { data } = await axios.get(
        `${apiUrl}/api/formations/listformation`,
        config,
      );
      console.log("cart", cartItems);
      const formationsIds = [];
      for (const formation of data) {
        formationsIds.push(formation._id);
      }
      for (const item of cartItems) {
        if (!formationsIds.includes(item.product)) {
          localStorage.removeItem("cartItemsTalent1");
        }
      }
      if (data.length === 0) {
        localStorage.removeItem("cartItemsTalent1");
      }
    };
    getFormations();
  }, []);
  return (
    <div className="html-container">
      <NavBar />

      <div className="block-1 shape">
        {barOpen && baniere && (
          <div className="purple-bar">
            <p className="text-white text-center py-2">
              🚀 {baniere.text}{" "}
              <a
                className="font-weight-bold text-decoration-underline more-infos text-white"
                href={baniere.link}
                target="_blank"
                rel="noreferrer"
              >
                {baniere.name}
              </a>
              <img
                src={cross}
                alt=""
                role="button"
                className="mx-3"
                onClick={() => setBarOpen(false)}
              />
            </p>
          </div>
        )}
        <div className="logo-container">
          <div className="big-logo">
            <img src={logo} alt="" className="big-logo-img" />
          </div>
          <h1 className="head-title">
            <span className="font-weight-bold">TALENT1</span>, une force humaine
            pour mener vos projets
            <div className="d-flex w-100 justify-content-center">
              <h1 className="text-center mx-2">with </h1>
              <Typewriter
                options={{
                  strings: [
                    "Agility.",
                    "Flexibility.",
                    "Adaptability.",
                    "Energy.",
                    "Love.",
                    "You !",
                  ],
                  autoStart: true,
                  loop: true,
                  cursor: "_",
                }}
              />
            </div>
          </h1>
        </div>
      </div>

      <div className="block2-container">
        <img src={wave} alt="" className="w-100 wave" />
        <div className="float-area nuage1">
          <img src={nuage1} alt="" className="floating-img" />
        </div>

        <div className="float-area nuage2">
          <img src={nuage2} alt="" className="floating-img" />
        </div>

        <div className="float-area nuage3">
          <img src={nuage3} alt="" className="floating-img" />
        </div>
        <div className="float-area nuage4">
          <img src={nuage4} alt="" className="floating-img" />
        </div>

        <div className="block-2">
          <img src={block1Img} alt="" className="blockImg1" />
          <div className="description-container">
            <div className="description-inner-container">
              <p className="description">
                <span className="font-weight-bold">Talent1</span> est un cabinet
                de conseil spécialisée dans les systèmes et les technologies de
                l'information.
              </p>
            </div>
            <div className="description-inner-container">
              <p className="description">
                Nous nous concentrons sur la gestion de projets et de
                portefeuilles, mettant notre savoir-faire à votre disposition
                pour améliorer votre organisation et la gestion de vos projets.{" "}
              </p>
            </div>
            <div className="description-inner-container">
              <p className="description">
                En complément de la méthodologie, nous disposons d'une expertise
                technique pour vous conseiller sur l'utilisation des outils les
                mieux adaptés pour une vue à 360°.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 solutions-container">
        <div className="float-area nuage5">
          <img src={nuage5} alt="" className="floating-img" />
        </div>
        <div className="float-area nuage6">
          <img src={nuage6} alt="" className="floating-img" />
        </div>
        <div className="float-area nuage7">
          <img src={nuage7} alt="" className="floating-img" />
        </div>
        <h2 className="text-center my-5 py-5 z-3 solutions-title home-title">
          Nos services
        </h2>
        <div className="block3-mobile">
          <div className="flex-column justify-content-center d-flex my-3">
            <img src={illuMobile1} alt="" className="illu-mobile" />
            <h3 className="text-center mt-3">Conseil en gestion de projet</h3>
            <p className="text-center w-75 align-self-center  ">
              Nous nous efforçons de mettre en œuvre des méthodologies adaptées
              pour faire face aux besoins en constante évolution des métiers et
              des utilisateurs et garantir ainsi le succès de vos projets.
            </p>
          </div>
          <div className="flex-column justify-content-center d-flex my-3">
            <img src={illuMobile2} alt="" className="illu-mobile" />
            <h3 className="text-center mt-3">Conseil stratégique</h3>
            <p className="text-center w-75 w-75 align-self-center">
              En nous concentrant sur la stratégie de systèmes et de technologie
              de l'information, nous vous aidons à gérer la gouvernance, la
              transformation numérique, l'audit et la qualité.
            </p>
          </div>
          <div className="flex-column justify-content-center d-flex my-3">
            <img src={illuMobile3} alt="" className="illu-mobile" />
            <h3 className="text-center mt-3">Expertises IT</h3>
            <p className="text-center w-75 align-self-center">
              Nous surveillons en permanence les dernières avancées
              technologiques, en particulier en matière d'ingénierie
              applicative, d'infrastructures et de cybersécurité.
            </p>
          </div>
          <div className="flex-column justify-content-center d-flex my-3">
            <img src={illuMobile4} alt="" className="illu-mobile" />
            <h3 className="text-center mt-3">Coaching & Formations</h3>
            <p className="text-center w-75 align-self-center">
              Prenez en main votre avenir et devenez le leader de demain en vous
              formant aujourd'hui. Maîtrisez votre environnement et devenez
              incontournable dans votre domaine.
            </p>
          </div>
        </div>
        <div className="block-3">
          <div className="flex-column expertise-container">
            <div className="expertise">
              <h3>Conseil en gestion de projet</h3>
              <p>
                Nous nous efforçons de mettre en œuvre des méthodologies
                adaptées pour faire face aux besoins en constante évolution des
                métiers et des utilisateurs et garantir ainsi le succès de vos
                projets.
              </p>
            </div>
            <div className="expertise">
              <h3>Conseil stratégique</h3>
              <p>
                En nous concentrant sur la stratégie de systèmes et de
                technologie de l'information, nous vous aidons à gérer la
                gouvernance, la transformation numérique, l'audit et la qualité.
              </p>
            </div>
          </div>

          <a className="fusee" href="/services">
            <img
              src={fuseeImage}
              alt=""
              onMouseEnter={() => {
                setFuseeImage(fuseeButtonClicked);
              }}
              onMouseOut={() => {
                setFuseeImage(fusee);
              }}
            />
          </a>

          <div className="flex-column expertise-container">
            <div className="expertise">
              <h3>Expertises IT</h3>
              <p>
                Nous surveillons en permanence les dernières avancées
                technologiques, en particulier en matière d'ingénierie
                applicative, d'infrastructures et de cybersécurité.
              </p>
            </div>
            <div className="expertise">
              <h3>Coaching & Formations</h3>
              <p>
                Prenez en main votre avenir et devenez le leader de demain en
                vous formant aujourd'hui. Maîtrisez votre environnement et
                devenez incontournable dans votre domaine.
              </p>
            </div>
          </div>
        </div>
        <div className="green-background">
          <img src={green} alt="" className="green-img" />
        </div>
      </div>

      <div className="block-4">
        <div className="serpentin">
          <img src={nosValeurs} alt="" />
        </div>
        <div className="nos-valeurs">
          <h2 className="home-title tablette-vue">Nos valeurs</h2>
          <div className="value my-4 value-tbalette-container">
            <div alt="" className="img-undisplay" />
            <div className="value-description value-head-title-container">
              <h3 className="value-title head-value-title home-title">
                Nos valeurs
              </h3>
            </div>
          </div>
          <div className="value my-4">
            <img src={satisfaction} alt="" className="img-value" />
            <div className="value-description">
              <h5 className="value-title satisfaction">Satisfaction</h5>
              <p>
                Pour garantir la satisfaction, nous nous concentrons sur la
                qualité de nos services, qui sont personnalisés, évolutifs et
                adaptés à vos besoins.{" "}
                <p>
                  La confiance est également un élément clé de notre approche,
                  car elle est la fondation d'une relation à long terme.
                </p>{" "}
              </p>
            </div>
          </div>
          <div className="value my-4">
            <img src={expertise} alt="" className="img-value" />
            <div className="value-description">
              <h5 className="value-title engagement">Expertise & Engagement</h5>
              <p>
                Notre savoir-faire comprend à la fois une expertise technique
                dans notre domaine en constante évolution, mais aussi
                fonctionnelle, avec les méthodologies et les référentiels
                appropriés.{" "}
                <p>
                  Nous nous impliquons pleinement pour garantir le succès de vos
                  projets, en prenant en compte vos attentes et en faisant de
                  vos défis notre priorité.
                </p>
              </p>
            </div>
          </div>
          <div className="value my-4">
            <img src={performance} alt="" className="img-value" />
            <div className="value-description">
              <h5 className="value-title performance">Performance</h5>
              <p>
                Nous nous engageons à respecter les délais, les coûts et les
                attentes de nos clients tout en améliorant la performance de nos
                prestations.{" "}
                <p>
                  Nous sommes également attentifs à apporter une valeur ajoutée
                  en gérant les risques associés, ce qui nous permet de fournir
                  des solutions qui répondent à vos besoins de manière
                  efficiente, fiable et performante.
                </p>{" "}
              </p>
            </div>
          </div>
          <div className="value my-4">
            <img src={transparence} alt="" className="img-value" />
            <div className="value-description">
              <h5 className="value-title transparence">Transparence</h5>
              <p>
                Notre objectif est de vous présenter une proposition claire,
                concise et bien définie qui reflète la nature de nos
                prestations.{" "}
                <p>
                  Nous accordons une grande importance à la qualité et à la
                  précision de notre présentation afin de vous permettre de
                  comprendre de manière claire et efficace ce que nous pouvons
                  vous offrir.
                </p>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="reconversion-container">
        <div className="reconversion-inner">
          <div className="img-reconversion-container">
            <img src={reconversionImage} alt="" className="reconversion-img" />
          </div>
          <div className="reconversion-left">
            <h2 className="reconversion-title">
              Vous envisagez une reconversion ou approfondir vos compétences ?
            </h2>
            <p className="reconversion-text my-4">
              Consultez notre catalogue de formation pour donner un boost à
              votre carrière...
            </p>
            <Button
              onClick={() => navigate("/formations")}
              text="Découvrir nos formations"
              className="reconversion-button"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomeScreen;
