import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import axios from "axios";
import logoConnexion from "../../assets/logo_connexion.svg";
import imgConnexion from "../../assets/connexionImg.svg";
import Button from "../../components/Button";
import { apiUrl } from "../../constants/apiUrl";
import CustomInput from "../../components/CustomInput";
import AuthContext from "../../context/currentUserContext";

function ConnexionScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const {setCurrentUser} = useContext(AuthContext)
  const location = useLocation();
  const route = location.state?.route;
  console.log("process", process.env);
  const handleLogin = async () => {
    try {
      const { data } = await axios.post(`${apiUrl}/api/users/login`, {
        email,
        password,
      });
      console.log("data", data);
      localStorage.setItem("userInfoTalent1", data.user._id);
      setCurrentUser(data.user)
      if (route) {
        navigate("/cart");
      } else {
        navigate("/");
      }
    } catch (err) {
      setError("Vos identifiants sont invalides");
    }
  };

  return (
    <div className="connexion-container">
      <div className="left-container">
        <div className="form-connexion-container">
          <img src={logoConnexion} alt="" className="connexion-logo" onClick={() => navigate('/')}/>
          <h1>Me connecter à Talent1</h1>

          <div className="flex-row d-flex signup-container">
            <p>Pas encore de compte ?</p>{" "}
            <Link to="/signup">
              <p className="signup-text">S&apos;inscrire</p>
            </Link>
          </div>

          <div className="text-field-container">
            <CustomInput
              id="outlined-helperText"
              label="Adresse e-mail"
              color="secondary"
              focused
              className="text-field"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="text-field-container">
            <CustomInput
              id="outlined-password-input"
              label="Mot de passe"
              type="password"
              className="text-field"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <Link to="/forgotten-password">
            <p className="forgotten-password">Mot de passe oublié ?</p>
          </Link>

          {error && (
            <p className="error">
              Votre identifiant et/ou mot de passe sont invalides.
            </p>
          )}
          <Button
            text="Se connecter"
            style={{ marginTop: "15%" }}
            onClick={handleLogin}
          />
        </div>
      </div>

      <div className="img-connexion-container">
        <div className="img-connexion-inner-container">
          <img src={imgConnexion} alt="" />
        </div>
      </div>
    </div>
  );
}

export default ConnexionScreen;
