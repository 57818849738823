import React, { useState, useEffect, useContext } from "react";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Typography } from "@mui/material";
import confirmationCommande from "../assets/confirmationCommande.svg";
import Button from "./Button";
import { handleSendCheckoutEmail } from "../utils/email";
import { apiUrl } from "../constants/apiUrl";
import AuthContext from "../context/currentUserContext";
import { formatDateForFacture } from "../utils/date";
import CustomLoader from "./CustomLoader";

function SimpleDialog(props) {
  const { open } = props;

  const navigate = useNavigate();

  return (
    <Dialog open={open}>
      <DialogTitle>Votre formation a été validée !</DialogTitle>
      <Typography className="text-center">
        Vous allez être contacté pour la planification.
      </Typography>
      <div className="confirm-command-photo-container">
        <img src={confirmationCommande} alt="" />
      </div>
      <Button
        text="Retour à la page d'accueil"
        onClick={() => {
          navigate("/");
          window.location.reload();
        }}
      />
    </Dialog>
  );
}

function CheckoutForm({ amount, cartItems, total, sousTotal, TVA, codePromo }) {
  const stripe = useStripe();
  const elements = useElements();

  const [open, setOpen] = React.useState(false);

  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [customError, setCustomError] = useState("");

  const date = formatDateForFacture();

  const handleSuccessfullPayment = async () => {
    const orderData = await axios.post(`${apiUrl}/api/orders`, {
      orderItems: cartItems,
      _id: currentUser._id,
    });

    await handleSendCheckoutEmail(
      cartItems,
      total,
      sousTotal,
      currentUser,
      new Date().toLocaleDateString(),
      date,
      setCurrentUser,
      TVA,
      codePromo,
    );
    localStorage.removeItem("cartItemsTalent1");
    setIsLoading(false);
    setOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    console.log("err", error);
    if (error) {
      if (error.type === "validation_error") {
        setCustomError("Veuillez remplir tous les champs");
      }
    }
    console.log("error", error);
    if (!error) {
      setIsLoading(true);
      const { id } = paymentMethod;
      try {
        const { data } = await axios.post(`${apiUrl}/api/charge`, {
          id,
          amount,
        });
        if (data.actionRequired) {
          // We perform 3D Secure authentication
          const { paymentIntent } = await stripe.confirmCardPayment(
            data.clientSecret,
          );
          if (paymentIntent.status === "succeeded") {
            handleSuccessfullPayment();
          } else {
            return console.log("Error in payment, please try again later");
          }

          const res2 = await axios.get(`${apiUrl}/check/${data.id}`);
          console.log(`Payment successful, payment ID - ${data.id}`);
        } else {
          // Simple HTTP Payment was successful
          handleSuccessfullPayment();
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const inputStyle = {
    iconColor: "#c4f0ff",
    color: "black",
    fontWeight: "500",
    fontFamily: "Rubik, sans-serif",
    fontSize: "16px",
    fontSmoothing: "antialiased",
    ":-webkit-autofill": {
      color: "black",
    },
    "::placeholder": {
      content: "Numéro de carte",
      color: "black",
    },
  };

  const CardInputWrapper = styled.div`
    border: 1px solid black;
    border-radius: 8px;
    padding: 20px 4px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 49%;
    @media (max-width: 600px) {
      width: 100%;
    }
  `;

  const CardInputSmallWrapper = styled.div`
    border: 1px solid black;
    border-radius: 8px;
    padding: 20px 4px;
    width: 49%;
  `;

  const CardInputSmallWrapperLeft = styled.div`
    border: 1px solid black;
    border-radius: 8px;
    padding: 20px 4px;
    width: 49%;
    margin-right: 20px;
  `;

  return (
    <form>
      <CardInputWrapper>
        <CardNumberElement
          options={{
            style: {
              base: inputStyle,
            },
            placeholder: "Numéro de carte",
          }}
        />
      </CardInputWrapper>

      <div className="d-flex">
        <CardInputSmallWrapperLeft>
          <CardExpiryElement
            options={{
              style: {
                base: inputStyle,
              },
              placeholder: "Date d'expiration",
            }}
          />
        </CardInputSmallWrapperLeft>

        <CardInputSmallWrapper>
          <CardCvcElement
            options={{
              style: {
                base: inputStyle,
              },
              placeholder: "CVC",
            }}
          />
        </CardInputSmallWrapper>
      </div>

      {customError && (
        <div className="alert alert-danger mt-3" role="alert">
          {customError}
        </div>
      )}
      {isLoading ? (
        <CustomLoader className="align-self-right float-right" />
      ) : (
        <Button
          text="Payer maintenant"
          className="pay-button"
          onClick={handleSubmit}
        />
      )}

      <SimpleDialog open={open} />
    </form>
  );
}

export default CheckoutForm;
