import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom";
import CookieConsent from "react-cookie-consent";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import App from "./App";
import WhiteButton from "./components/WhiteButton";

ReactDOM.render(
  <React.StrictMode>
    <App />
    <CookieConsent
      style={{ background: "#6200EE" }}
      buttonText={<WhiteButton text="Je comprends" />}
      buttonStyle={{ color: "white", fontSize: "13px" }}
    >
      Ce site utilise des cookies pour une meilleure expérience utilisateur
    </CookieConsent>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
