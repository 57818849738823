import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import axios from "axios";
import "./App.css";

import HomeScreen from "./screens/HomeScreen";
import ListFormationsScreen from "./screens/ListFormationsScreen";
import ConnexionScreen from "./screens/auth/ConnexionScreen";
import "./styles/HomeScreen.css";
import "./styles/Footer.css";
import "./styles/Connexion.css";
import "./styles/InformationsScreen.css";
import "./styles/ListeFormationScreen.css";
import "./styles/CardFormation.css";
import "./styles/FormationDetailScreen.css";
import "./styles/DetailFormation.css";
import "./styles/ContactScreen.css";
import "./styles/SideBar.css";
import "./styles/FormationListCard.css";
import "./styles/AddFormationScreen.css";
import "./styles/WhiteButton.css";
import "./styles/ProfileScreen.css";
import "./styles/CartScreen.css";
import "./styles/FacturationScreen.css";
import "./styles/CheckoutForm.css";
import "./styles/ServicesScreen.css";
import SignupScreen from "./screens/auth/SignupScreen";
import InformationsScreen from "./screens/auth/InformationsScreen";
import FormationDetailScreen from "./screens/FormationDetailScreen";
import ContactScreen from "./screens/ContactScreen";
import store from "./store";
import AddFormationScreen from "./screens/admin/AddFormationScreen";
import ProfileScreen from "./screens/profile/ProfileScreen";
import Users from "./screens/admin/Users";
import ViewUserProfile from "./screens/admin/ViewUserProfile";
import CartScreen from "./screens/cart/CartScreen";
import FacturationScreen from "./screens/cart/FacturationScreen";
import ChoosePaymentScreen from "./screens/cart/ChoosePaymentScreen";
import ServicesScreen from "./screens/ServicesScreen";
import AddPromoCodeScreen from "./screens/admin/AddPromoCodeScreen";
import ForgottenPasswordScreen from "./screens/auth/ForgottenPassword";
import ResetPasswordScreen from "./screens/auth/ResetPassword";
import AuthContext from "./context/currentUserContext";
import NoCartItemsScreen from "./screens/cart/NoCartItemsScreen";
import AddBanniereScreen from "./screens/admin/AddBaniereScreen";
import { apiUrl } from "./constants/apiUrl";
import CGUScreen from "./screens/CGUScreen";
import ActivateAccount from "./screens/auth/ActivateAccount";
import NotFoundScreen from "./screens/404Screen";
import DashboardScreen from "./screens/admin/DashboardScreen";
import CGVScreen from "./screens/CGVScreen";
import ScrollToTop from "./ScrollToTop";

function App() {
  const [currentUser, setCurrentUser] = React.useState(null);
  const [loggedIn, setIsLoggedIn] = useState(false);

  let userId;
  useEffect(() => {
    const fetchCurrentUser = async () => {
      userId = localStorage.getItem("userInfoTalent1");
      if (!userId) {
        setIsLoggedIn(false);
      } else {
        const { data } = await axios.post(`${apiUrl}/api/users/profile`, {
          _id: userId,
        });
        setCurrentUser(data);
        setIsLoggedIn(true);
      }
    };
    fetchCurrentUser();
  }, [userId]);

  return (
    <Provider store={store}>
      <AuthContext.Provider
        value={{
          currentUser,
          setCurrentUser,
          setIsLoggedIn,
          loggedIn,
        }}
      >
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/services" element={<ServicesScreen />} />
            <Route path="/formations" element={<ListFormationsScreen />} />
            <Route path="/login" element={<ConnexionScreen />} />
            <Route path="/signup" element={<SignupScreen />} />
            <Route path="/informations" element={<InformationsScreen />} />
            <Route path="/formations/:id" element={<FormationDetailScreen />} />
            <Route path="/contact" element={<ContactScreen />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/admin/formations" element={<AddFormationScreen />} />
            <Route path="/admin/banniere" element={<AddBanniereScreen />} />
            <Route path="/admin/users" element={<Users />} />
            <Route path="/admin/promocodes" element={<AddPromoCodeScreen />} />
            <Route path="/admin/view-users" element={<ViewUserProfile />} />
            <Route path="/admin/dashboard" element={<DashboardScreen />} />
            <Route path="/cart" element={<CartScreen />} />
            <Route path="/facturation" element={<FacturationScreen />} />
            <Route path="/payment" element={<ChoosePaymentScreen />} />
            <Route path="/activate" element={<ActivateAccount />} />
            <Route path="/no-cart" element={<NoCartItemsScreen />} />
            <Route path="/CGV" element={<CGVScreen />} />
            <Route path="/CGU" element={<CGUScreen />} />
            <Route
              path="/forgotten-password"
              element={<ForgottenPasswordScreen />}
            />
            <Route path="/reset-password" element={<ResetPasswordScreen />} />
          </Routes>
        </Router>
      </AuthContext.Provider>
    </Provider>
  );
}

export default App;
