import { pdf } from "@react-pdf/renderer";
import React from "react";
import { storage } from "../firebase";
import Facture from "../components/PDFViewer";

export const formatDate = (date) => {
  console.log("date");
  date.replaceAll("/", "-");
  const arr = date.split("-");
  arr.reverse();
  date = arr.join("");
  console.log("date", date);
  return date;
};

export const generateFactureUrl = async (
  cartItems,
  total,
  sousTotal,
  userData,
) => {
  const blob = await pdf(
    <Facture
      cartItems={cartItems}
      total={total}
      sousTotal={sousTotal}
      userData={userData}
    />,
  ).toBlob();
  let url;
  const number = Math.random() * 100000001;
  const file = new File([blob], `${userData.name}-facture-${number}.pdf`, {
    lastModified: new Date().getTime(),
  });
  console.log("file", file);
  const uploadTask = storage.ref(`factures/${file.name}`).put(file);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
      );
      console.log(progress);
    },
    (error) => {
      console.log(error);
    },
    () => {
      storage
        .ref("factures")
        .child(file.name)
        .getDownloadURL()
        .then(async (downloadUrl) => {
          url = downloadUrl;
        });
    },
  );
  return url;
};

export function hexToRgbAEncadre(hex) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join("")}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},1)`;
  }
  throw new Error("Bad Hex");
}

export function hexToRgbA(hex) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join("")}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},0.2)`;
  }
  throw new Error("Bad Hex");
}
