import React, { useState } from "react";
import imageUpload from "../assets/ImageUpload.svg";
import { uploadFileAsync } from "../utils/upload";
import CustomLoader from "./CustomLoader";

function CustomImageUpload({ file, setFile, text, formation }) {
  const hiddenFileInput = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedFileName, setSelectedFileName] = useState("");
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = async (event) => {
    setIsLoading(true);
    const fileUploaded = event.target.files[0];
    const downloadUrl = await uploadFileAsync(
      fileUploaded,
      `images/${fileUploaded.name}`,
    );
    setSelectedFileName(fileUploaded.name);
    console.log(downloadUrl);
    setFile(downloadUrl);
    setIsLoading(false);
  };
  return (
    <div>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div>
          {file ? (
            <div>
              <div className="add-image-container-fillout">
                <img src={file} className="add-image-container-img" />
              </div>
              <div className="d-flex">
                <p className="mb-5 cursor-pointer" onClick={handleClick}>
                  Modifier
                </p>
                <p
                  onClick={() => setFile("")}
                  className="text-danger mx-3 cursor-pointer"
                >
                  Supprimer
                </p>
              </div>

              <input
                type="file"
                accept="image/*"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
            </div>
          ) : (
            <div>
              <div
                className="rounded p-3 add-image-container d-flex flex-column justify-content-center"
                onClick={handleClick}
                style={{ border: "1px solid lightgrey", cursor: "pointer" }}
              >
                <div className="self-center d-inline-block">
                  <img src={imageUpload} alt="" />
                  <p className="add-image-text">Ajouter une image</p>
                </div>
              </div>
              <input
                type="file"
                accept="image/*"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CustomImageUpload;
