import React, { useState } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import logoConnexion from "../../assets/logo_connexion.svg";
import imgConnexion from "../../assets/connexionImg.svg";
import Button from "../../components/Button";
import { apiUrl } from "../../constants/apiUrl";
import { sendResetPasswordEmail } from "../../utils/sendEmail";
import CustomInput from "../../components/CustomInput";

function SimpleDialog(props) {
  const { onClose, selectedValue, open, email } = props;

  const navigate = useNavigate();
  const handleClose = () => {
    onClose(selectedValue);
    navigate("/");
  };

  const styles = {
    container: {
      maxWidth: "400px",
    },
    alignCenter: {
      textAlign: "center",
    },
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={styles.container}>
        <h4 style={styles.alignCenter}>Récupérer mon mot de passe</h4>
        <p style={styles.alignCenter}>
          S'il existe un compte associé à l'adresse {email}, un email de
          confirmation sera envoyé. Il contient un lien pour réinitialiser votre
          mot de passe.
        </p>
        <Button
          text="J'ai compris"
          style={{ marginTop: "5%", borderRadius: "5px" }}
          onClick={handleClose}
        />
      </div>
    </Dialog>
  );
}

function ForgottenPasswordScreen() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendResetPasswordEmail = async () => {
    const { data } = await axios.get(
      `${apiUrl}/api/users/exists/${email.trim().toLowerCase()}`,
    );
    console.log("data", data);
    if (data !== "Cet utilisateur n'existe pas") {
      try {
        const response = await sendResetPasswordEmail(email, 3, data.firstName);
        handleClickOpen();
      } catch (err) {
        console.log(err);
      }
    } else {
      setError("Vos identifiants sont invalides");
    }
  };

  return (
    <div className="connexion-container">
      <div className="left-container">
        <div className="form-connexion-container">
          <img
            src={logoConnexion}
            alt=""
            className="connexion-logo"
            onClick={() => navigate("/")}
          />
          <h1>Mot de passe oublié ?</h1>
          <p>
            Pour modifier votre mot de passe, saisissez l’adresse email avec
            laquelle vous avez créé votre compte Talent1.{" "}
          </p>
          <div className="flex-row d-flex signup-container" />

          <div className="text-field-container">
            <CustomInput
              id="outlined-helperText"
              label="Adresse e-mail"
              color="secondary"
              focused
              className="text-field"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {error && <p className="error">Vos identifiants sont invalides</p>}
          <Button
            text="Réinitialiser votre mot de passe"
            style={{ marginTop: "15%" }}
            onClick={handleSendResetPasswordEmail}
          />
        </div>
      </div>
      <SimpleDialog open={open} onClose={handleClose} email={email} />
      <div className="img-connexion-container">
        <div className="img-connexion-inner-container">
          <img src={imgConnexion} alt="" />
        </div>
      </div>
    </div>
  );
}

export default ForgottenPasswordScreen;
