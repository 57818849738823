import React, { useEffect, useState, useContext } from "react";
import Radio from "@mui/material/Radio";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import axios from "axios";
import { Typography } from "@mui/material";

import { PayPalButton } from "react-paypal-button-v2";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import NavBarFacturation from "../../components/NavBarFacturation";
import Footer from "../../components/Footer";
import visa from "../../assets/Visa (1).svg";
import mastercard from "../../assets/Mastercard.svg";
import paypal from "../../assets/Paypal.svg";
import CheckoutForm from "../../components/CheckoutForm";
import ApercuCommande from "../../components/cart/ApercuCommande";
import confirmationCommande from "../../assets/confirmationCommande.svg";
import Button from "../../components/Button";
import { handleSendCheckoutEmail } from "../../utils/email";
import { apiUrl } from "../../constants/apiUrl";
import AuthContext from "../../context/currentUserContext";
import { formatDateForFacture } from "../../utils/date";

const stripePromise = loadStripe(
  "pk_live_51KRPacDHaizn6dX0dNrK7gY4PmoWIpZoSK1gIlkTRYcP9GXi5x80f0MDU9MVRBS1oghTJFTg3qgkGKTUgf2DxNxM00dnUcT2Jy",
);
function SimpleDialog(props) {
  const { open } = props;

  const navigate = useNavigate();

  return (
    <Dialog open={open}>
      <DialogTitle>Votre formation a été validée !</DialogTitle>
      <Typography>Vous allez être contacté pour la planification.</Typography>

      <div className="confirm-command-photo-container">
        <img src={confirmationCommande} alt="" />
      </div>
      <Button
        text="Retour à la page d'accueil"
        onClick={() => {
          navigate("/");
          window.location.reload();
        }}
      />
    </Dialog>
  );
}

function ChoosePaymentScreen() {
  const [cardSelected, setCardSelected] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const handleChange = () => {
    setCardSelected(!cardSelected);
  };

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const [total, setTotal] = useState(0);
  const [sousTotalProps, setSousTotalProps] = useState(0);
  let sousTotal = 0;
  cartItems.forEach((item) => {
    sousTotal += Number(item.prixHT) * item.qty;
  });
  const [TVA, setTVA] = useState(0);

  useEffect(() => {
    setSousTotalProps(Number(sousTotal).toFixed(2));
    setTotal(Number(sousTotal * 1.2).toFixed(2));
    setTVA(Number(sousTotal * 0.2).toFixed(2));
  }, []);

  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const date = formatDateForFacture();

  const [codePromo, setCodePromo] = useState(0);

  const handleSubmit = async () => {
    const orderData = await axios.post(`${apiUrl}/api/orders`, {
      orderItems: cartItems,
      _id: currentUser._id,
    });
    await handleSendCheckoutEmail(
      cartItems,
      total,
      sousTotalProps,
      currentUser,
      new Date().toLocaleDateString(),
      date,
      setCurrentUser,
      TVA,
      codePromo,
    );
    localStorage.removeItem("cartItemsTalent1");
    setOpen(true);
  };

  return (
    <div className="custom-container">
      <NavBarFacturation paiement />
      <div className="profile-main inner-custom-container">
        <h1 className="mb-4">Paiement</h1>
        <div className="facturation-container">
          <div className="adresse-facturation-container adresse-facturation-one position-relative">
            <h5 className="adresse-facturation-text">
              Choisir un moyen de paiement
            </h5>
            <div className="radio-container">
              <div className="d-flex my-2">
                <Radio
                  checked={cardSelected}
                  onChange={handleChange}
                  value="a"
                  name="radio-buttons"
                  sx={{
                    color: "#6200EE",
                    "&.Mui-checked": {
                      color: "#6200EE",
                    },
                  }}
                  inputProps={{ "aria-label": "A" }}
                />
                <div className="d-flex">
                  <img src={visa} alt="" />
                  <img src={mastercard} alt="" />
                </div>
              </div>

              {cardSelected && (
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    amount={total * 100}
                    cartItems={cartItems}
                    total={total}
                    TVA={TVA}
                    codePromo={codePromo}
                    sousTotal={sousTotalProps}
                  />
                </Elements>
              )}
            </div>
          </div>
          <SimpleDialog open={open} />
          <ApercuCommande
            cartItems={cartItems}
            displayPromoCode
            TVA={TVA}
            setTVA={setTVA}
            sousTotal={sousTotal}
            total={total}
            setSousTotalProps={setSousTotalProps}
            sousTotalProps={sousTotalProps}
            codePromo={codePromo}
            setCodePromo={setCodePromo}
            setTotal={setTotal}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ChoosePaymentScreen;
