import axios from "axios";
import { apiUrl } from "../constants/apiUrl";

export const sendMail = async (name, email, id, templateId) => {
  try {
    const request = await axios
      .post(`${apiUrl}/api/email/sendinblue`, {
        name,
        email,
        id,
        templateId,
        subject: "Someone sent you a Link.",
      })
      .then((res) => {
        return res;
      });
    return request.status === 200;
  } catch (err) {
    console.error(err);
  }
};

export const sendContactMail = async (
  lastName,
  firstName,
  email,
  phone,
  message,
  fileUrl,
  enterpriseName,
) => {
  try {
    const request = await axios
      .post(`${apiUrl}/api/email/sendinblue/contact`, {
        lastName,
        email,
        firstName,
        phone,
        message,
        fileUrl,
        enterpriseName,
      })
      .then((res) => {
        return res;
      });
    return request.status === 200;
  } catch (err) {
    console.error(err);
  }
};

export const sendResetPasswordEmail = async (email, templateId, name) => {
  try {
    const request = await axios
      .post(`${apiUrl}/api/email/sendinblue`, {
        email,
        name,
        templateId,
      })
      .then((res) => {
        return res;
      });
    return request.status === 200;
  } catch (err) {
    console.error(err);
  }
};
