import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hexToRgbAEncadre, hexToRgbA } from "../utils/functions.js";
import checkIcon from "../assets/check_icon.svg";
import { addToCart } from "../actions/cartActions";

function CardFormation({ formation, addCart }) {
  const dispatch = useDispatch();
  const addToCartHandler = async () => {
    try {
      dispatch(addToCart(formation._id, 1));
    } catch (err) {
      console.log(err);
    }
  };

  const navigate = useNavigate();
  return (
    <div
      onClick={
        addCart
          ? () => addToCartHandler()
          : () =>
              navigate(`/formations/${formation.titre.replace(/\s/g, "")}`, {
                state: {
                  formation,
                },
              })
      }
      className="color-dark cursor-pointer"
    >
      <div
        className="card-formation-container"
        style={{
          border: formation.codeHexaEncadre
            ? `4px solid ${hexToRgbAEncadre(formation.codeHexaEncadre)}`
            : "",
        }}
      >
        {formation.encadre && (
          <div
            className="encadre rounded"
            style={{
              backgroundColor: formation.encadre
                ? `${hexToRgbAEncadre(formation.codeHexaEncadre)}`
                : "",
            }}
          >
            <p className="encadre-text">{formation.encadre}</p>
          </div>
        )}

        <div className="d-flex justify-content-between">
          <div
            className="gestion-projet"
            style={{
              backgroundColor: formation.codeHexaThematique
                ? `${hexToRgbA(formation.codeHexaThematique)}`
                : "",
            }}
          >
            <p style={{ color: formation.codeHexaThematique }}>
              {formation.thematique}
            </p>
          </div>
          {formation.icon && (
            <img src={formation.icon} alt="" className="icon-rounded" />
          )}
        </div>

        <h3 className="mb-3 formation-title-card">{formation.titre}</h3>

        <h4 className="mb-5">
          {Number(formation.prixHT).toFixed(2)}€ HT{" "}
          <span style={{ color: "#7A7A7A", fontSize: "0.9rem" }}>
            ({(Number(formation.prixHT) * 1.2).toFixed(2)}€ TTC)
          </span>
        </h4>

        <div className="check-container">
          {formation.point1 && (
            <div className="d-flex my-2">
              <img src={checkIcon} className="forma-icon" />
              <p>{formation.point1}</p>
            </div>
          )}
          {formation.point2 && (
            <div className="d-flex my-2">
              <img src={checkIcon} className="forma-icon" />
              <p>{formation.point2}</p>
            </div>
          )}
          {formation.point3 && (
            <div className="d-flex my-2">
              <img src={checkIcon} className="forma-icon" />
              <p>{formation.point3}</p>
            </div>
          )}
          {formation.point4 && (
            <div className="d-flex my-2">
              <img src={checkIcon} className="forma-icon" />
              <p>{formation.point4}</p>
            </div>
          )}
          {formation.point5 && (
            <div className="d-flex my-2">
              <img src={checkIcon} className="forma-icon" />
              <p>{formation.point5}</p>
            </div>
          )}
        </div>

        {addCart ? (
          <div className="d-flex flex-row go-detail">
            <p className="formation-detail" onClick={addToCartHandler}>
              Ajouter au panier
            </p>

            <FontAwesomeIcon
              icon={faArrowRight}
              color="#6633CC"
              className="arrow-icon"
            />
          </div>
        ) : (
          <div className="d-flex flex-row go-detail">
            <Link
              to={`/formations/${formation.titre.replace(/\s/g, "")}`}
              state={{ formation }}
            >
              <p className="formation-detail">Détail de la formation</p>
            </Link>
            <FontAwesomeIcon
              icon={faArrowRight}
              color="#6633CC"
              className="arrow-icon"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CardFormation;
