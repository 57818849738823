import React from "react";
import { useDispatch } from "react-redux";
import { QuantityPicker } from "react-qty-picker";
import { addToCart } from "../../actions/cartActions";

function QtySelector({ item }) {
  const dispatch = useDispatch();

  return (
    <QuantityPicker
      value={item.qty}
      min={0}
      onChange={(value) => {
        console.log(value);
        // here value is the final update value of the component
        dispatch(addToCart(item.product, Number(value)));
      }}
    />
  );
}

export default QtySelector;
