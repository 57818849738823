import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import SideBar from "../../components/SideBar";
import HeaderAdmin from "../../components/admin/HeaderAdmin";
import { apiUrl } from "../../constants/apiUrl";

import InformationsUser from "../../components/admin/InformationsUser";
import FacturesUser from "../../components/admin/FacturesUser";
import FormationsUser from "../../components/admin/FormationsUser";

function ViewUserProfile() {
  const [formation, setFormation] = useState(true);
  const [factures, setFactures] = useState(false);
  const [detail, setDetail] = useState(false);
  const location = useLocation();

  const { userId } = location.state;
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const getUserOrders = async () => {
      const { data } = await axios.post(`${apiUrl}/api/users/profile`, {
        _id: userId,
      });
      console.log(data);
      setUser(data);
      setOrders(data.formations);
    };
    getUserOrders();
  }, []);

  return (
    <div className="d-flex">
      <SideBar />
      {user && (
        <div className="users-container">
          <HeaderAdmin />
          <h1 className="mt-5 mx-5">
            Profil de {user.firstName} {user.lastName}
          </h1>
          <div className="p-5">
            <div className="header-profile">
              <p
                className={
                  formation ? "header-profile-purple" : "header-profile-title"
                }
                onClick={() => {
                  setFormation(true);
                  setFactures(false);
                  setDetail(false);
                }}
              >
                Formations
              </p>
              <p
                className={
                  factures ? "header-profile-purple" : "header-profile-title"
                }
                onClick={() => {
                  setFormation(false);
                  setFactures(true);
                  setDetail(false);
                }}
              >
                Factures
              </p>
              <p
                className={
                  detail ? "header-profile-purple" : "header-profile-title"
                }
                onClick={() => {
                  setFormation(false);
                  setFactures(false);
                  setDetail(true);
                }}
              >
                Détails du compte
              </p>
            </div>

            {formation && orders && (
              <FormationsUser orders={orders} user={user} />
            )}
            {factures && <FacturesUser factures={orders} />}
            {detail && user && (
              <InformationsUser currentUser={user} setCurrentUser={user} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewUserProfile;
