import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import Checkbox from "@mui/material/Checkbox";
import logoConnexion from "../../assets/logo_connexion.svg";
import imgConnexion from "../../assets/imgConnexion.svg";
import Button from "../../components/Button";
import { apiUrl } from "../../constants/apiUrl";
import CustomInput from "../../components/CustomInput";

function SignupScreen() {
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const initialValues = {
    email: "",
    password: "",
    checked: checkboxChecked,
  };

  const signupSchema = Yup.object().shape({
    email: Yup.string(),
    checked: Yup.string().required(
      "Veuillez accepter les conditions générales d'utilisations",
    ),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&)(#_+=/§])[A-Za-z\d@$!%*?&-)(#_+=/§]{10,}$/,
        "Votre mot de passe doit comporter au minimum dix caractères incluant obligatoirement une lettre majuscule, un chiffre et un caractère spécial (@$!%*?&)(#_+=/§).",
      )
      .required("Ce champ est requis"),
  });

  const handleForm = async (values) => {
    setError("");
    if (!checkboxChecked) {
      setError("Veuillez accepter les conditions générales d'utilisations");
    } else {
      const { email, password } = values;
      try {
        const { data } = await axios.get(
          `${apiUrl}/api/users/exists/${email.trim().toLowerCase()}`,
        );
        if (data !== "Cet utilisateur n'existe pas") {
          setError("Cet utilisateur existe déjà");
        } else {
          navigate("/informations", {
            state: {
              email,
              password,
            },
          });
        }
      } catch (e) {
        setError("Votre mot de passe actuel est erroné");
      }
    }
  };

  return (
    <div className="connexion-container">
      <div className="left-container">
        <div className="form-connexion-container">
          <img
            src={logoConnexion}
            alt=""
            className="connexion-logo"
            onClick={() => navigate("/")}
          />
          <h1>Bienvenue sur Talent1 !</h1>

          <div className="signup-container">
            <p>Déjà membre ?</p>
            <Link to="/login">
              <p className="signup-text">Se connecter</p>
            </Link>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={signupSchema}
            onSubmit={(values) => {
              handleForm(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <div>
                <div className="text-field-container">
                  <CustomInput
                    id="outlined-helperText"
                    label="Adresse e-mail"
                    color="secondary"
                    focused
                    value={values.email}
                    className="text-field"
                    onChange={handleChange("email")}
                  />
                </div>

                {errors.email && (
                  <div className="text-danger">{errors.email}</div>
                )}
                <div className="text-field-container">
                  <CustomInput
                    id="outlined-password-input"
                    label="Mot de passe"
                    type="password"
                    className="text-field"
                    value={values.password}
                    autoComplete="current-password"
                    onChange={handleChange("password")}
                  />
                </div>
                {errors.password && (
                  <div className="text-danger">{errors.password}</div>
                )}
                <div className="d-flex">
                  <Checkbox
                    checked={checkboxChecked}
                    className="mt-1"
                    onChange={() => setCheckboxChecked(!checkboxChecked)}
                  />
                  <p className="mt-2 pt-2">
                    En cochant cette case, j'accepte les{" "}
                    <Link to="/CGU">CGU</Link> et <Link to="/CGV">CGV</Link>
                  </p>
                </div>

                {error && <p className="text-danger">{error}</p>}
                <Button
                  text="Créer mon compte"
                  style={{ marginTop: "15%" }}
                  onClick={() => {
                    console.log("jdkljfdl");
                    handleSubmit();
                  }}
                />
              </div>
            )}
          </Formik>
        </div>
      </div>

      <div className="img-connexion-container">
        <div className="img-connexion-inner-container">
          <img src={imgConnexion} alt="" />
        </div>
      </div>
    </div>
  );
}

export default SignupScreen;
