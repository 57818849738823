/* eslint-disable no-unused-vars */
import React from "react";

function WhiteButton({
  text,
  style,
  styleText,
  className,
  textClassName,
  onClick,
  ...rest
}) {
  const classes = `buttonContainer ${className}`;
  const textClasses = `buttonText ${textClassName}`;
  return (
    <div
      className={`${classes} rounded p-3 d-inline-block`}
      style={style}
      onClick={onClick}
    >
      <p className={textClasses} style={styleText}>
        {text}
      </p>
    </div>
  );
}

export default WhiteButton;
