import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { InlineWidget } from "react-calendly";
import Dialog from "@mui/material/Dialog";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import Typewriter from "typewriter-effect";
import { Formik } from "formik";
import * as Yup from "yup";

import { createBrowserHistory } from "history";
import NavBar from "../components/NavBar";
import Button from "../components/Button";
import businessMan from "../assets/businessMan.svg";
import building from "../assets/building.svg";
import Footer from "../components/Footer";
import wave from "../assets/wave_violet.svg";
import CustomInput from "../components/CustomInput";
import { sendContactMail } from "../utils/sendEmail";
import AuthContext from "../context/currentUserContext";
import CustomUpload from "../components/CustomUpload";
import { apiUrl } from "../constants/apiUrl";

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open} className="contact-dialog">
      <InlineWidget
        url="https://calendly.com/erwan-bagdatli-1"
        className="w-100"
      />
    </Dialog>
  );
}

function ContactScreen() {
  const history = createBrowserHistory();

  const [particulier, setParticulier] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [done, setDone] = useState(false);
  const [unconnected, setUnconnected] = useState(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    message: "",
    email: "",
    enterpriseName: "",
  };

  const contactSchema = Yup.object().shape({
    enterpriseName: particulier
      ? ""
      : Yup.string().required("Ce champ est requis"),
    firstName: Yup.string().required("Ce champ est requis"),
    lastName: Yup.string().required("Ce champ est requis"),
    phone: Yup.string().required("Ce champ est requis"),
    message: Yup.string().required("Ce champ est requis"),
    email: Yup.string().required("Ce champ est requis"),
  });

  const { currentUser } = useContext(AuthContext);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const recaptchaRef = React.createRef();
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (values) => {
    const captchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    const { data } = await axios.post(`${apiUrl}/api/users/verify-captcha`, {
      token: captchaToken,
    });
    if (data === "Robot") {
      return;
    }
    const response = await sendContactMail(
      values.lastName,
      values.firstName,
      values.email,
      values.phone,
      values.message,
      fileUrl,
      values.enterpriseName,
    );
    setDone(true);
  };

  useEffect(() => {
    history.listen((location, action) => {
      window.scrollTo(0, 0);
    });
  }, [fileUrl]);

  console.log("heyy");
  return (
    <div>
      <NavBar active="contact" />
      <div className="block-1 block-1-contact" id="block-1">
        <h1 className="head-title">Réservons un échange</h1>
        <h5 className="text-center text-white">
          {" "}
          <Typewriter
            options={{
              strings: [
                "Nous sommes disponibles pour répondre à vos questions et vous aider dans vos démarches.",
              ],
              autoStart: true,
              loop: true,
              cursor: "_",
              pauseFor: 15000,
            }}
          />
        </h5>
      </div>
      <img
        src={wave}
        alt=""
        className="w-100 wave"
        style={{ backgroundColor: "#F3F7F9" }}
      />

      <SimpleDialog open={open} onClose={handleClose} />
      <div className="contact-super-container">
        <div className="contact-container margin-relative">
          <div className="planification contact-inner">
            <h5 className="send-message-text mb-2">Réservons un échange</h5>
            <p className="mb-5">
              Nous nous engageons pour que vos projets soient un succès. A
              l'écoute de vos attentes, nous faisons de vos défis notre première
              priorité pour garantir la réalisation de vos projets dans les
              délais.{" "}
            </p>
            <Button text="Prendre un rendez-vous" onClick={handleClickOpen} />
          </div>
          <div className="send-message contact-inner">
            <p className="send-message-text">Ecrivez-nous</p>
            <div className="flex-row d-flex signup-container">
              <div
                className={
                  particulier
                    ? "particulier-purple particulier"
                    : "particulier-grey particulier"
                }
                onKeyDown={() => setParticulier(false)}
                onClick={() => setParticulier(true)}
              >
                <div className="img-particulier-container">
                  <img src={businessMan} alt="" />
                </div>
                <p className={particulier ? "purple" : "grey"}>
                  Je suis un particulier
                </p>
              </div>

              <div
                className={
                  particulier
                    ? "particulier-grey enterprise"
                    : "particulier-purple enterprise"
                }
                onKeyDown={() => setParticulier(false)}
                onClick={() => setParticulier(false)}
              >
                <div className="img-particulier-container">
                  <img src={building} alt="" className="mt-2" />
                </div>
                <p className={particulier ? "grey" : "purple"}>
                  Je suis une entreprise
                </p>
              </div>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={contactSchema}
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values);
                setTimeout(() => {
                  window.location.reload();
                }, 2500);
              }}
            >
              {({ values, errors, handleChange, handleSubmit }) => (
                <div>
                  <div className="phone-container d-flex flex-column">
                    {!particulier && (
                      <div className="d-flex flex-column mb-4">
                        <CustomInput
                          required
                          label="Nom de l'entreprise"
                          className="text-field"
                          value={values.enterpriseName}
                          onChange={handleChange("enterpriseName")}
                        />
                        {errors.enterpriseName && (
                          <div className="text-danger">
                            {errors.enterpriseName}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="prenom-container">
                    <div className="text-prenom-container">
                      <CustomInput
                        required
                        label="Prénom"
                        value={values.firstName}
                        className="text-field"
                        onChange={handleChange("firstName")}
                      />
                      {errors.firstName && (
                        <div className="text-danger">{errors.firstName}</div>
                      )}
                    </div>

                    <div className="text-nom-container">
                      <CustomInput
                        required
                        label="Nom"
                        value={values.lastName}
                        className="text-field"
                        onChange={handleChange("lastName")}
                      />
                      {errors.lastName && (
                        <div className="text-danger">{errors.lastName}</div>
                      )}
                    </div>
                  </div>

                  <div className="phone-container d-flex flex-column">
                    <CustomInput
                      required
                      label="Email"
                      className="text-field"
                      value={values.email}
                      onChange={handleChange("email")}
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>
                  <div className="phone-container mt-4 d-flex flex-column">
                    <CustomInput
                      required
                      label="Numéro de téléphone"
                      className="text-field"
                      value={values.phone}
                      onChange={handleChange("phone")}
                    />
                    {errors.phone && (
                      <div className="text-danger">{errors.phone}</div>
                    )}
                  </div>

                  <div className="text-field-container mt-4">
                    <CustomInput
                      required
                      multiline
                      rows={6}
                      label="Message"
                      value={values.message}
                      className="text-field"
                      onChange={handleChange("message")}
                    />
                    {errors.message && (
                      <div className="text-danger">{errors.message}</div>
                    )}
                  </div>

                  <CustomUpload
                    file={fileUrl}
                    setFile={setFileUrl}
                    className="mb-3"
                    text="Ajouter une pièce jointe"
                  />
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LeKu4AlAAAAAJo8hgbdn4E9AyzXk6R_vki0N4hj"
                  />

                  <Button text="Envoyer" onClick={handleSubmit} />
                </div>
              )}
            </Formik>
            {done && (
              <div className="alert alert-success mt-3" role="alert">
                Votre message a bien été envoyé ! 😁
              </div>
            )}
            {unconnected && (
              <div className="alert alert-danger mt-3" role="alert">
                Veuillez vous connecter
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ContactScreen;
