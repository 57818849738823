import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../assets/logo_talent1_header_dark.svg";
import AuthContext from "../context/currentUserContext";
import shoppingCart from "../assets/shoppingBag.svg";
import verticalLine from "../assets/Line 1.png";
import fullShoppingBag from "../assets/Line.svg";

function NavBar({ active }) {
  const [stickyClass, setStickyClass] = useState("");

  const stickNavbar = () => {
    if (window !== undefined) {
      const windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 0 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const { currentUser, setIsLoggedIn, setCurrentUser } =
    useContext(AuthContext);

  const logoutHandler = () => {
    localStorage.removeItem("userInfoTalent1");
    setIsLoggedIn(false);
    setCurrentUser(null);
    navigate("/");
  };

  let numberOfArticles = 0;

  cartItems.forEach((item) => {
    numberOfArticles += item.qty;
  });

  console.log("active", active);
  return (
    <Navbar bg="white" expand="lg" className={`navbar ${stickyClass} big-nav`}>
      <img
        src={logo}
        alt=""
        className="navbar-logo"
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
      />
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link
            className={
              active === "services"
                ? `nav-link-self mx-4 nav-link-dark`
                : `nav-link-self mx-4`
            }
            href="/services"
            eventKey="link-1"
          >
            Nos services
          </Nav.Link>

          <Nav.Link
            className={
              active === "formations"
                ? `nav-link-self mx-4 nav-link-dark`
                : `nav-link-self mx-4`
            }
            href="/formations"
            eventKey="link-2"
          >
            Nos formations
          </Nav.Link>
          <Nav.Link
            className={
              active === "contact"
                ? `nav-link-self mx-4 nav-link-dark`
                : `nav-link-self mx-4`
            }
            href="/contact"
            eventKey="link-3"
          >
            Nous contacter
          </Nav.Link>
        </Nav>
        <Nav.Link className="nav-link-self">
          {cartItems && cartItems.length !== 0 ? (
            <div
              className="qty-container"
              onClick={() => {
                navigate("/cart");
              }}
            >
              <img src={fullShoppingBag} alt="" />
              <div className="qty-inner-container">
                <p>{numberOfArticles}</p>
              </div>
            </div>
          ) : (
            <img
              src={shoppingCart}
              alt=""
              onClick={() => {
                navigate("/no-cart");
              }}
            />
          )}
        </Nav.Link>
        <Nav.Link href="#link" className="nav-link-self">
          <img src={verticalLine} alt="" className="separator" />
        </Nav.Link>

        {currentUser ? (
          <NavDropdown
            title={currentUser.firstName}
            id="basic-nav-dropdown"
            className="navbar-dropdown"
          >
            <NavDropdown.Item href="/profile">Mon profil</NavDropdown.Item>
            <NavDropdown.Divider />
            {currentUser.email === "erwan.bagdatli@talent1.fr" && (
              <>
                <NavDropdown.Item>
                  <Link to="/admin/dashboard">Administration</Link>
                </NavDropdown.Item>
                <NavDropdown.Divider />
              </>
            )}

            <NavDropdown.Item onClick={logoutHandler}>
              Se déconnecter
            </NavDropdown.Item>
          </NavDropdown>
        ) : (
          <>
            <Nav.Link href="/login" className="nav-link-self mr-2">
              Me connecter
            </Nav.Link>
            <Nav.Link href="/signup" className="create-account">
              Créer un compte
            </Nav.Link>
          </>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
