import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import logo from "../assets/logo_talent1_header.png";
import validated from "../assets/validated.png";
import normalFont from "../fonts/Rubik-Light.ttf";
import boldFont from "../fonts/Rubik-Bold.ttf";
import { formatDateForFacture } from "../utils/date";

Font.register({
  family: "Rubik",
  fonts: [
    {
      src: normalFont,
      fontWeight: 300,
    },
    {
      src: boldFont,
      fontWeight: 700,
    },
  ],
});

function Facture({ cartItems, total, TVA, userData, codePromo, sousTotal }) {
  const date = formatDateForFacture();

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Rubik",
      flexDirection: "column",
      backgroundColor: "white",
      position: "relative",
    },
    imageValidated: {
      height: "20%",
      width: "25%",
      alignSelf: "center",
    },
    image: {
      width: "120px",
      height: "30px",
      alignSelf: "center",
    },
    title: {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "25px",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    factureNumber: {
      fontWeight: "bold",
      textAlign: "center",
      marginVertical: "20px",
    },
    adresseContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginVertical: "30px",
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    footer: {
      backgroundColor: "#E8E8E8",
      alignContent: "center",
      paddingVertical: "30px",
      paddingHorizontal: "5%",
    },
    textCenter: {
      textAlign: "center",
    },
    textContent: {
      fontSize: "12px",
    },
    footerText: {
      fontSize: "10px",
    },
    content: {
      padding: "5%",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>
          <View>
            <Image source={logo} style={styles.image} />
          </View>

          <Text style={styles.title}>Votre formation a été validée</Text>
          <Image source={validated} style={styles.imageValidated} />
          <Text style={styles.factureNumber}>Numéro de facture {date}</Text>

          <View style={styles.adresseContainer}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Facture adressée à </Text>
              {userData?.enterpriseName && (
                <Text style={styles.textContent}>
                  {userData.enterpriseName}
                </Text>
              )}

              <Text style={styles.textContent}>
                {userData.firstName} {userData.lastName}
              </Text>
              <Text style={styles.textContent}>
                {userData.facturationAddress}
              </Text>
              <Text style={styles.textContent}>
                {userData.facturationPostalCode} {userData.facturationCity}
              </Text>
              <Text style={styles.textContent}>
                {userData.facturationCountry}
              </Text>
            </View>
            <View>
              <Text style={{ fontWeight: "bold" }}>Date de la facture</Text>
              <Text style={styles.textContent}>
                {new Date().toLocaleDateString()}
              </Text>
            </View>
          </View>

          <View style={{ marginVertical: "30px" }}>
            {cartItems.map((item) => {
              const { id } = item;
              return (
                <View key={id} style={styles.flex}>
                  <div>
                    <Text style={styles.textContent}>
                      {item.qty} x {item.titre}
                    </Text>
                    <Text style={[styles.textContent, { color: "grey" }]}>
                      Du {item.startDate} au {item.endDate}
                    </Text>
                  </div>

                  <Text style={styles.textContent}>{item.qty} x {item.prixHT} €HT</Text>
                </View>
              );
            })}
          </View>
          <View style={{ marginVertical: "30px" }}>
          {codePromo &&   <View style={styles.flex}>
              <Text style={styles.textContent}>Code Promo</Text>
              <Text style={[{ fontWeight: "bold" }, styles.textContent]}>
                - {codePromo} €
              </Text>
            </View>}
            <View style={styles.flex}>
              <Text style={styles.textContent}>Total (HT)</Text>
              <Text style={[{ fontWeight: "bold" }, styles.textContent]}>
                {sousTotal} €
              </Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.textContent}>TVA (20%)</Text>
              <Text style={[{ fontWeight: "bold" }, styles.textContent]}>
                {TVA} €
              </Text>
            </View>
         
            <View style={[styles.flex, {marginTop: "15px"}]}>
              <Text>Montant total de la facture</Text>
              <Text style={[{ fontWeight: "bold", marginTop: "3px" }, styles.textContent]}>
                {total} € TTC
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.footer}>
          <Text style={[styles.textCenter, styles.footerText]}>
            Talent1 - 95 Bis rue Jean Mermoz - 95230 Soisy-sous-Montmorency
          </Text>
          <Text style={[styles.textCenter, styles.footerText]}>
            N° Siren (Siret) : 898 635 115 (00014)
          </Text>
          <Text style={[styles.textCenter, styles.footerText]}>
            N° TVA : FR71898635115 - Régime des encaissements
          </Text>
          <Text style={[styles.textCenter, styles.footerText]}>
            Mail : contact@talent1.fr
          </Text>
        </View>
      </Page>
    </Document>
  );
}

export default Facture;
