import React from "react";
import { useNavigate } from "react-router";
import BigShoppingCart from "../../assets/BigShoppingCart.svg";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
import NavBar from "../../components/NavBar";

function NoCartItemsScreen() {
  const navigate = useNavigate();
  return (
    <div className="super-container">
      <NavBar />
      <div className="inner-no-cart-container container">
        <img src={BigShoppingCart} alt="" />
        <h3 className="no-cart-title my-3">Votre panier est vide</h3>
        <Button
          className="no-cart-button"
          text="Voir les formations"
          onClick={() => navigate("/formations")}
        />
      </div>
      <Footer />
    </div>
  );
}

export default NoCartItemsScreen;
