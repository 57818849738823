import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { async } from "@firebase/util";
import Dialog from "@mui/material/Dialog";
import logoConnexion from "../../assets/logo_connexion.svg";
import Button from "../../components/Button";
import businessMan from "../../assets/businessMan.svg";
import building from "../../assets/building.svg";
import infosImage from "../../assets/InfosImage.svg";
import WhiteButton from "../../components/WhiteButton";
import { apiUrl } from "../../constants/apiUrl";
import CustomUpload from "../../components/CustomUpload";
import { sendMail } from "../../utils/sendEmail";
import CustomInput from "../../components/CustomInput";

function SimpleDialog(props) {
  const { onClose, selectedValue, open, email } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const navigate = useNavigate();
  const styles = {
    container: {
      maxWidth: "400px",
    },
    alignCenter: {
      textAlign: "center",
    },
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <div style={styles.container}>
        <h4 style={styles.alignCenter}>Confirmation de l'email</h4>
        <p style={styles.alignCenter}>
          Nous vous avons envoyé un mail à l'adresse {email} afin de confirmer
          votre compte. Veuillez cliquer sur le lien reçu dans ce mail afin de
          poursuivre votre inscription.
        </p>
        <Button
          text="J'ai compris"
          style={{ marginTop: "5%", borderRadius: "5px" }}
          onClick={() => navigate("/")}
        />
      </div>
    </Dialog>
  );
}

function InformationsScreen() {
  const location = useLocation();
  const { email, password } = location.state;

  const [particulier, setParticulier] = useState(true);
  const [suivant, setSuivant] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [localisation, setLocalisation] = useState("");
  const [poste, setPoste] = useState("");
  const [cvUrl, setCvUrl] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const createPendingUser = async () => {
    try {
      const { data } = await axios.post(`${apiUrl}/api/pendinguser`, {
        email,
        password,
        particulier,
        firstName,
        lastName,
        phone,
        localisation,
        poste,
        formations: [],
        CV: cvUrl,
      });
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const navigate = useNavigate();
  const sendActivationCodeEmail = async () => {
    const pendingUser = await createPendingUser();
    console.log(pendingUser);
    const response = await sendMail(firstName, email, pendingUser._id, 1);
    console.log(response);
  };
  return (
    <div className="connexion-container">
      <div className="left-container">
        {!suivant ? (
          <div className="form-connexion-container">
            <img
              src={logoConnexion}
              alt=""
              className="connexion-logo"
              onClick={() => navigate("/")}
            />
            <h1>Informations personnelles</h1>
            <p>1/2</p>
            <div className="flex-row d-flex signup-container">
              <div
                className={
                  particulier
                    ? "particulier-purple particulier"
                    : "particulier-grey particulier"
                }
                onClick={() => setParticulier(true)}
              >
                <div className="img-particulier-container">
                  <img src={businessMan} alt="" />
                </div>
                <p className={particulier ? "purple" : "grey"}>
                  Je suis un particulier
                </p>
              </div>

              <div
                className={
                  particulier
                    ? "particulier-grey particulier"
                    : "particulier-purple particulier"
                }
                onClick={() => setParticulier(false)}
              >
                <div className="img-particulier-container">
                  <img src={building} alt="" className="mt-1" />
                </div>
                <p className={particulier ? "grey" : "purple"}>
                  Je suis une entreprise
                </p>
              </div>
            </div>

            <div className="text-field-container">
              <CustomInput
                required
                label="Prénom"
                className="text-field"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className="text-field-container">
              <CustomInput
                required
                label="Nom"
                className="text-field"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className="text-field-container">
              <CustomInput
                required
                label="Numéro de téléphone"
                className="text-field"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <Button
              text="Suivant"
              style={{ marginTop: "15%" }}
              onClick={async () => {
                setSuivant(true);
              }}
            />
          </div>
        ) : (
          <div className="form-connexion-container form-container">
            <img src={logoConnexion} alt="" className="connexion-logo" />
            <h1>Informations personnelles</h1>
            <p>2/2</p>

            <div className="text-field-container mt-5">
              <CustomInput
                label="Localisation"
                className="text-field"
                value={localisation}
                onChange={(e) => setLocalisation(e.target.value)}
              />
            </div>

            <div className="text-field-container">
              <CustomInput
                label="Intitulé de votre poste"
                className="text-field"
                value={poste}
                onChange={(e) => setPoste(e.target.value)}
              />
            </div>

            <CustomUpload
              text="Déposer votre CV en .pdf"
              file={cvUrl}
              folder="CV"
              setFile={setCvUrl}
            />
            <div className="d-flex justify-content-end mt-3 ">
              <div className="d-flex align-self-end">
                <Button
                  text="Valider"
                  style={{ marginLeft: "1rem" }}
                  onClick={async () => {
                    await sendActivationCodeEmail();
                    setOpen(true);
                  }}
                />
              </div>
            </div>
            <SimpleDialog open={open} onClose={handleClose} email={email} />
          </div>
        )}
      </div>

      <div className="img-connexion-container">
        <h1 className="sert">A quoi ça sert ?</h1>
        <p className="creer-profile">
          En créant votre profil complet, vous pouvez suivre vos formations, et
          être au courant des futures opportunitées de missions.
        </p>
        <div className="img-connexion-inner-container">
          <img src={infosImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default InformationsScreen;
