import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import SideBar from "../../components/SideBar";
import HeaderAdmin from "../../components/admin/HeaderAdmin";
import { apiUrl } from "../../constants/apiUrl";
import Button from "../../components/Button";
import CustomDataGrid from "../../components/CustomDataGrid";
import deleteImage from "../../assets/Style.svg";
import WhiteButton from "../../components/WhiteButton";
import { sendMail } from "../../utils/sendEmail";

function numberWithSpaces(x) {
  return Number(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
}

function DashboardScreen() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [formationId, setFormationId] = useState(null);
  function SimpleDialog(props) {
    const { onClose, selectedValue, open, id } = props;

    console.log("ID", id);
    const handleClose = () => {
      onClose(selectedValue);
    };

    const handleDelete = async () => {
      try {
        await axios.delete(`${apiUrl}/api/factureadmin/${id}`);
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    };

    const styles = {
      container: {
        maxWidth: "400px",
      },
      alignCenter: {
        textAlign: "center",
      },
    };
    return (
      <Dialog onClose={handleClose} open={open}>
        <div style={styles.container}>
          <h4 style={styles.alignCenter}>Suppression de la facture</h4>
          <p style={styles.alignCenter}>
            Etes-vous sur de vouloir supprimer cette facture ?
          </p>
          <div className="d-flex float-right">
            <WhiteButton
              text="Annuler"
              className="mx-3"
              style={{ marginTop: "5%", borderRadius: "5px" }}
              onClick={handleClose}
            />
            <Button
              text="Supprimer"
              style={{ marginTop: "5%", borderRadius: "5px" }}
              onClick={handleDelete}
            />
          </div>
        </div>
      </Dialog>
    );
  }

  const navigate = useNavigate();
  const columns = [
    {
      field: "factureNumber",
      headerName: "N° de facture",
      headerAlign: "left",
      align: "left",
      width: 200,
      flex: 0.5,
      editable: false,
    },
    {
      field: "boughtDate",
      headerName: "Date d'achat",
      headerAlign: "left",
      align: "left",
      width: 200,
      editable: false,
    },
    {
      field: "enterpriseName",
      headerName: "Société",
      headerAlign: "left",
      align: "left",
      flex: 0.5,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Nom",
      headerAlign: "left",
      align: "left",
      flex: 0.5,
      editable: false,
    },
    {
      field: "firstName",
      headerName: "Prénom",
      headerAlign: "left",
      flex: 0.5,
      align: "left",

      editable: false,
    },
    {
      headerName: "Total HT",
      editable: false,
      field: "prixHT",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <div>
          <p className="font-weight-bold-custom">{params.row.prixHT} €</p>
        </div>
      ),
    },
    {
      field: "TVA",
      headerName: "TVA",
      headerAlign: "left",
      align: "left",
      flex: 0.5,
      editable: false,
      renderCell: (params) => (
        <div>
          <p className="font-weight-bold-custom">{params.row.TVA} €</p>
        </div>
      ),
    },
    {
      field: "total",
      flex: 0.5,
      headerName: "Total TTC",
      headerAlign: "left",
      align: "left",
      editable: false,
      renderCell: (params) => (
        <div>
          <p className="font-weight-bold-custom">{params.row.total} €</p>
        </div>
      ),
    },
    {
      field: "factureUrl",
      width: 200,
      headerName: "Facture",
      editable: false,
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      renderCell: (params) => (
        <div>
          {params.row.factureUrl && (
            <a href={params.row.factureUrl} target="_blank" rel="noreferrer">
              <Button text="Télécharger" />
            </a>
          )}
        </div>
      ),
    },
    {
      headerName: "",
      editable: true,
      width: 50,
      renderCell: (params) => (
        <div>
          <img
            src={deleteImage}
            className="delete-img"
            alt=""
            onClick={() => {
              setOpen(true);
              setFormationId(params.row._id);
            }}
          />
          <SimpleDialog open={open} onClose={handleClose} id={formationId} />
        </div>
      ),
    },
  ];
  const [factures, setFactures] = useState([]);
  const [totalHT, setTotalHT] = useState(0);
  const [totalTTC, setTotalTTC] = useState(0);
  const [TVA, setTVA] = useState(0);
  useEffect(() => {
    const getFactures = async () => {
      const { data } = await axios.get(`${apiUrl}/api/factureadmin`);
      setFactures(data);
      for (const facture of data) {
        setTotalHT((prevState) => Number(prevState) + Number(facture.prixHT));
        setTVA((prevState) => Number(prevState) + Number(facture.TVA));
        setTotalTTC((prevState) => Number(prevState) + Number(facture.total));
      }
    };
    getFactures();
  }, []);
  console.log(totalHT);

  return (
    <div className="d-flex">
      <SideBar />
      <div className="users-container">
        <HeaderAdmin text="Dashboard" />
        {factures ? (
          <div className="mt-5">
            {" "}
            <CustomDataGrid data={factures} columns={columns} height="80vh" />
          </div>
        ) : (
          ""
        )}
        <div />
        <div className="d-flex mt-5 ">
          <h5 className="mx-3 font-weight-bold-custom">
            Total HT : {totalHT.toFixed(2)} €
          </h5>
          <h5 className="mx-3 font-weight-bold-custom">
            TVA: {TVA.toFixed(2)} €
          </h5>
          <h5 className="mx-3 font-weight-bold-custom">
            Total TTC : {totalTTC.toFixed(2)} €
          </h5>
        </div>
      </div>
    </div>
  );
}

export default DashboardScreen;
