import React from "react";
import { Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo_talent1_header_dark.svg";
import checkboxCircle from "../assets/checkbox_circle.svg";

function NavBarFacturation({ paiement }) {
  const navigate = useNavigate();
  return (
    <Navbar bg="white" expand="lg" className="big-nav">
      <img
        src={logo}
        alt=""
        className="navbar-logo"
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
      />{" "}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <div className="d-flex align-self-center justify-content-center container">
        <h5 className="mb-0 mx-3 text-purple desktop">Informations</h5>
        <h6 className="mb-0 mx-3 text-purple mobile">Informations</h6>
        <h5 className="text-purple mb-0 desktop">------</h5>
        <h6 className="text-purple mb-0 mobile">--</h6>
        <img src={checkboxCircle} alt="" className="mx-3" />
        {paiement ? (
          <div className="d-flex">
            <h5 className="text-purple mb-0 desktop">------</h5>
            <h6 className="text-purple mb-0 mobile">--</h6>
            <h5 className="mb-0 mx-3 text-purple desktop">Paiement</h5>
            <h6 className="mb-0 mx-3 text-purple mobile">Paiement</h6>
          </div>
        ) : (
          <div className="d-flex">
            <h5 className="mb-0 desktop">------</h5>
            <h6 className="text-purple mb-0 mobile">--</h6>
            <h5 className="mb-0 mx-3 desktop">Paiement</h5>
            <h6 className="mb-0 mx-3 text-purple mobile">Paiement</h6>
          </div>
        )}
      </div>
    </Navbar>
  );
}

export default NavBarFacturation;
